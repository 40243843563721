/**
 * 후원하기
 */
export const donateAction = {
  title: 'บริจาค',
  balance: 'สมดุล',
  donation: 'การบริจาค',
  wallet: 'กระเป๋าสตางค์ของฉัน',
  donationPlaceholder: 'เข้าสู่การบริจาค',
  success: '{donation}LM ถูกส่งไปยัง "{creator}" แล้ว',
  error: {
    network: 'เกิดข้อผิดพลาดขณะดำเนินการคำขอของคุณ โปรดลองอีกครั้งในภายหลัง',
    lackOfBalance: 'ยอดคงเหลือของคุณไม่เพียงพอ',
    codes: {
      2006: 'ขาดค่าที่จำเป็น',
      2028: 'การขาดสมดุล',
      2029: 'ข้อผิดพลาดในการโอน',
      2031: 'คุณไม่สามารถสนับสนุน LM ที่ต่ำกว่าจุดทศนิยมได้',
      4006: 'ผู้ส่งไม่ได้รับการตรวจสอบ KYC',
      4008: 'ผู้ส่งไม่มีที่อยู่กระเป๋าเงิน',
      4010: 'ผู้รับไม่ใช่ผู้สร้าง',
      4011: 'ผู้รับไม่ได้รับการตรวจสอบ KYC',
      4012: 'ผู้รับไม่มีที่อยู่กระเป๋าเงิน',
    },
  },
}
