import type { ModalNameKeys } from '@composables/useModal'
import type { Responsive } from '@store'

/**
 * 레이어 타입
 */
export enum LayerType {
  MENU_PANEL = 'menuPanel', // 메뉴 패널
  SYSTEM_PANEL = 'systemPanel', // 시스템 패널
  NOTIFICATION_PANEL = 'notificationPanel', // 알림 패널
  USER_PANEL = 'userPanel', // 사용자 패널
  NOTIFICATION_SETTING_PANEL = 'notificationSettingPanel', // 알림 설정 패널
  USER_PROFILE_EDIT_PANEL = 'userProfileEditPanel', // 사용자 프로필 수정 패널
  USER_MAIN_PROFILE_EDIT_PANEL = 'userMainProfileEditPanel', // 사용자 메인 프로필 수정 패널
  SEARCH_PANEL = 'searchPanel', // 검색 패널
}

/**
 * 전역 로딩 인디케이터 타입
 */
export interface LoadingIndicator {
  show: boolean
  message?: string
}

/**
 * 모달 열기 닫기 페이로드
 */
export type ModalOpenClosePayload = {
  modalNameKey: ModalNameKeys | string
  routeSync: boolean
}

/**
 * 라우트 스크롤 포지션
 */
export interface RouteScrollPosition {
  scrollPosition: number // 페이지 스크롤 포지션
  recyclerViewMinHeight?: number // 스크롤 포지션을 리스토어 해야 하는 페이지에 리사이클러 뷰가 있다면 해당 리사이클러 뷰의 높이도 리스토어
  scrollToIndex?: number // 특정 리스트 아이템 인덱스
  scrollToOffset?: number // 특정 리스트 아이템 스크롤 포지션과 페이지 스크롤 포지션의 차이
}

/**
 * 라우트 스크롤 포지션 목록 아이템
 */
type RouteScrollPositions = Record<string, RouteScrollPosition>

/**
 * 레이아웃 스토어 스테이트 타입
 */
export interface LayoutState {
  loadingIndicatorGlobal: LoadingIndicator
  headerRect: DOMRect | null
  headerInnerRect: DOMRect | null
  modals: Array<ModalOpenClosePayload>
  layers: Array<LayerType>
  mainBgWhite: boolean
  mobileGnbHide: boolean
  routeScrollPositions: RouteScrollPositions
  responsive: Responsive
  touchDevice: boolean
}
