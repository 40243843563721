import { commonError } from './commonError'

/**
 * KYC 인증 관련
 */
export const kyc = {
  // GNB 사용자 패널 KYC 인증 섹션
  userKycInfo: {
    certified: 'การตรวจสอบ KYC',
    certifiedAction: {
      yet: 'ตรวจสอบตอนนี้',
      yetSimple: 'ตรวจสอบ',
      done: 'เสร็จสิ้น',
    },
  },
  // kyc 상태 조회
  checkKycStatus: {
    error: {
      4003: 'ไม่สามารถตรวจสอบผู้ใช้รายนี้ได้', // 토큰 정보와 일치하지 않는 공통 일련번호 입니다.
      default: commonError.network.message,
    },
  },
  // certifiedKyc
  unAuthKyc: {
    title: 'ตรวจสอบ',
    cs: 'หาก KYC ยังไม่เสร็จสมบูรณ์ กรุณาติดต่อ {cs}',

    // NEW
    // 인증하기
    tryYet: {
      primaryCopy: {
        service:
          'หากคุณต้องการตรวจสอบรางวัลของคุณ กรุณาทำการยืนยันตัวตน <strong class="text-color-primary-blue">(KYC) ให้เสร็จสิ้น</strong>',
        reward:
          'หากคุณต้องการใช้รางวัลของคุณ กรุณาทำการยืนยันตัวตน <strong class="text-color-primary-blue">(KYC) ให้เสร็จสิ้น</strong>',
        donate:
          'หากคุณต้องการตรวจสอบการสนับสนุน กรุณาทำการยืนยันตัวตน <strong class="text-color-primary-blue">(KYC) ให้เสร็จสิ้น</strong>',
      },
      secondaryCopy:
        '<p class="text-color-primary-blue mg-b-1u">KYC คืออะไร?</p> <p>KYC (Know Your Client) คือวิธีการตรวจสอบที่ใช้โดยสถาบันการเงินเพื่อยืนยันตัวตนของลูกค้า</p>',
      howToVerifyKyc: 'วิธีการทำ KYC',
      verifyKycProcess: {
        readyId: 'เตรียมเอกสารแสดงตัวตน',
        faceCapture: 'ถ่ายภาพใบหน้าของคุณเพื่อการตรวจสอบ',
        processingTime: 'กระบวนการนี้ใช้เวลาประมาณ 10 นาที!',
      },
      verifyAfter: 'เมื่อ KYC เสร็จสิ้น ข้อมูลของคุณจะได้รับการอัปเดต',
      actions: {
        negative: 'ยกเลิก',
        positive: 'ตรวจสอบ',
      },
    },

    // 승인거절
    reject: {
      primaryCopy:
        'กรุณาลองทำการตรวจสอบ <strong class="text-color-primary-blue">KYC ใหม่</strong>',
      secondaryCopy: {
        service: 'หากกระบวนการไม่เสร็จสิ้น, คุณจะไม่สามารถใช้บริการได้',
        reward:
          'หากกระบวนการไม่เสร็จสิ้น, คุณจะไม่สามารถตรวจสอบรางวัลของคุณได้',
        donate: 'หากกระบวนการไม่เสร็จสิ้น, คุณจะไม่สามารถทำการสนับสนุนได้',
      },
      rejectReason: 'เหตุผลการปฏิเสธ KYC',
      loadRejectReason: 'โหลดเหตุผลการปฏิเสธ',
      actions: {
        negative: 'ยกเลิก',
        positive: 'ลองอีกครั้ง',
      },
    },

    // 중복
    duplicate: {
      primaryCopy: 'เป็นการตรวจสอบ KYC ซ้ำ',
      secondaryCopy:
        'ไม่สามารถทำการตรวจสอบ KYC ได้เนื่องจากได้ทำไปแล้วกับบัญชีอื่น',
      guide:
        '※ หลังจากออกจากระบบ, คุณสามารถค้นหา ID ที่มีอยู่ของคุณได้ที่ ‘ลืม ID (อีเมล)?’',
      confirm: 'ยืนยัน',
    },

    // 진행중
    pending: {
      primaryCopy: 'กระบวนการตรวจสอบ KYC กำลังดำเนินอยู่',
      secondaryCopy: {
        service: 'หากกระบวนการเสร็จสมบูรณ์,<br />คุณสามารถใช้บริการได้',
        reward:
          'หากกระบวนการเสร็จสมบูรณ์,<br />คุณสามารถตรวจสอบรางวัลของคุณได้',
        donate: 'หากกระบวนการเสร็จสมบูรณ์,<br />คุณสามารถทำการสนับสนุนได้',
      },
      guide: 'เมื่อ KYC เสร็จสมบูรณ์ ข้อมูลของคุณจะได้รับการอัปเดต!',
      confirm: 'ยืนยัน',
    },
  },
}
