/**
 * 결제 모듈
 */
export const pay = {
  payType: {
    name: {
      CC: 'Credit Card Payment', // 신용카드
      AT: 'Bank Transfer', // 계좌이체
    } as { [Property in PayType]: string },
  },
  iframe: {
    loadFail: 'The payment module cannot be loaded.',
  },
}
