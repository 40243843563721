import * as LayoutType from './type'
import { initLayoutState } from './init'

export * from './init'
export * from './type'
export const useLayoutStore = defineStore('layout', () => {
  const loadingIndicatorGlobal = ref(initLayoutState.loadingIndicatorGlobal)
  const headerRect = ref(initLayoutState.headerRect)
  const headerInnerRect = ref(initLayoutState.headerInnerRect)
  const modals = ref(initLayoutState.modals)
  const layers = ref(initLayoutState.layers)
  const mainBgWhite = ref(initLayoutState.mainBgWhite)
  const mobileGnbHide = ref(initLayoutState.mobileGnbHide)
  const routeScrollPositions = ref(initLayoutState.routeScrollPositions)
  const responsive = ref(initLayoutState.responsive)
  const touchDevice = ref(initLayoutState.touchDevice)

  /**
   * 전역 로딩 인디케이터 업데이트
   */
  const updateLoadingIndicatorGlobal = ({
    show,
    message = '',
  }: LayoutType.LoadingIndicator) => {
    loadingIndicatorGlobal.value = {
      show,
      message: !show ? '' : message,
    }
  }

  /**
   * 헤더 rect 업데이트
   */
  const updateHeaderRect = (payload: DOMRect | null) => {
    headerRect.value = payload
  }

  /**
   * 헤더 inner rect 업데이트
   */
  const updateHeaderInnerRect = (payload: DOMRect | null) => {
    headerInnerRect.value = payload
  }
  /**
   * 메인 배경색 화이트 사용
   */
  const updateMainBgWhite = (payload: boolean) => {
    mainBgWhite.value = payload
  }

  /**
   * GNB 모바일 헤더 숨기기
   */
  const updateGnbMobileHide = (payload: boolean) => {
    mobileGnbHide.value = payload
  }

  /**
   * 모달 열기
   * @description 모달 네임을 최신순으로 추가
   * @param payload
   */
  const modalShow = (payload: LayoutType.ModalOpenClosePayload) => {
    modals.value.unshift(payload)
  }

  /**
   * 모달 닫기
   * @description 가장 최근에서 모달 네임이 일치하는 항목을 삭제
   * @param payload
   */
  const modalHide = (
    payload: LayoutType.ModalOpenClosePayload['modalNameKey']
  ) => {
    modals.value.some((modal, index) => {
      if (modal.modalNameKey === payload) {
        modals.value.splice(index, 1)
        return true
      }
      return false
    })
  }

  /**
   * 모달 모두 닫기
   * @description 모든 모달 삭제
   */
  const modalHideAll = () => {
    modals.value = []
  }

  /**
   * 레이어 열기
   * @description 레이어를 최신순으로 추가
   * @param layerType
   */
  const layerOpen = (layerType: LayoutType.LayerType) => {
    if (layers.value.includes(layerType)) return
    layers.value.unshift(layerType)
  }

  /**
   * 레이어 닫기
   * @description 레이어를 차례대로 삭제
   * @param layerType
   */
  const layerClose = (layerType: LayoutType.LayerType) => {
    layers.value.some((layer, index) => {
      if (layer === layerType) {
        layers.value.splice(index, 1)
        return true
      }
      return false
    })
  }

  /**
   * 레이어 열기/닫기 토글
   * @description 기존 상태를 기준으로 열거나 닫기
   */
  const layerToggle = (layerType: LayoutType.LayerType, isOpen?: boolean) => {
    const newIsOpen =
      typeof isOpen === 'undefined' ? layers.value.includes(layerType) : isOpen
    newIsOpen ? layerClose(layerType) : layerOpen(layerType)
  }

  /**
   * 레이어 모두 닫기
   * @description 레이어 모두 삭제
   */
  const layerCloseAll = () => {
    layers.value = []
  }

  /**
   * 라우트 페이지 스크롤 포지션 저장
   */
  const saveRouteScrollPosition = (
    routeName: string,
    scrollPosition: LayoutType.RouteScrollPosition
  ) => {
    routeScrollPositions.value[routeName] = scrollPosition
  }

  /**
   * 전역 반응형 레이아웃 상태 업데이트
   */
  const updateResponsive = (payload: Responsive) => {
    responsive.value = payload
  }

  /**
   * 터치 가능한 디바이스 여부 업데이트
   */
  const updateTouchDevice = (isTouchDevice: boolean) => {
    touchDevice.value = isTouchDevice
  }

  return {
    loadingIndicatorGlobal,
    headerRect,
    headerInnerRect,
    modals,
    layers,
    mainBgWhite,
    mobileGnbHide,
    routeScrollPositions,
    responsive,
    touchDevice,
    updateLoadingIndicatorGlobal,
    updateHeaderRect,
    updateHeaderInnerRect,
    updateMainBgWhite,
    updateGnbMobileHide,
    'modal:show': modalShow,
    'modal:hide': modalHide,
    'modal:hideAll': modalHideAll,
    'layer:open': layerOpen,
    'layer:close': layerClose,
    'layer:toggle': layerToggle,
    'layer:closeAll': layerCloseAll,
    saveRouteScrollPosition,
    updateResponsive,
    updateTouchDevice,
  }
})
