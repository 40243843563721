import { commonError } from './commonError'

/**
 * Sendbird
 */
export const sendbird = {
  createLiveEvent: {
    errors: {
      checkCreateLiveAvailable: {
        3004: 'ไม่สามารถเชื่อมต่อกับการถ่ายทอดสด กรุณาลองอีกครั้งในภายหลัง', // sendbird user id가 존재하지 않습니다.
        3006: 'เกินเวลาที่อนุญาตให้ถ่ายทอดสดในหนึ่งวัน (60 นาที)', // 라이브 시간초과 오류
        4010: 'You are not a Creator who can create a live.', // 크리에이터 회원이 아닙니다.
      },
      fetchNftCollectionsFail: commonError.network.message,
      createLive: {
        2005: 'ภาพปกเป็นนามสกุลไฟล์ที่ไม่สามารถลงทะเบียนได้',
        3007: 'มีการถ่ายทอดสดที่ยังไม่สิ้นสุด', // 라이브 시간초과 오류
      },
      alreadyOnAir: 'การถ่ายทอดสดกำลังดำเนินอยู่แล้ว',
    },
    modal: {
      title: 'เริ่มต้น',
      guide: {
        primary: 'คุณผู้สร้าง! ลองสื่อสารกับแฟนๆ ผ่านการถ่ายทอดสด',
        secondary:
          '*หลังการถ่ายทอดสดจะมีการอัปโหลดวิดีโอลงในแท็บถ่ายทอดสดที่หน้าหลักของผู้สร้าง > เนื้อหา',
      },
      sections: {
        liveTitle: {
          label: 'ตั้งชื่อการถ่ายทอดสด',
          placeholder: 'กรุณากรอกชื่อการถ่ายทอดสด',
          errMin: 'ไม่ได้กรอกชื่อ',
          errMax: 'สามารถกรอกได้สูงสุด 50 ตัวอักษร',
        },
        liveEnterLimit: {
          label: 'ตั้งค่าการจำกัดการเข้าถึงการถ่ายทอดสด',
          guide:
            'เมื่อกำหนดการจำกัดการเข้าถึง จะสามารถจำกัดผู้ใช้ที่เข้าถึงการถ่ายทอดสดของฉันได้ คุณสามารถเลือก NFT-holder ที่สามารถเข้าถึงได้',
          placeholder: 'เลือก NFT-holder ที่สามารถเข้าถึงได้',
          errorMessage: 'ไม่ได้เลือกเงื่อนไขการเข้าถึง',
          radios: {
            all: 'เข้าถึงได้ทุกคน',
            limit: 'ตั้งค่าการจำกัดการเข้าถึง',
            choiceAll: 'เลือกทั้งหมด',
          },
        },
      },
      actions: {
        cancel: 'ยกเลิก',
        create: 'เริ่มต้น',
      },
    },
  },
  enterLiveEvent: {
    actions: {
      enterLive: 'เข้าถึง',
    },
    errors: {
      checkEnterLiveAvailableFail: commonError.network.message,
      2011: 'การถ่ายทอดสดไม่มีอยู่',
      2034: 'โฮสต์ได้บล็อกฉันจึงไม่สามารถเข้าถึงการถ่ายทอดสดได้',
      3010: 'ไม่ใช่การถ่ายทอดสดที่กำลังดำเนินอยู่',
      3011: 'ได้เข้าถึงการถ่ายทอดสดแล้ว',
      3012: 'จำนวนคนที่สามารถเข้าถึงได้เต็มแล้ว',
    },
    modal: {
      message: 'ไม่สามารถเข้าถึง LIVE ของผู้สร้างได้',
      description: 'หากคุณถือ NFT ด้านล่างนี้ คุณจะสามารถเข้าถึงได้',
      cause: 'รายการ NFT',
    },
  },
  createChatChannel: {
    actions: {
      create: 'สร้างห้องแชทสำหรับแฟน',
    },
    errors: {
      3008: 'เกินจำนวนช่องที่สามารถสร้างได้',
      4010: 'ไม่ใช่ผู้สร้างที่สามารถสร้างช่องได้',
      network: commonError.network.message,
    },
    modal: {
      title: 'สร้างห้องแชทสำหรับแฟน',
      guide: {
        primary:
          'คุณผู้สร้าง! ลองสร้างห้องแชทสำหรับแฟนและสื่อสารกับแฟนๆ ได้แบบเรียลไทม์',
        secondary: '*สามารถสร้างห้องแชทสำหรับแฟนได้เพียงหนึ่งห้องต่อผู้สร้าง',
      },
      sections: {
        channelTitle: {
          label: 'ตั้งชื่อห้องแชท',
          placeholder: 'กรุณากรอกชื่อห้องแชทสำหรับคุณและแฟนๆ',
          errMin: 'ไม่ได้กรอกชื่อห้องแชท',
          errMax: 'สามารถกรอกได้สูงสุด 50 ตัวอักษร',
        },
      },
      actions: {
        cancel: 'ยกเลิก',
        create: 'เริ่มต้น',
      },
    },
  },
  enterChatChannel: {
    actions: {
      enter: 'รายการแชท',
    },
    errors: {
      createChannelNotYet: 'ไม่มีห้องแชทสำหรับแฟนที่สร้างโดยผู้สร้าง', // 크리에이터에게 channelUrl이 아직 없음
      enterChatFail: 'ไม่สามารถเข้าถึงการแชทได้ กรุณาลองอีกครั้งในภายหลัง',
    },
    enterCreatorChannel: 'เข้าร่วมห้องแชทสำหรับแฟนของผู้สร้าง',
  },
}
