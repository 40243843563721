import * as BridgeTypes from './types'
import { bridgeToApp, bridgeToWeb } from './bridges'

/**
 * 데브툴에서 브릿지 호출을 위한 리스트
 * @direction Web -> App
 */
export const devToolBridges: BridgeTypes.DevToolBridge[] = [
  {
    handlerKey: 'setPullToRefreshEnabled',
    direction: 'WEB → APP',
    description: '풀 투 리프레쉬 on',
    bridge: bridgeToApp.setPullToRefreshEnabled,
    message: 'true',
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'setPullToRefreshEnabled',
    direction: 'WEB → APP',
    description: '풀 투 리프레쉬 off',
    bridge: bridgeToApp.setPullToRefreshEnabled,
    message: 'false',
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'isReadyToBridge',
    direction: 'WEB → APP',
    description: '브릿지 연결 준비 완료 알리기: 요청',
    bridge: bridgeToApp.isReadyToBridge,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'openWeb',
    direction: 'WEB → APP',
    description: '새로운 브라우저 열기: 요청',
    bridge: bridgeToApp.openWeb,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'openWeb',
    direction: 'APP → WEB',
    description: '새로운 브라우저 열기: 응답',
    bridge: bridgeToWeb.openWeb,
    message: {
      handlerKey: 'openWeb',
      message: '',
      status: 'success',
      data: null,
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'closeWeb',
    direction: 'WEB → APP',
    description: '웹뷰 닫기: 요청',
    bridge: bridgeToApp.closeWeb,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'closeWeb',
    direction: 'APP → WEB',
    description: '웹뷰 닫기: 응답',
    bridge: bridgeToWeb.closeWeb,
    message: {
      handlerKey: 'closeWeb',
      message: '',
      status: 'success',
      data: null,
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'closeWebAndRedirection',
    direction: 'WEB → APP',
    description: '웹뷰 닫으며 이전 웹뷰 화면 이동: 요청',
    bridge: bridgeToApp.closeWebAndRedirection,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'closeWebAndRedirection',
    direction: 'APP → WEB',
    description: '웹뷰 닫으며 이전 웹뷰 화면 이동: 응답',
    bridge: bridgeToWeb.closeWebAndRedirection,
    message: {
      handlerKey: 'closeWebAndRedirection',
      message: '',
      status: 'success',
      data: null,
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'moveHome',
    direction: 'WEB → APP',
    description: '홈으로 이동: 요청',
    bridge: bridgeToApp.moveHome,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'moveHome',
    direction: 'APP → WEB',
    description: '홈으로 이동: 응답',
    bridge: bridgeToWeb.moveHome,
    message: {
      handlerKey: 'moveHome',
      message: '',
      status: 'success',
      data: null,
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'saveStorage',
    direction: 'WEB → APP',
    description: '앱 저장소에 데이터 저장: 요청',
    bridge: bridgeToApp.saveStorage,
    message: {
      userInfo: {
        userCmmnSn: 20230303,
        userSn: 20222388,
      },
      authToken: {
        grantType: 'Bearer',
        accessToken:
          'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyMDIyMjM4OCIsInVzZXJDbW1uU24iOiIyMDIzMDMwMyIsInNjb3BlIjoiYWNjZXNzIiwiaWF0IjoxNjk3NDE5NTA0LCJleHAiOjE2OTc0Mzc1MDR9.Xz-DxROVZlNmMQHi1zzW04gpUOXZzDOt7WD6wBICWBKFOElLgfU5zerz3_6Hnx3TPseanJ_e9g5e4n-SCLoCmQ',
        refreshToken:
          'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyMDIyMjM4OCIsInVzZXJDbW1uU24iOiIyMDIzMDMwMyIsInNjb3BlIjoicmVmcmVzaCIsImlhdCI6MTY5NzQxOTUwNCwiZXhwIjoxNjk4MDI0MzA0fQ.jKcR48fa0qhxnv1F-v4chQ9wOVmkoWuok3O_n16KBeuYXd8GP6-I8rCHZENXHPqkXNUe_5hn02XI2oJ5ZXUP4g',
        accessTokenValidTime: 18000000,
        accessTokenExpireTimestamp: 1697437504226,
      },
      locale: 'ko',
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'saveStorage',
    direction: 'APP → WEB',
    description: '앱 저장소에 데이터 저장: 응답',
    bridge: bridgeToWeb.saveStorage,
    message: {
      handlerKey: 'saveStorage',
      message: '',
      status: 'success',
      data: null,
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'getStorage',
    direction: 'WEB → APP',
    description: '앱 저장소 데이터 읽어 오기: 요청',
    bridge: bridgeToApp.getStorage,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'getStorage',
    direction: 'APP → WEB',
    description: '앱 저장소 데이터 읽어 오기: 응답',
    bridge: bridgeToWeb.getStorage,
    message: {
      handlerKey: 'getStorage',
      message: '',
      status: 'success',
      data: {
        userInfo: {
          userCmmnSn: 20230303,
          userSn: 20222388,
        },
        authToken: {
          grantType: 'Bearer',
          accessToken:
            'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyMDIyMjM4OCIsInVzZXJDbW1uU24iOiIyMDIzMDMwMyIsInNjb3BlIjoiYWNjZXNzIiwiaWF0IjoxNjk3NDE5NTA0LCJleHAiOjE2OTc0Mzc1MDR9.Xz-DxROVZlNmMQHi1zzW04gpUOXZzDOt7WD6wBICWBKFOElLgfU5zerz3_6Hnx3TPseanJ_e9g5e4n-SCLoCmQ',
          refreshToken:
            'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyMDIyMjM4OCIsInVzZXJDbW1uU24iOiIyMDIzMDMwMyIsInNjb3BlIjoicmVmcmVzaCIsImlhdCI6MTY5NzQxOTUwNCwiZXhwIjoxNjk4MDI0MzA0fQ.jKcR48fa0qhxnv1F-v4chQ9wOVmkoWuok3O_n16KBeuYXd8GP6-I8rCHZENXHPqkXNUe_5hn02XI2oJ5ZXUP4g',
          accessTokenValidTime: 18000000,
          accessTokenExpireTimestamp: 1697437504226,
        },
        locale: 'ko',
      },
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'clearStorage',
    direction: 'WEB → APP',
    description: '앱 저장소 데이터 삭제: 요청',
    bridge: bridgeToApp.clearStorage,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'clearStorage',
    direction: 'APP → WEB',
    description: '앱 저장소 데이터 삭제: 응답',
    bridge: bridgeToWeb.clearStorage,
    message: {
      handlerKey: 'clearStorage',
      message: '',
      status: 'success',
      data: null,
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'clearWebLocalStorage',
    direction: 'WEB → APP',
    description: '웹뷰 로컬 스토리지 삭제: 요청',
    bridge: bridgeToApp.clearWebLocalStorage,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'clearWebLocalStorage',
    direction: 'APP → WEB',
    description: '웹뷰 로컬 스토리지 삭제: 응답',
    bridge: bridgeToWeb.clearWebLocalStorage,
    message: {
      handlerKey: 'clearWebLocalStorage',
      message: '',
      status: 'success',
      data: null,
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'clearWebCookie',
    direction: 'WEB → APP',
    description: '웹뷰 쿠키 삭제: 요청',
    bridge: bridgeToApp.clearWebCookie,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'clearWebCookie',
    direction: 'APP → WEB',
    description: '웹뷰 쿠키 삭제: 응답',
    bridge: bridgeToWeb.clearWebCookie,
    message: {
      handlerKey: 'clearWebCookie',
      message: '',
      status: 'success',
      data: null,
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'terminateApp',
    direction: 'WEB → APP',
    description: '앱 종료: 요청',
    bridge: bridgeToApp.terminateApp,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'terminateApp',
    direction: 'APP → WEB',
    description: '앱 종료(리스폰스를 기다리지 않음): 응답',
    bridge: bridgeToWeb.terminateApp,
    message: {
      handlerKey: 'terminateApp',
      message: '',
      status: 'success',
      data: null,
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'checkPermission',
    direction: 'WEB → APP',
    description: '권한 체크: 요청',
    bridge: bridgeToApp.checkPermission,
    message: {
      permission: 'storage',
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'checkPermission',
    direction: 'APP → WEB',
    description: '권한 체크: 응답',
    bridge: bridgeToWeb.checkPermission,
    message: {
      handlerKey: 'checkPermission',
      message: '',
      status: 'success',
      data: {
        permission: 'storage',
        status: 'granted',
      },
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'requestPermission',
    direction: 'WEB → APP',
    description: '권한 요청: 요청',
    bridge: bridgeToApp.requestPermission,
    message: {
      permission: 'storage',
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'requestPermission',
    direction: 'APP → WEB',
    description: '권한 요청: 응답',
    bridge: bridgeToWeb.requestPermission,
    message: {
      handlerKey: 'requestPermission',
      message: '',
      status: 'success',
      data: {
        permission: 'storage',
        status: 'on',
      },
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'forceUpdate',
    direction: 'WEB → APP',
    description: '앱 업데이트를 위해 마켓 이동: 요청',
    bridge: bridgeToApp.forceUpdate,
    message: {
      requiredMinVer: '1.0.1', // 앱 강제 업데이트 최소 버전
      currentVer: '1.0.0', // 앱 현재 버전
      recentVer: '1.0.2', // 앱 최신 버전
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'forceUpdate',
    direction: 'APP → WEB',
    description: '앱 업데이트를 위해 마켓 이동: 응답',
    bridge: bridgeToWeb.forceUpdate,
    message: {
      handlerKey: 'forceUpdate',
      message: '',
      status: 'success',
      data: null,
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'geoLocation',
    direction: 'WEB → APP',
    description: '사용자 위치 요청: 요청',
    bridge: bridgeToApp.geoLocation,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'geoLocation',
    direction: 'APP → WEB',
    description: '사용자 위치 요청: 응답',
    bridge: bridgeToWeb.geoLocation,
    message: {
      handlerKey: 'geoLocation',
      message: '',
      status: 'success',
      data: {
        lat: 37.519179, // latitude 위도 (십진수 도(DD): 41.40338)
        lng: 127.034103, // longitude 경도 (십진수 도(DD): 2.17403)
      },
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'goBack',
    direction: 'APP → WEB',
    alwaysListening: true,
    description: '네비게이션 뒤로가기(안드로이드 물리 뒤로가기 키): 요청',
    bridge: bridgeToWeb.goBack,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  // {
  //   handlerKey: 'goBack',
  //   direction: 'WEB → APP',
  //   description: '네비게이션 뒤로가기(안드로이드 물리 뒤로가기 키): 응답',
  //   bridge: bridgeToWeb.goBack,
  //   message: {
  //     handlerKey: 'goBack',
  //     message: '',
  //     status: 'success',
  //     data: null,
  //   },
  //   version: {
  //     AOS: '1.0.0',
  //     IOS: '1.0.0',
  //   },
  // },
  {
    handlerKey: 'redraw',
    direction: 'APP → WEB',
    alwaysListening: true,
    description: '화면갱신: 요청',
    bridge: bridgeToWeb.redraw,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  // {
  //   handlerKey: 'redraw',
  //   direction: 'APP → WEB',
  //   description: '화면갱신: 응답',
  //   bridge: bridgeToApp.redraw,
  //   message: {
  //     handlerKey: 'redraw',
  //     message: '',
  //     status: 'success',
  //     data: null,
  //   },
  //   version: {
  //     AOS: '1.0.0',
  //     IOS: '1.0.0',
  //   },
  // },
  {
    handlerKey: 'onAppStatusChange',
    direction: 'APP → WEB',
    alwaysListening: true,
    description:
      '앱 상태(foreground | background | notification) 변경 상태 알림: 요청',
    bridge: bridgeToWeb.onAppStatusChange,
    message: 'background',
    version: {
      AOS: '1.0.4',
      IOS: '1.0.4',
    },
  },
  // {
  //   handlerKey: 'onAppStatusChange',
  //   direction: 'APP → WEB',
  //   description: '앱 상태(active) 변경 상태 알림: 응답',
  //   bridge: bridgeToWeb.onAppStatusChange,
  //   message: {
  //     handlerKey: 'onAppStatusChange',
  //     message: '',
  //     status: 'success',
  //     data: 'null',
  //   },
  //   version: {
  //     AOS: '1.0.0',
  //     IOS: '1.0.0',
  //   },
  // },
  {
    handlerKey: 'deepLink',
    direction: 'APP → WEB',
    alwaysListening: true,
    description: '특정 딥링크로 연결된 URL을 전달: 요청',
    bridge: bridgeToWeb.deepLink,
    message: {
      url: 'https://dev.lmnova.com/post/39',
      params: 'customParams=null',
    },
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  // {
  //   handlerKey: 'deepLink',
  //   direction: 'APP → WEB',
  //   description: '특정 딥링크로 연결된 URL을 전달: 응답',
  //   bridge: bridgeToWeb.deepLink,
  //   message: {
  //     handlerKey: 'deepLink',
  //     message: '',
  //     status: 'success',
  //     data: null,
  //   },
  //   version: {
  //     AOS: '1.0.0',
  //     IOS: '1.0.0',
  //   },
  // },
  {
    handlerKey: 'onCloseDialog',
    direction: 'APP → WEB',
    alwaysListening: true,
    description: '네이티브 다이얼로그(웹뷰) 닫았을때 알려줌: 요청',
    bridge: bridgeToWeb.onCloseDialog,
    message: undefined,
    version: {
      AOS: '1.0.0',
      IOS: '1.0.0',
    },
  },
  {
    handlerKey: 'pushMessageToWeb',
    direction: 'APP → WEB',
    alwaysListening: true,
    description: '푸시 메세지를 웹으로 전달',
    bridge: bridgeToWeb.pushMessageToWeb,
    message: {
      type: 'dlink',
      action: 'loginUrlBrowse',
      url: 'https://devlive.lmnova.com#channel=channel_id&userid=sendbirdId&status=start',
    },
    version: {
      AOS: '1.0.3',
      IOS: '1.0.3',
    },
  },
  {
    handlerKey: 'fullscreenStatus',
    direction: 'WEB → APP',
    alwaysListening: false,
    description: '풀 스크린 상태 업데이트',
    bridge: bridgeToWeb.fullscreenStatus,
    message: {
      isFullScreen: true,
      isPortrait: false,
      videoSize: {
        videoWidth: 960,
        videoHeight: 480,
      },
    },
    version: {
      AOS: '1.0.13',
      IOS: '1.0.11',
    },
  },
]
