<script setup lang="ts">
import type {
  NovaTipTapMenuAddVideoEmits,
  NovaTipTapMenuAddVideoProps,
} from './NovaTipTapMenuAddVideo.types'
import { ReplacePostContentsType } from '@store'
import type { Radio } from '@components/NovaRadio/NovaRadio.types'

const emit = defineEmits<NovaTipTapMenuAddVideoEmits>()
const props = defineProps<NovaTipTapMenuAddVideoProps>()

const { t, locale, messages } = useI18n()
const videoTypes = ref<Radio[]>([
  { label: 'Youtube', value: 'youtube', direction: 'row' },
  { label: 'Youtube Shorts', value: 'youtubeShorts', direction: 'row' },
  { label: 'Vimeo', value: 'vimeo', direction: 'row' },
])
const selectedVideo = ref('youtube')
const linkUrl = ref('')
const descriptions = computed(() =>
  (
    (messages.value[locale.value].videoAddPanel as { descriptions: any })
      .descriptions[selectedVideo.value] as string[]
  ).map((_item, index) =>
    t(`videoAddPanel.descriptions.${selectedVideo.value}[${index}]`)
  )
)
const videoUrl = computed(() => {
  switch (selectedVideo.value) {
    case 'youtube':
      return parserByYoutube(linkUrl.value)
    case 'youtubeShorts':
      return parserByYoutubeShorts(linkUrl.value)
    case 'vimeo':
      return parserByVimeo(linkUrl.value)
    default:
      throw new Error('video type is not match')
  }
})
const isValidYoutube = computed(() => {
  switch (selectedVideo.value) {
    case 'youtube':
      return validYoutube(linkUrl.value)
    case 'youtubeShorts':
      return validYoutubeShorts(linkUrl.value)
    case 'vimeo':
      return validVimeo(linkUrl.value)
  }
})

watch(
  () => props.isOpen,
  (cur) => {
    if (cur) init()
  }
)

// init
const init = () => {
  selectedVideo.value = 'youtube'
  linkUrl.value = ''
}

const validYoutube = (url: string) => {
  const regex =
    /^(https?:\/\/)?(www\.|m\.)?(youtube.com\/(watch\?v=|embed\/)|youtu\.be\/)([\w-]+)/g
  return regex.test(url)
}

const validYoutubeShorts = (url: string) => {
  const regex =
    /^(https?:\/\/)?(www\.)?youtube\.com\/(shorts\/|embed\/)([a-zA-Z0-9_-]+)(\?.*)?$/
  return regex.test(url)
}

const validVimeo = (url: string) => {
  const regex =
    /^(https?:\/\/)?(www\.)?(vimeo\.com\/|player\.vimeo\.com\/video\/)[a-zA-Z0-9_-]+$/
  return regex.test(url)
}

// youtube parser
const parserByYoutube = (url: string): string => {
  const regex =
    /(https?:\/\/)?(www\.|m\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/)([\w-]+)(\?.*)?/

  const match = regex.exec(url)
  return match ? `https://www.youtube.com/embed/${match[5]}` : ''
}

// youtube shorts parser
const parserByYoutubeShorts = (str: string): string => {
  const pattern =
    /^(?:https?:\/\/)?(?:www\.)?(?:(?:youtube\.com\/embed\/)|(?:youtube\.com\/shorts\/))([a-zA-Z0-9_-]+)(\?.*)?$/

  const match = str.match(pattern)
  if (match && match[1]) {
    return `https://www.youtube.com/embed/${match[1]}`
  } else {
    return ''
  }
}

// vimeo parser
const parserByVimeo = (str: string): string => {
  return str.replace(/www\./, '')
}

// video type change
const handleOnChangeRadio = (radio: string) => {
  selectedVideo.value = radio
}

// add video link
const handleOnSetLink = () => {
  props.editor
    .chain()
    .focus()
    .extendMarkRange('link')
    .setLink({
      href: videoUrl.value,
      // @ts-ignore
      class: ReplacePostContentsType.VIDEO,
    })
    .run()
  emit('onClose')
}
</script>

<template>
  <NovaBoxBase class="add-video-url" :floating="true">
    <NovaRadioGroup
      :label="$t('videoAddPanel.videoTypeLabel')"
      :current="selectedVideo"
      :radios="videoTypes"
      :direction="'horizon'"
      :equal-width="true"
      :theme="'button'"
      @on-change-radio="handleOnChangeRadio"
    />

    <div class="video-url-wrap">
      <NovaInput
        v-model="linkUrl"
        :label="$t('videoAddPanel.urlLabel')"
        :placeholder="$t('videoAddPanel.urlPlaceholder')"
      >
        <template #actions>
          <NovaButtonText
            :label="$t('videoAddPanel.addBtn')"
            :theme="'primary-blue'"
            :disabled="!isValidYoutube"
            @click="handleOnSetLink"
          />
        </template>
      </NovaInput>

      <NovaDescriptions :descriptions="descriptions" />
    </div>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.add-video-url {
  display: flex;
  flex-direction: column !important;
  gap: 16px !important;
  min-width: 340px;
  max-width: 100%;

  .video-url-wrap {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}
</style>
