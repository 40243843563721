const apiPath = {
  USER: '/user',
  POST: '/posting',
  SEARCH: '/search',
  MYPAGE: '/my',
  ETC: '/etc',
  IMG: '/comm/img',
  VIDEO: '/comm/video',
  COMM: '/comm',
  CREATOR: '/creator',
  BLOCKCHAIN: '/blockchain',
  W3CF: '/w3cf',
  FUND: '/fund',
  AUCTION: '/auction',
  FIREBASE: '/fcm',
  SENDBIRD: '/sendbird',
  ATTENDANCE: '/attendance',
  FACILITY: '/facility',
  SUPERMOON: '/super-moon',
  OPENSEARCH: '/opensearch',
}

export default apiPath
