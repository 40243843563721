import { corporationInfo } from './corporationInfo'
import type {
  CreatorSubscribeAccessCode,
  CreatorSubscribeAccessStatus,
  CreatorSubscribeErrorMessage,
} from '@mixins/useSubscribe'
import type { PremiumPostsOrderBy } from '@store/post/type'

export const subscribe = {
  premiumBrand: '<span class="premium-brand-logo">NOVA<sup>+</sup></span>',
  subscribeGrade: {
    premium: 'Premium',
    novaPlus: 'Nova+',
  },
  subscribeGate: {
    benefitSetting: 'การตั้งค่าผลประโยชน์ {subscribeGrade}',
    balance: 'ประวัติ {subscribeGrade}',
  },
  subscribeAccessCode: {
    opened: 'เปิด {subscribeGrade}',
    support: 'สนับสนุน {subscribeGrade}',
    cancel: 'ยกเลิกการสมัคร {subscribeGrade}',
    refund: 'การคืนเงิน {subscribeGrade}',
  } as {
    [Property in CreatorSubscribeAccessCode]: string
  },
  premiumCreators: {
    title: 'รายการ {premiumBrand} ของฉัน',
    error:
      'ไม่สามารถเรียกดูผู้สร้างเนื้อหาที่คุณสนับสนุนในระดับ {subscribeGrade} ได้ โปรดลองอีกครั้งในภายหลัง',
    empty: 'ไม่มีผู้สร้าง',
  },
  creatorsByRecommended: {
    title: 'ผู้สร้างที่แนะนำ',
    error:
      'ไม่สามารถเรียกดูผู้สร้างเนื้อหาที่แนะนำได้ โปรดลองอีกครั้งในภายหลัง',
    empty: 'ไม่มีผู้สร้างที่แนะนำ',
  },
  premiumPosts: {
    orderBy: {
      newest: 'ใหม่',
      oldest: 'เก่า',
      hot: 'ฮอต',
    } as { [Property in PremiumPostsOrderBy]: string },
    error:
      'ไม่สามารถเรียกดูเนื้อหาของผู้สร้างเนื้อหาที่คุณสนับสนุนในระดับ {subscribeGrade} ได้ โปรดลองอีกครั้งในภายหลัง',
    empty:
      'ติดตามผู้สร้างเนื้อหาที่คุณสนับสนุนในระดับ {subscribeGrade} และสัมผัสเนื้อหาพิเศษสำหรับผู้สนับสนุนเท่านั้น!',
    emptyByNoPremium: 'ไม่มีเนื้อหาของ {subscribeGrade}',
    requireSignin: 'เพลิดเพลินกับเนื้อหาหลายรูปแบบจากผู้สร้างของ I LIKE LM',
  },
  premiumPost: {
    new: 'เผยแพร่เนื้อหาพิเศษสำหรับผู้สนับสนุน {subscribeGrade}!',
    empty: 'หลังจากเปิด {subscribeGrade} คุณสามารถสร้างโพสต์ได้',
    blockMessage: 'เนื้อหานี้มองเห็นได้เฉพาะสำหรับผู้สนับสนุน {subscribeGrade}',
    blockMessageAction: 'สนับสนุนระดับ {subscribeGrade}',
  },
  createPremiumPost: {
    requireCreator: {
      title: 'การแจ้งเตือน',
      message:
        'ในการเขียนโพสต์ {subscribeGrade} คุณต้องเปลี่ยนเป็นผู้สร้าง คุณต้องการดำเนินการเปลี่ยนแปลงหรือไม่?',
    },
    requirePremiumOpened: {
      title: 'การแจ้งเตือน',
      message:
        'หลังจากเปิด {subscribeGrade} คุณสามารถสร้างโพสต์ได้ คุณต้องการดำเนินการเปิดหรือไม่?',
      toastMessage: 'หลังจากเปิด {subscribeGrade} คุณสามารถสร้างโพสต์ได้',
    },
  },
  sections: {
    subscribeSupportDetail: {
      title: 'ข้อมูลการสนับสนุนของ {premiumBrand}',
      benefit: 'ผลประโยชน์ของ {creatorNickname} จาก {premiumBrand}',
      support:
        'จำนวนเงินสนับสนุนรายเดือนระดับ {subscribeGrade} ของผู้สร้างเนื้อหา',
      paymentPrice: 'จำนวนเงินที่ชำระ',
      vat: 'ภาษีมูลค่าเพิ่ม',
    },
    subscribeCancelDetail: {
      title: 'ข้อมูล {subscribeGrade}',
      benefit: 'ผลประโยชน์',
      guide:
        'คุณแน่ใจหรือไม่ว่าต้องการยกเลิกผลประโยชน์เหล่านี้? {subscribeGrade} นี้จะมีผลจนถึง {date}!',
    },
    subscribeRefundDetail: {
      title: 'ข้อมูล {subscribeGrade}',
      benefit: 'ผลประโยชน์',
      guide:
        'คุณต้องการดำเนินการขอคืนเงิน {subscribeGrade} หรือไม่?<br />โปรดทราบว่าหากคุณสมัครขอคืนเงิน ผลประโยชน์ {subscribeGrade} จะถูกยกเลิกทันที',
    },
    cancelReasons: {
      title: 'เหตุผลในการยกเลิก {subscribeGrade}',
      reasons: {
        expensive: 'ราคาสูงเกินไป',
        technical: 'มีปัญหาทางเทคนิค',
        interesting: 'ไม่ใช่เนื้อหาที่ต้องการดู',
        useService: 'ไม่ค่อยได้ใช้บริการนี้',
        contentsQuality: 'ไม่พอใจกับคุณภาพเนื้อหา',
        etc: 'อื่น ๆ',
      },
    },
    refundForm: {
      title: 'กรอกข้อมูลคืนเงิน',
      form: {
        bank: {
          label: 'ธนาคารที่คืนเงิน',
          placeholder: 'โปรดเลือกธนาคารคืนเงิน',
        },
        id: {
          label: 'ID',
          placeholder: '',
        },
        bankAccount: {
          label: 'บัญชีที่คืนเงิน',
          placeholder: 'โปรดกรอกข้อมูลบัญชีคืนเงินของคุณ',
          description: 'โปรดกรอกหมายเลขบัญชีของคุณรวมถึงขีดกลาง (-)',
        },
        nickname: {
          label: 'ชื่อเล่น',
          placeholder: '',
          description:
            'คุณไม่สามารถเปลี่ยนชื่อเล่นได้ในขณะที่กำลังดำเนินการคืนเงิน',
        },
        bankAccountHolder: {
          label: 'ชื่อเจ้าของบัญชี',
          placeholder: 'โปรดกรอกชื่อเจ้าของบัญชี',
        },
      },
    },
    openBenefit: {
      title: 'การตั้งค่าผลประโยชน์ {premiumBrand}',
      benefit: 'ผลประโยชน์',
      txt: 'กรุณาเขียนสิทธิประโยชน์ที่มอบให้แก่ผู้สนับสนุน {premiumBrand} ของผู้สร้างเนื้อหา (1 รายการขึ้นไป)',
      buttonsAdd: 'เพิ่มผลประโยชน์',
      guideList: {
        example1: 'ตัวอย่าง 1. อัปโหลดการ์ตูนต้นฉบับสัปดาห์ละหนึ่งครั้ง',
        example2:
          'ตัวอย่าง 2. เผยแพร่คอลัมน์สามครั้งต่อสัปดาห์ (หัวข้อรวมถึงวิดีโอ หนังสือ การ์ตูน ฯลฯ)',
      },
      benefitKor: 'การสร้างผลประโยชน์ (Kor)',
      benefitEng: 'การสร้างผลประโยชน์ (Eng)',
      benefitTha: 'การสร้างผลประโยชน์ (Thai)',
      benefitSpa: 'การสร้างผลประโยชน์ (Spa)',
    },
    payment: {
      title: 'วิธีการชำระเงิน',
      subscribePrice: 'จำนวนเงินสนับสนุุน',
    },
    guide: {
      title: 'ประกาศ {subscribeAccessCode}',
    },
    terms: {
      title: 'ข้อกำหนดและเงื่อนไข',
    },
    openedBenefitGuide: {
      title: 'ผลประโยชน์จากการเปิด {premiumBrand}',
      guide:
        'เมื่อ {creatorNickname} เปิด {subscribeGrade} คุณจะได้รับ 90% ของจำนวนเงินสนับสนุนจาก {subscribeGrade} เป็นรายได้',
      benefits: ['รายได้ที่มั่นคง', 'การสื่อสารกับแฟนๆ', 'โอกาสในการสร้าง NFT'],
    },
  },
  actions: {
    support: 'สนับสนุนให้กับ {subscribeGrade}',
    supporting: 'สนับสนุน {subscribeGrade}',
    open: 'เปิด {subscribeGrade}',
    modify: 'บันทึก {subscribeGrade}',
    subscribeCancel: 'ยกเลิกการสนับสนุนระดับ {subscribeGrade}',
    subscribeContinue: 'ดำเนินการต่อ',
    refund: 'ขอคืนเงิน {subscribeGrade}',
    cancel: 'ยกเลิก',
  },
  errorMessage: {
    requireCreatorInfo: {
      title: 'ผู้สร้างเนื้อหาที่คุณค้นหาอาจได้ออกจากแพลตฟอร์มหรือไม่อยู่แล้ว',
      message: 'กรุณาลองค้นหาผู้สร้างคนอื่น',
      actionLabel: 'ย้อนกลับ',
    },
    requireSignIn: {
      title: 'หน้านี้ต้องการให้เข้าสู่ระบบ',
      message: 'คุณต้องการเข้าสู่ระบบหรือไม่?',
      actionLabel: 'เข้าสู่ระบบ',
    },
    notEqualCreatorUserSn: {
      title: 'ไม่สามารถดำเนินการ {subscribeAccessCode} ได้',
      message: 'เฉพาะผู้สร้างเท่านั้นที่สามารถเปิด {subscribeGrade} ได้',
      actionLabel: 'ย้อนกลับ',
    },
    yetSubscribeService: {
      title: 'ไม่สามารถดำเนินการ {subscribeAccessCode} ได้',
      message: 'ผู้สร้างยังไม่ได้เปิด {subscribeGrade}',
      actionLabel: 'ย้อนกลับ',
    },
    requireInfo: {
      title: 'ไม่สามารถเรียกดูข้อมูลที่ต้องการได้',
      message: 'กรุณาลองอีกครั้ง',
      actionLabel: 'กรุณาลองอีกครั้ง',
    },
    requireDifUser: {
      title: 'ไม่สามารถดำเนินการ {subscribeAccessCode} ได้',
      message: 'ผู้สร้างไม่สามารถ {subscribeAccessCode} ตัวเองได้',
      actionLabel: 'ย้อนกลับ',
    },
    alreadySubscribeSupport: {
      title: 'คุณกำลังสนับสนุน {subscribeGrade} อยู่แล้ว',
      message: 'คุณสามารถเพลิดเพลินกับ {subscribeGrade} ได้จนถึง {expireDate}',
      actionLabel: 'ย้อนกลับ',
    },
    alreadySubscribeNext: {
      title: 'ไม่สามารถสนับสนุนให้กับ {subscribeGrade} ได้',
      message: 'คุณกำลังสนับสนุน {subscribeGrade} สำหรับช่วงเวลาถัดไปอยู่แล้ว',
      actionLabel: 'ย้อนกลับ',
    },
    unableSubscribeNext: {
      title: 'คุณกำลังสนับสนุน {subscriptionGrade} อยู่แล้ว',
      message:
        'คุณสามารถเริ่มการสนับสนุน {subscribeGrade} ถัดไปได้จาก 3 วันก่อนวันหมดอายุ',
      actionLabel: 'ย้อนกลับ',
    },
  } as {
    [Property in CreatorSubscribeAccessStatus]: CreatorSubscribeErrorMessage
  },
  success: {
    opened: {
      open: '{subscribeGrade} ได้เปิดใช้งานสำเร็จแล้ว',
      modify: 'การตั้งค่า {subscribeGrade} ได้บันทึกสำเร็จแล้ว',
    },
  },
  apiError: {
    support: {
      5300: 'ไม่มีข้อมูลเกี่ยวกับโปรแกรมสมาชิก {subscribeGrade} ของผู้สร้าง',
      6001: 'การเรียกหน้าชำระเงิน NFT ล้มเหลว',
    },
    opened: {
      passBenefits: 'เนื้อหาผลประโยชน์ต้องมีความยาวอย่างน้อย 2 ตัวอักษร',
      open: 'ไม่สามารถเปิด {subscribeGrade} ได้ กรุณาลองอีกครั้งภายหลัง',
      modify:
        'ไม่สามารถบันทึกการตั้งค่า {subscribeGrade} ได้ กรุณาลองอีกครั้งภายหลัง',
    },
  },
  supportPayProcess: {
    success: {
      title: 'การแจ้งเตือน',
      message: 'การบริจาค {subscribeGrade} ของคุณเสร็จสมบูรณ์แล้ว',
    },
    fail: {
      title: 'การแจ้งเตือน',
      message:
        'การบริจาค {subscribeGrade} ของคุณไม่เสร็จสมบูรณ์</br>กรุณาลองอีกครั้ง</br> เหตุผล: {failMessage}',
      failMessage: {
        fail: 'การสนับสนุนระดับ {subscribeGrade} ของคุณไม่สำเร็จ กรุณาลองอีกครั้ง',
        requireRetry: 'บริการขณะนี้ไม่พร้อมใช้งาน กรุณาลองอีกครั้งภายหลัง',
        serverTimeout:
          'เวลาการชำระเงินได้หมดอายุแล้ว กรุณาลองอีกครั้งสำหรับการสนับสนุนระดับ {subscribeGrade}',
        clientTimeout: `เกิดปัญหาในการชำระเงิน กรุณาลองอีกครั้ง หากคุณมีคำถามใด ๆ กรุณาติดต่อที่ <a href="mailto:${corporationInfo.infos.email}">${corporationInfo.infos.email}</a>`,
      },
    },
    loading: 'กำลังตรวจสอบข้อมูลการชำระเงิน',
  },
  expiredNotification: {
    title:
      'สิทธิประโยชน์จากการสนับสนุนระดับ {subscribeGrade} ของ {creatorNickname} หมดอายุแล้ว',
    description:
      'หากต้องการใช้เนื้อหาระดับ {subscribeGrade} ต่อไปในเดือนหน้า กรุณาสมัครสนับสนุนระดับ {subscribeGrade} อีกครั้ง',
    support: 'สนับสนุนเพื่อ {subscribeGrade}',
    confirm: 'ยืนยัน',
  },
}
