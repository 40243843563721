/**
 * 권한 요청
 */
export const requestAppPermission = {
  // permission: {
  //   camera: {
  //     IOS: '카메라(en)',
  //     AOS: '카메라(en)',
  //   },
  //   storage: {
  //     IOS: '사진(en)',
  //     AOS: '갤러리(en)',
  //   },
  //   address: {
  //     IOS: '주소록(en)',
  //     AOS: '주소록(en)',
  //   },
  //   location: {
  //     IOS: '위치(en)',
  //     AOS: '위치(en)',
  //   },
  // },
  requestModal: {
    description: {
      storage: {
        post: "ไม่มีสิทธิ์เข้าถึง 'ภาพ/แกลเลอรี' ที่จำเป็นสำหรับการเขียนโพสต์ กรุณาไปที่การตั้งค่าและปรับสิทธิ์",
        profileImage:
          "ไม่มีสิทธิ์เข้าถึง 'ภาพ/แกลเลอรี' ที่จำเป็นสำหรับการเปลี่ยนแปลงภาพโปรไฟล์ กรุณาไปที่การตั้งค่าและปรับสิทธิ์",
        mainProfileImage:
          "ไม่มีสิทธิ์เข้าถึง 'ภาพ/แกลเลอรี' ที่จำเป็นสำหรับการเปลี่ยนแปลงภาพโปรไฟล์หลัก กรุณาไปที่การตั้งค่าและปรับสิทธิ์",
        homeHeaderImage:
          "ไม่มีสิทธิ์เข้าถึง 'ภาพ/แกลเลอรี' ที่จำเป็นสำหรับการเปลี่ยนแปลงภาพส่วนหัว กรุณาไปที่การตั้งค่าและปรับสิทธิ์",
      },
    },
  },
}
