import { subscribe } from './subscribe'
/**
 * 알림함
 */
export const notificationsPanel = {
  tabs: {
    NOT_PLS: '일반',
    PLS: subscribe.premiumBrand,
  } as { [Property in NotificationType]: string },
}
