import type { CorporationInfoProperty } from '@components/NovaBoxCorporationInfo/NovaBoxCorporationInfo.types'

/**
 * 회사 정보
 */
export const corporationInfo = {
  title: 'ข้อมูลธุรกิจของบริษัท',
  copyright: 'The Moon Labs Inc. © {year} สงวนสิทธิ์ทุกประการ',
  properties: {
    name: 'บริษัท',
    owner: 'ซีอีโอ',
    address: 'ที่อยู่',
    businessNumber: 'หมายเลขทะเบียนธุรกิจ',
    // corporationNumber: 'Corporation Registration No.',
    tel: 'เบอร์โทรหลัก',
    fax: 'แฟกซ์',
    email: 'อีเมล',
  } as { [Property in CorporationInfoProperty]: string },
  infos: {
    name: 'The Moon Labs Inc.',
    owner: 'ซอง-อุก มูน',
    address: 'ชั้น 3, 9-11, ถนนออนจู 147-กิล, เขตกันนัม, โซล, สาธารณรัฐเกาหลี',
    businessNumber: '372-86-02722',
    // corporationNumber: '110111-8145107',
    tel: '+82-2-3469-7100',
    fax: '+82-2-3452-1802',
    email: "cs{'@'}ilikelm.com",
  } as { [Property in CorporationInfoProperty]: string },
}
