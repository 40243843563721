/**
 * 알림 권한
 */
export const notificationPermission = {
  actions: {
    cancel: 'ยกเลิก',
    ok: 'ตกลง',
    requestPermissions: 'ขอร้อง',
    config: 'การตั้งค่า',
    notNow: 'ภายหลัง',
  },
  modals: {
    beforeRequest: {
      title: 'การตั้งค่าการแจ้งเตือน',
      message:
        'เราต้องการแจ้งให้คุณทราบเกี่ยวกับการตอบสนองกิจกรรมใหม่บนเว็บไซต์',
    },
  },
}
