/**
 * 출석체크 관련 언어팩
 */
export const dailyCheck = {
  check: 'เช็คอิน',
  dailyCheckin: 'เช็คอิน',
  checkedIn: 'เช็คอินสำเร็จ!',
  notification: 'การแจ้งเตือนการเช็คอิน',
  totalCheckinDays: 'จำนวนวันที่เช็คอิน',
  consecutiveCheckinDays: 'จำนวนวันที่เช็คอินต่อเนื่อง',
  guide: 'ข้อควรระวัง',
  guides: [
    'คะแนนสำหรับกิจกรรมเช็คอินคือ 5 คะแนน',
    'เมื่อเปิดการแจ้งเตือนการเช็คอิน การแจ้งเตือนจะถูกส่งทุกวันเวลา 14:00 น.',
    'สามารถเช็คอินได้ตั้งแต่เวลา 00:00 น. ของทุกวัน (ตามเวลา KST จำกัด 1 ครั้งต่อวัน)',
    'ต้องกดปุ่มเช็คอินเพื่อให้การเช็คอินได้รับการยอมรับ',
    'นโยบายการเช็คอินอาจมีการเปลี่ยนแปลงหรือหยุดชั่วคราวโดยไม่แจ้งล่วงหน้าเนื่องจากเหตุผลของบริษัท',
    'หากเช็คอินด้วยวิธีที่ผิดปกติ อาจถูกตัดสิทธิ์จากการได้รับรางวัล',
    'คะแนนกิจกรรมเช็คอินจะถูกนำไปใช้กับรางวัลประจำสัปดาห์ทุกวันอังคาร',
  ],
  reqDailyCheck: {
    success: 'การเช็คอินเสร็จสมบูรณ์แล้ว',
    errors: {
      2010: 'คุณได้ทำการเช็คอินเรียบร้อยแล้ว',
    },
  },
}
