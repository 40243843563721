/**
 * 크리에이터 홈페이지 Url
 */
export const creatorHomeLink = {
  label: 'ลิงก์ I LIKE LM',
  edit: {
    placeholder: 'กรุณาใส่ที่อยู่ของหน้าหลักของคุณ',
  },
  tools: {
    clipboard: {
      tooltip: 'คัดลอกลิงก์ I LIKE LM',
      toastMessage: {
        success: 'ลิงก์ได้ถูกคัดลอกไปยังคลิปบอร์ดแล้ว',
      },
    },
    edit: {
      tooltip: 'แก้ไข',
      toastMessage: {
        unavailableLmNovaLink:
          'มีอักขระที่ไม่ถูกต้องในข้อความ กรุณาใช้ลิงก์ I LIKE LM อื่น',
        success: 'ลิงก์ของหน้าหลักของคุณได้ถูกแก้ไข',
        failDefault:
          'ขออภัย บริการของเราไม่สามารถใช้งานได้ในขณะนี้ กรุณาลองอีกครั้งในภายหลัง',
        failEqual: 'ลิงก์นี้เหมือนกับลิงก์ปัจจุบันของคุณ กรุณาลองลิงก์อื่น',
        failUpdate: 'ไม่สามารถบันทึกได้ กรุณาลองอีกครั้งในภายหลัง',
        failDuplicate: 'ถูกใช้ไปแล้ว',
        failLength: 'กรุณาใส่มากกว่า {min} ตัวอักษร',
        failCharacter: 'กรุณาใส่การผสมผสานของตัวอักษรภาษาอังกฤษและตัวเลข',
        checkDuplicateBeforeSave: 'กรุณาตรวจสอบการซ้ำก่อนทำการบันทึก',
      },
    },
  },
}
