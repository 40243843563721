/**
 * 결제 모듈
 */
export const pay = {
  payType: {
    name: {
      CC: 'การชำระเงินด้วยบัตรเครดิต', // 신용카드
      AT: 'การโอนเงินผ่านธนาคาร', // 계좌이체
    } as { [Property in PayType]: string },
  },
  iframe: {
    loadFail: 'ไม่สามารถโหลดโมดูลการชำระเงินได้',
  },
}
