import type {
  BaseInfinityScrollList,
  BooleanYn,
  BooleanCode,
  UserCodeType,
  CommunityUserType,
  CreatorGrade,
  CreatorOrganizationCode,
  FileContainer,
  FileImageContainer,
  FileVideoContainer,
} from '@store/types'
import type { Hashtag } from '@store/etc/type'

/**
 * 특수 카테고리 코드
 */
export enum SpecialCategoryCode {
  CREATOR_CONTENTS = 'C1', // 크리에이터 콘텐츠
  FREE_FORUM = 'P2', // 자유게시판
  NOVA_CHALLENGE = 'P1', // 노바 첼린지
}

/**
 * 카테고리 코드
 */
export type CategoryCode = SpecialCategoryCode | string

/**
 * 카테고리 일련번호
 * @description AI 콘텐츠 등록 여부를 판단하기 위해 클라이언트가 enum 으로 관리
 */
export enum CategorySN {
  CREATOR_CONTENTS = 14,
}

/**
 * 카테고리
 */
export interface Category {
  cmtyNttCtgrySn: number // 카테고리 일련번호
  ctgryDfltCeckAt: BooleanYn // 카테고리 기본(DEFAULT) 체크 여부
  ctgryDfltWordsCode: CategoryCode // 카테고리 기본(DEFAULT) 문구 코드
  ctgryNttWritngAuthorCodeArray: string // 게시물 카테고리 작성권한 코드 배열: 포스팅가능한권한코드배열( "," 로 구분). 계정유형코드(ACNT_TY_CODE - P,M) + 커뮤니티사용자구분코드(CMTY_USER_SE_CODE - G(일반) C(크리에이터) A(소속사,제휴사) S(총판))
  ctgryNttWritngCoLmttAt: BooleanYn // 카테고리 게시물 작성 횟수 제한 여부
  nttCtgryNm: string // 카테고리 명
  nttCtgryTyCode: UserCodeType // 카테고리 타입코드 : P(사용자) M(관리) C(크리에이터)
}

/**
 * 카테고리 리스트 아이템
 */
export interface CategoryListItem extends Category {
  lowerCategoryList?: Category[] // 하위 카테고리 목록
  ctgryAtmcOpnAt?: BooleanYn // 카테고리 자동 오픈 여부
}

/**
 * 포스트 개인화 액션
 */
export type PostPrivateAction =
  | 'share' // 공유하기
  | 'scrap' // 스크랩 하기
  | 'cancelScrap' // 스크랩 하기
  | 'hide' // 스크랩 숨기기
  | 'report' // 스크랩 신고하기
  | 'modify' // 스크랩 수정
  | 'remove' // 스크랩 삭제
  | 'donate' // 후원하기
  | 'edit' // 게시글 수정

/**
 * CreatePostPayload
 * @description 포스트 등록 타입
 * cmtyNttCtgrySn : 커뮤니티 게시물 카테고리 일련번호
 * hashTagList: 해시태그 목록
 * tempImgUrlList: 임시 이미지 url 목록
 * nttOthbcScopeCode:ScopeCodeType
 * nttSj: 게시물 제목
 * nttCn: 게시물 내용
 * ScopeCodeType
 * PUBL(공개)
 * PRIV(개인만)
 * PAID(유료)
 */
export type ScopeCodeType =
  | 'PUBL' // 공개
  | 'PRIV' // 개인만
  | 'PAID' // 유료

/**
 * 좋아요 한 사용자
 */
export interface RecommendUser {
  userSn: number // 좋아요 한 사용자 일련번호
  userNcnm: string // 좋아요 한 사용자 닉네임
  profileImgUrl: string // 좋아요 한 사용자 포트레이트 이미지
}

/**
 * [포스트, 댓글] 좋아요, 좋아요 취소, 싫어요, 싫어요 취소 값 및 업데이트 해야할 값들
 */
export interface RecommendAndUnRecommendSuccess {
  rcFlag: BooleanCode // 좋아요 여부
  dvFlag: BooleanCode // 싫어요
  rcCount: number // 업데이트 좋아요 수
  recommendUserList?: RecommendUser[] // 좋아요 한 사용자 목록
}

/**
 * 게시물 타입 코드
 */
export type NttTypeCode =
  | 'NORM' // 일반형
  | 'CARD' // 카드형
  | 'CVOD' // 영상형

/**
 * 포스트 목록 아이템
 */
export interface PostsItem {
  answerCount: number // 댓글 개수
  cmtyNttCtgrySn: number | null // 카테고리 일련번호
  cmtyNttSn: number // 커뮤니티 게시물 일련번호
  cmtyUserSeCode: CommunityUserType // 사용자 타입 (G: 일반, C: 크리에이터)
  creatorLink: string | null // 크리에이터 링크
  crtrOrgnztTyCode: CreatorOrganizationCode // 크리에이터 조직 구분 코드
  crtrSpclGradCode: CreatorGrade | null // 크리에이터 등급: cmtyUserSeCode 가 'G' 일때 null
  dvFlag: BooleanCode | null // 싫어요 구분 (0: 해제, 1: 활성, null: 포스트가 프리미엄이고 미구독자가 조회한 경우 | 로그인 안한 경우)
  followFlag: BooleanCode // 포스트 작성자가 크리에이터 일때 팔로잉 여부
  hashTagList: Hashtag[] | null // 게시물 해시태그 목록
  imageOrVideoList: FileContainer | null
  nttOthbcScopeCode: ScopeCodeType // 공개 범위 타입 코드
  nttRegistDt: string // 작성일
  nttSj: string // 게시물 제목
  nttTyCode: NttTypeCode // 게시물 타입
  proflMediaInfo: string | null // 프로필 파일 정보(프로필 이미지)
  rcCount: number // 좋아요 수
  rcFlag: BooleanCode | null // 좋아요 구분 (0: 해제, 1: 활성, null: 포스트가 프리미엄이고 미구독자가 조회한 경우)
  rdCount: number // 조회수
  recommendUserList: RecommendUser[] // 좋아요 한 사용자 목록
  reportableAt: BooleanYn //  신고 가능 여부 Y/N
  scFlag: BooleanCode | null // 스크랩 여부(0: 미스크랩, 1: 스크랩, null: 포스트가 프리미엄이고 미구독자가 조회한 경우)
  shCount: number | null // 공유 개수(포스트가 프리미엄이고 미구독자가 조회한 경우 null)
  sumSvcActScore: number // 인기순 기준 점수(인기순 포스트 목록 조회시 페이로드 lastSumSvcActScore에 사용함)
  topFlag: BooleanCode | null // 상단 고정 여부
  userNcnm: string // 작성자 닉네임
  userSn: number // 작성자 일련번호
  nttCn: string | null // 게시물 내용
  prmbrshSbscrbAt: BooleanYn | null // 프리미엄 구독여부(Y: 구독중, N: 구독안함, null: 포스트 작성자와 조회한 사용자가 동일)
  prmbrshCntntsAt: BooleanYn // 프리미엄 멤버십 콘텐츠 여부
  blurFileUrl: string | null // 프리미엄 포스트일 경우 string, 포스트가 프리미엄이고 미구독자가 조회한 경우, 프리미엄 포스트가 아닐 경우 null
  nttSndbrdLiveAt: BooleanYn // 라이브 여부 Y/N
  nttAiPrcuseAt: BooleanYn // 게시물 AI 활용 여부 Y/N
  randSeed: string | null // 추천순 randSeed
  nttRecomendStdrDt: string | null // 랭킹 기준 일시

  // 클라이언트에서 사용하는 모델
  isUpdateBefore?: boolean // 방금전 생성 여부(포스트 생성시 이미지 리사이즈 기준을 비동기로 처리하지 않기 때문)
}

/**
 * 포스트 좋아요, 좋아요 취소, 싫어요, 싫어요 취소, 스크랩, 스크랩 취소, 공유하기 페이로드
 */
export interface PostActionPayload {
  cmtyNttSn: number
}

/**
 * 포스트 신고하기 페이로드
 */
export interface PostReportPayload extends PostActionPayload {
  rptCn: string // 신고 내용
  sanctnsRptClCode: string // 지재 신고 분류 코드
}

/**
 * 포스트 상세
 */
export interface Post {
  recommendUserList: RecommendUser[] // 좋아요 한 사용자 리스트
  answerCount: number // 댓글수
  crtrOrgnztTyCode: CreatorOrganizationCode // 크리에이터 조직 구분 코드
  cmtyNttSn: number // 커뮤니티 게시물 일련번호
  nttRegistDt: string // 작성일
  dvFlag: BooleanCode // 싫어요 구분 (0: 해제, 1: 활성)
  hashTagList: Hashtag[] // 해시태그 리스트
  nttCn: string // 게시물 내용
  nttOthbcScopeCode: ScopeCodeType // 게시물 공개 범위 코드
  nttSj: string // 게시물 제목
  nttTyCode: NttTypeCode // 게시물 타입
  proflMediaInfo: string | null // 프로필 파일 정보
  rcCount: number // 좋아요 수
  rcFlag: BooleanCode // 좋아요 구분
  rdCount: number // 조회수
  reportableAt: BooleanYn //  신고 가능 여부 Y/N
  scFlag: BooleanCode // 스크랩 구분
  shCount: number // 공유수
  bkFlag: BooleanCode // 숨김여부
  userNcnm: string // 사용자 닉네임
  userSn: number // 게시글 사용자 일련번호
  sumSvcActScore: number | null // 인기순 기준 점수(인기순 포스트 목록 조회시 페이로드 lastSumSvcActScore에 사용함)
  cmtyUserSeCode: CommunityUserType // 사용자 타입 (G: 일반, C: 크리에이터)
  crtrSpclGradCode: CreatorGrade | null // 크리에이터 등급: cmtyUserSeCode 가 'G' 일때 null
  followFlag: BooleanCode // 포스트 작성자가 크리에이터 일때 팔로잉 여부
  cmtyNttCtgrySn: number | null // 카테고리 일련번호
  creatorLink: string // 크리에이터 별칭
  imageOrVideoList: FileContainer | null
  prmbrshCntntsAt: BooleanYn // 프리미엄 멤버십 콘텐츠 여부
  nttSndbrdLiveAt: BooleanYn // 라이브 여부 Y/N
  nttAiPrcuseAt: BooleanYn // 게시물 AI 활용 여부 Y/N

  // 클라이언트에서 사용하기 위한 모델
  prmbrshSbscrbAt?: BooleanYn | null // 프리미엄 구독여부(Y: 구독중, N: 구독안함, null: 포스트 작성자와 조회한 사용자가 동일)
  blurFileUrl?: string | null
  isUpdateBefore?: boolean // 방금전 생성 여부(포스트 생성시 이미지 리사이즈 기준을 비동기로 처리하지 않기 때문)
}

/**
 * 포스트 상세 조회 페이로드
 */
export interface PostPayload {
  cmtyNttSn: number
}

/**
 * 포스트 목록
 */
export interface Posts {
  data: Post[]
  nextPageNo: number | null
}

/**
 * 포스트 정렬
 */
export type PostsOrderBy =
  | 'for-you' // 추천순(개인화)
  | 'newest' // 최신순
  | 'follow-newest' // 팔로잉

/**
 * 포스트 목록 조회 페이로드
 */
export interface PostsPayload {
  [Key: string]: any
  pageNum: number // 페이지 번호
  pageSize: number // 페이지당 조회 아이템 개수
  nextPageSize: number // 다음 페이지에서 조회할 아이템 개수 (조회 시점과 다음 조회 시점의 아이템 개수가 다를때)
  lastCmtyNttSn?: number // 마지막 게시물 일련번호(refresh: 키삭제, loadMore: 마제막 게시물 일련번호)
  lastSumSvcActScore?: number // 인기순 조회시 마지막 게시물의 인기 점수

  orderBy: PostsOrderBy // 정렬
  cmtyNttCtgrySn?: number // 카테고리 일련번호
  flwUserSn?: number // 특정 사용자가 등록한 포스트 조회시 필요한 사용자 일련번호
  searchNttSj?: string // 제목 검색 키워드
  searchHashtagNm?: string // 해시태그 검색 키워드
  randSeed?: string // 추천순 randSeed
  nttRecomendStdrDt?: string // 랭킹 기준 일시
}

/**
 * 포스트 목록 조회 타입
 */
export type PostsListType =
  | 'feed' // 피드 포스트 목록
  | 'default' // 일반 포스트 목록
  | 'hashtag' // 해시태그 검색 포스트 목록
  | 'search' // 키워드 검색 포스트 목록

/**
 * 해시태그 랭킹 포스트 목록 페이로드
 */
export interface HashtagPostsPayload {
  hashtagSn: number
}

/**
 * 해시태그 랭킹 포스트
 */
export interface HashtagPost {
  cmtyNttSn: number
  nttCn: string
  nttSj: string
  userNcnm: string
  thumbUrl: string | null
}

/**
 * 이미지 파일
 */
export interface ImgFile {
  contentType: string
  fileExtention: string
  fileName: string
  fileSize: number
  originFileNm: string
  s3Url: string
}

/**
 * 영상 파일
 */
export interface VideoFile {
  originFileNm: string
  s3M3u8Url: string
  s3Mp4Url: string
  s3ThumnailUrl: string
}

/**
 * 편집기에 포함되는 이미지 파일 리스트
 */
export interface EditorImage {
  deleteAt: BooleanYn // 삭제 여부
  fileUrl: string // 이미지 파일 URL
  tempAt: BooleanYn // 임시 파일 여부
}

/**
 * 파일 업로드 Response
 */
export interface ImgOrVideoFile {
  fileId: string
  images: ImgFile[]
  video: VideoFile
}

/**
 * 임시 게시물 상세 조회 페이로드
 */
export interface TempPostPayload {
  tmprNttSn: number // 임시 게시물 일련번호
}

/**
 * 임시 게시물 삭제 페이로드
 */
export interface TempPostDeletePayload {
  tmprNttSn: number // 임시 게시물 일련번호
}

/**
 * 임시 게시물 상세
 */
export interface TempPost {
  cardVideoJson: string // 게시물 json 정보
  cmtyNttCtgrySn: number // 커뮤니티 게시물 카테고리 일련번호
  hashTagList: string[] // 해시태그 목록
  nttCn: string // 임시 게시물 내용
  nttOthbcScopeCode: ScopeCodeType
  nttSj: string // 임시 게시물 제목
  nttTyCode: string // 커뮤니티 게시글 유형(NORM:일반형, CARD:카드형, CVOD:영상형)
  tmprNttRegistDt: string // 임시 게시물 등록 일시
  tmprNttSn: number // 임시 게시물 일련번호
  nttAiPrcuseAt: BooleanYn // 게시물 AI 활용 여부 Y/N
}

/**
 * 포스트 등록
 */
export interface CreatePostPayload {
  blurTempImgUrl?: string // 블러 임시 이미지 url(프리미엄 포스트일때 삽입)
  cmtyNttCtgrySn?: number // 카테고리 일련번호(프리미엄 멤버십 콘텐츠 일때 삭제)
  hashTagList?: string[]
  tempImgUrlList?: string[]
  nttOthbcScopeCode: ScopeCodeType
  nttSj: string
  nttCn: string
  nttTyCode: NttTypeCode
  imgOrVideoFile: FileImageContainer | FileVideoContainer | null
  prmbrshCntntsAt: BooleanYn // 프리미엄 멤버십 콘텐츠 여부
  tmprNttSn?: number // 임시 게시글 일련번호
  nttAiPrcuseAt: BooleanYn // 게시물 AI 활용 여부 Y/N
}

/**
 * 포스트 수정
 */
export interface EditPostPayload {
  blurTempImgUrl?: string // 블러 임시 이미지 url
  cmtyNttCtgrySn?: number // 카테고리 일련번호(프리미엄 멤버십 콘텐츠 일때 삭제)
  cmtyNttSn: number // 커뮤니티 게시글 일련번호
  fileUrlList: EditorImage[] // 파일 Url 목록
  hashTagList?: string[] // 해시태그 목록
  imgOrVideoFile: FileContainer | null // 카드, 비디오형 파일
  nttCn: string // 게시물 내용
  nttOthbcScopeCode: ScopeCodeType // 게시물 공개 범위 코드
  nttSj: string // 게시물 제목
  nttTyCode: NttTypeCode // 커뮤니티 게시글 유형
  prmbrshCntntsAt: BooleanYn // 프리미엄 멤버십 콘텐츠 여부 Y/N
  nttAiPrcuseAt: BooleanYn // 게시물 AI 활용 여부 Y/N
}

/**
 * 임시 포스트 등록
 */
export interface CreateTempPostPayload {
  cmtyNttCtgrySn?: number
  nttOthbcScopeCode: ScopeCodeType
  nttSj: string
  nttCn: string
  nttTyCode: NttTypeCode
  fileId?: string
  cardVideoJson?: string
  nttAiPrcuseAt: BooleanYn // 게시물 AI 활용 여부 Y/N
}

/**
 * 미리보기 포스트
 */
export interface PreviewPost {
  hashTagList: []
  tempImgUrlList: string[]
  nttSj: string
  nttCn: string
}

/**
 * TempPostsPayload
 * @description 임시포스트 리스트 페이로드
 * lastTmprNttSn: 마지막 임시저장게시물 일련번호
 * nextPageSize: 다음페이지 출력될 row 개수
 * pageSize: 출력될 row 개수
 */
export interface TempPostsPayload {
  pageNum: number
  pageSize: number
  nextPageSize: number
  nttTyCode?: NttTypeCode // 커뮤니티 게시글 유형(NORM:일반형, CARD:카드형, CVOD:영상형)
}

/**
 * 임시 저장상태 포스트
 */
export interface TempPostItem {
  tmprNttSn: number
  nttSj: string
  cmtyNttCtgrySn: number | null
  nttOthbcScopeCode: number | null
  tmprNttRegistDt: string
  hashTagList: string[] | null
}

/**
 * 댓글 & 댑글 액션 타입 (액션 및 상태 타입으로 사용)
 */
export type CommentAndReplyPrivateActionType =
  | 'hide' // 숨기기
  | 'report' // 신고
  | 'delete' // 삭제
  | 'edit' // 수정
  | 'signOff' // 탈퇴한 사용자의 댓글

/**
 * 댓글 작성자 상태
 */
export type CommenterStatus =
  | 'Z' // 가입시작
  | 'P' // 가입중
  | 'A' // 준회원
  | 'S' // 가입
  | 'L' // 계정잠금(로그인 불가)
  | 'T' // 탈퇴
  | 'D' // 휴면회원
  | 'B' // 블랙리스트회원
  | 'R' // 제제회원

/**
 * 댓글 & 답글
 */
export interface CommentAndReply {
  cmtyNttAnswerSn: number // 댓글/답글 일련번호
  mentnNickname: string | null // 멘션 사용자 닉네임
  mentnUserAt: BooleanYn // 멘션 여부
  mentnUserSn: number | null // 멘션 사용자 일련번호
  nttAnswerCn: string // 댓글 내용
  nttAnswerRegistDt: string // 댓글 등록 일시
  nttParntsAnswerSn: number | null // 부모 댓글 일련번호
  recommendCount: number // 댓글 좋아요 수
  recommendUserList: RecommendUser[] // 좋아요 한 사용자 목록
  replyreAt: BooleanYn // 답글 여부 Y N
  reportableAt: BooleanYn // 신고 가능 여부 Y N
  userNcnm: string // 작성자 닉네임
  userProfl: string | null // 작성자 프로필 url
  userSn: number // 작성자 일련번호
  rcFlag: BooleanCode // 좋아요 여부
  dvFlag: BooleanCode // 싫어요 여부
  deleteAt: BooleanYn // 삭제 여부
  bkFlag: BooleanCode // 숨김 여부(개인화 정보)
  sanctnsAt: BooleanYn // 신고 여부
  userSttusCode: CommenterStatus // 댓글 작성자 상태

  // 클라이언트만 사용하는 모델
  cmtyNttSn?: number // 포스트 게시물 일련번호
  hasReplies?: boolean
  lastReply?: boolean
  isUpdateBefore?: boolean // 수정하거나 생성시 이미지 리사이징 대기 이미지 노출 을 위한 모델
}

/**
 * 댓글 & 답글 리스트 정렬
 */
export type CommentsAndRepliesOrderBy =
  | 'oldest' // 오래된 순
  | 'newest' // 최신 순
  | 'hot' // 인기순

/**
 * 댓글 & 답글 리스트 조회 페이로드
 */
export interface CommentsAndRepliesPayload {
  cmtyNttSn: number // 포스트 게시물 일련번호
  nextPageSize: number // 다음 페이지에서 조회할 아이템 개수 (조회 시점과 다음 조회 시점의 아이템 개수가 다를때)
  orderBy: CommentsAndRepliesOrderBy // 정렬
  pageNum: number // 페이지 번호
  pageSize: number // 페이지당 조회 아이템 개수
}

/**
 * 댓글 & 답글 작성 페이로드
 */
export interface CommentAddPayload {
  cmtyNttSn: number // 커뮤니티 게시물 일련번호
  mentnUserSn?: number // 언급 사용자 일련번호
  nttAnswerCn: string // 댓글 내용
  nttParntsAnswerSn?: number // 상위 댓글 일련번호
  tempImgUrlList?: string[] // 임시 이미지 url 목록 : cdn 포함 full url
}

/**
 * 댓글 & 답글 수정하기 페이로드
 */
export interface CommentEditPayload {
  cmtyNttAnswerSn: number // 댓글 & 답글 일련번호
  fileUrlList: EditorImage[] // 파일 Url 목록
  nttAnswerCn: string // 댓글 내용
}

/**
 * 댓글 & 답글 삭제 페이로드
 */
export interface CommentRemovePayload {
  cmtyNttAnswerSn: number // 커뮤니티 게시물 댓글 일련번호
}

/**
 * 댓글 & 답글 삭제시 댓글 카운트 업데이트 페이로드
 */
export interface CommentRemoveUpdatePostPayload {
  cmtyNttSn: number // 커뮤니티 게시물 일련번호
  answerCount: number // 업데이트 할 댓글 수
}

/**
 * 댓글 & 답글 좋아요, 좋아요 취소 페이로드
 */
export interface CommentRecommendPayload {
  cmtyNttAnswerSn: number // 댓글 & 답글 일련번호
}

/**
 * 댓글 & 답글 숨기기 페이로드
 */
export interface CommentHidePayload {
  cmtyNttAnswerSn: number // 댓글 & 답글 일련번호
  cmtyNttSn: number // 포스트 일련번호
}

/**
 * 댓글 & 답글 신고하기 페이로드
 */
export interface CommentReportPayload {
  cmtyNttAnswerSn: number // 댓글 & 답글 일련번호
  rptCn: string // 신고 내용
  sanctnsRptClCode: string // 제재 신고 분류 코드
}

/**
 * 해시태그 컨트롤(전역 해시태그 관리)
 */
export interface HashtagControl {
  hashtag: Hashtag // 사용자가 선택한 해시태그
}

/**
 * 크리에이터 목록 조회 페이로드
 */
export interface CreatorsPayload {
  lastOrderScore?: number // 마지막 정렬 기준 점수
  lastUserSn?: number // 마지막 사용자 일련번호
  nextPageSize: number // 다음페이지 요청 개수
  pageNum: number // 요청 페이지 번호
  pageSize: number // 한 페이지당 row 수
  topUserSn?: number // 상위노출 사용자 일련번호
}

/**
 * 크리에이터
 */
export interface Creator {
  userNcnm: string // 크리에이터 닉네임
  userSn: number // 크리에이터 사용자 일련번호
  followFlag: BooleanCode // 팔로우 구분
  followerCount: number // 팔로워 수
  userProfl: string // 크리에이터 프로필 url
  creatorLink: string // 크리에이터 별칭
  crtrSpclGradCode: CreatorGrade // 크리에이터 등급
  crtrOrgnztTyCode: CreatorOrganizationCode // 크리에이터 조직 구분 코드
  cdnPrefix: string | null // ????
  orderScore: number // 정렬 기준 점수
  kycAt: BooleanYn // KYC 인증여부
}

/**
 * 토큰
 */
export interface AuthPayload {
  grantType: string
  accessToken: string
}

/**
 * 프리미엄 포스트 목록 정렬
 */
export type PremiumPostsOrderBy =
  | 'newest' // 최신순
  | 'oldest' // 과거순
  | 'hot' // 인기순

/**
 * 프리미엄 포스트 목록 페이로드
 */
export interface PremiumPostsPayload {
  [Key: string]: any
  nextPageSize: number // 다음 페이지 요청 개수
  orderBy: PremiumPostsOrderBy // 정렬
  pageNum: number // 페이지 번호
  pageSize: number // 한 페이지당 row 수
  creatorUserSn?: number // 필터 크리에이터 사용자 일련번호
  lastCmtyNttSn?: number // 마지막 게시물 일련번호
  lastSumSvcActScore?: number // 마지막 인기순 기준 점수 (인기순 정렬 필수)
  srchPostType?: string // NttTypeCode | '' 포스트 타입 코드 ('NORM':일반형, 'CARD':카드형, 'CVOD':영상형, '': 모두)
}

/**
 * 게시글 후원 페이로드
 */
export interface PostingDonationPayload {
  cmtyNttSn: number // 게시글 일련번호
  donation: number // 후원금
  privateKey: string // 개인키
}

/**
 * 게시글 작성 가능 여부 조회 페이로드(카테고리 기준)
 */
export interface PostCreateAvailablePayload {
  cmtyNttCtgrySn: number // 커뮤니티 게시물 카테고리 일련번호
}

/**
 * 게시글 작성 가능 여부(카테고리 기준)
 */
export interface PostCreateAvailable {
  publishableAt: BooleanYn // 게시물 발행 가능 여부 Y/N
  publishableCount: number // 게시물 발행 가능 개수
  rwardStdrEndDt: string // 게시물 발행 기준 종료 일시
  svcActCrtrCtgryPostWklyMaxCo: number // 크리에이터 컨텐츠 주간 최대 포스팅 개수
}

/**
 * PostState
 * @description 포스트 스토어 스테이트 타입
 */
export interface PostState {
  categories: CategoryListItem[]
  baseHashtags: Hashtag[]
  feedPosts: BaseInfinityScrollList<PostsItem[], PostsPayload>
  posts: BaseInfinityScrollList<PostsItem[], PostsPayload>
  postsByHashtag: BaseInfinityScrollList<PostsItem[], PostsPayload>
  postsBySearch: BaseInfinityScrollList<PostsItem[], PostsPayload>
  postsByHashtagRanking: HashtagPost[]
  userPosts: Posts | null
  post: Post | null
  commentsAndReplies: BaseInfinityScrollList<
    CommentAndReply[],
    CommentsAndRepliesPayload
  >
  previewPost: PreviewPost | null
  tempList: BaseInfinityScrollList<TempPostItem[], TempPostsPayload>
  hashtagControl: HashtagControl
  creators: BaseInfinityScrollList<Creator[], CreatorsPayload>
  premiumPosts: BaseInfinityScrollList<PostsItem[], PremiumPostsPayload>
}
