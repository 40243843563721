/**
 * 공통 에러
 */
export const commonError = {
  401: {
    title: 'ข้อผิดพลาดในการตรวจสอบสิทธิ์',
    message: 'การเชื่อมต่อบริการถูกตัดขาดโปรดลองอีกครั้งในภายหลัง',
  },
  403: {
    title: 'สิทธิ์การเข้าถึงถูกจำกัด',
    message: 'คุณไม่มีสิทธิ์เข้าถึงหน้านี้ กรุณาติดต่อผู้ดูแลระบบ',
  },
  404: {
    title: 'ข้อผิดพลาดในการเข้าถึง',
    message: 'ไม่พบหน้าที่ร้องขอ กรุณาตรวจสอบหน้าที่และลองอีกครั้ง',
  },
  500: {
    title: 'เซิร์ฟเวอร์กำลังตรวจสอบ',
    message: 'เซิร์ฟเวอร์กำลังตรวจสอบอยู่ชั่วคราว กรุณาลองอีกครั้งในภายหลัง',
  },
  502: {
    title: 'เซิร์ฟเวอร์กำลังตรวจสอบ',
    message: 'เซิร์ฟเวอร์กำลังตรวจสอบอยู่ชั่วคราว กรุณาลองอีกครั้งในภายหลัง',
  },
  505: {
    title: 'เซิร์ฟเวอร์กำลังตรวจสอบ',
    message: 'เซิร์ฟเวอร์กำลังตรวจสอบอยู่ชั่วคราว กรุณาลองอีกครั้งในภายหลัง',
  },
  4002: {
    title: 'สมาชิกนี้ยังไม่ได้ลงทะเบียน',
    message: 'สมาชิกนี้ได้ถอนการลงทะเบียนหรือไม่มีอยู่ กรุณาลองค้นหาสมาชิกอื่น',
  },
  1998: {
    title: 'กำลังอัปเดตรายการฟีด',
    message: 'กำลังอัปเดตฟีดรายการตามลำดับความนิยมใหม่',
  },
  default: {
    title: 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ',
    message: 'กรุณาลองอีกครั้งในภายหลัง',
  },
  network: {
    title: 'ข้อผิดพลาดเครือข่าย',
    message: 'บริการขณะนี้ไม่สามารถใช้งานได้ กรุณาลองอีกครั้งในภายหลัง',
  },
}
