/**
 * 레퍼럴 회원가입
 */
export const referral = {
  signUp: {
    dialog: {
      referralNotAble: {
        title: 'ไม่สามารถสมัครสมาชิกด้วยการแนะนำ',
        description:
          'คุณได้สมัครสมาชิกแล้ว กรุณาออกจากระบบแล้วดำเนินการสมัครสมาชิกอีกครั้ง',
        action: {
          positive: 'ตกลง',
        },
      },
      referralAble: {
        title: 'สมัครสมาชิกด้วยการแนะนำ',
        description: 'กรุณาดำเนินการสมัครสมาชิกด้วยการแนะนำให้เสร็จสิ้น',
        mobileGuide:
          'หากคุณเข้าถึงจาก iPhone กรุณาปิดการบล็อกป๊อปอัพในการตั้งค่าของ Safari',
      },
    },
    error: {
      title: 'ไม่พบผู้ใช้',
      message:
        'ไม่สามารถลงทะเบียนเป็นผู้แนะนำได้ กรุณาตรวจสอบผู้ใช้และลองอีกครั้ง',
    },
  },
  clipboard: {
    tooltip: 'คัดลอกลิงก์ผู้แนะนำ',
    toastMessage: {
      success: 'ลิงก์ผู้แนะนำได้ถูกคัดลอกไปยังคลิปบอร์ดแล้ว',
    },
  },
  edit: {
    label: 'ลิงก์ผู้แนะนำ',
    tooltip: 'แก้ไข',
    toastMessage: {
      success: 'ลิงก์ผู้แนะนำได้ถูกเปลี่ยนแปลงแล้ว',
      failDefault: 'เกิดข้อผิดพลาดขณะดำเนินการ กรุณาลองอีกครั้งในภายหลัง',
      failEqual: 'ลิงก์ผู้แนะนำเดิมและชื่อเหมือนกัน กรุณากรอกลิงก์ผู้แนะนำอื่น',
      failUpdate: 'ลิงก์ผู้แนะนำได้ถูกเปลี่ยนแปลงแล้ว',
      failDuplicate: 'ลิงก์ผู้แนะนำนี้มีการใช้งานโดยผู้ใช้อื่นแล้ว',
      failLength: 'กรุณากรอกอย่างน้อย {min} ตัวอักษร',
      failCharacter: 'กรุณากรอกเฉพาะอักษรภาษาอังกฤษและตัวเลข',
      unavailableRecommendLink:
        'มีอักขระที่ไม่สามารถใช้ได้ กรุณาใช้ลิงก์ผู้แนะนำอื่น',
      checkDuplicateBeforeSave: 'กรุณาตรวจสอบการซ้ำก่อนบันทึก',
    },
  },
}
