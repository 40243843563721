<template>
  <div :class="['creator-grade-badge', 'single-line-tooltip', `size-${size}`]">
    <Tippy
      :append-to="'parent'"
      :content="badge.tooltip"
      :interactive="true"
      :theme="'simple'"
      :placement="tooltipPosition"
      :tag="'button'"
      :role="'button'"
    >
      <div class="image-container">
        <img class="creator-badge-icon" :src="badge.url" :alt="badge.tooltip" />
      </div>
    </Tippy>
  </div>
</template>

<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type {
  CreatorBadge,
  NovaCreatorGradeBadgeProps,
} from './NovaCreatorGradeBadge.types'
import DefaultBadge from 'assets/images/creator/creator-badge-default.svg?url'
import SuperBadge from 'assets/images/creator/creator-badge-super.svg?url'
import MegaBadge from 'assets/images/creator/creator-badge-mega.svg?url'

const props = withDefaults(defineProps<NovaCreatorGradeBadgeProps>(), {
  size: 'md',
  tooltipPosition: 'bottom',
})

const { t } = useI18n()
const badge = computed<CreatorBadge>(() => {
  switch (props.creatorGrade) {
    case '0000':
    case null:
      return { url: DefaultBadge, tooltip: t('creatorGrade.default') }
    case '0020':
      return { url: DefaultBadge, tooltip: t('creatorGrade.default') }
    case '0030':
      return { url: SuperBadge, tooltip: t('creatorGrade.super') }
    case '0040':
      return { url: DefaultBadge, tooltip: t('creatorGrade.default') }
    case '0050':
      return { url: MegaBadge, tooltip: t('creatorGrade.mega') }
    case '0060':
      return { url: DefaultBadge, tooltip: t('creatorGrade.default') }
    default:
      throw new Error('creator grade is not match')
  }
})
</script>

<style lang="scss" scoped>
.creator-grade-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.size-md {
    .image-container {
      width: 26px;
      height: 26px;
    }
  }

  &.size-lg {
    .image-container {
      width: 48px;
      height: 48px;
    }
  }

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .creator-badge-icon {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &.single-line-tooltip {
    :deep(.tippy-content) {
      margin-top: -12px;
      white-space: nowrap;
    }
  }
  .single-line-tooltip .tippy-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
}
</style>
