import { bridgeToApp, type FullscreenStatusPayload } from '@bridge'

type ListenerParamVideo = HTMLVideoElement | undefined | null

const useVideoFullScreen = () => {
  const appStore = useAppStore()
  const { appType } = storeToRefs(appStore)
  const videoSize = ref({
    videoWidth: 0,
    videoHeight: 0,
  })
  const isPortrait = computed<FullscreenStatusPayload['isPortrait']>(
    () => videoSize.value.videoWidth < videoSize.value.videoHeight
  )

  // fullscreen 시작
  const handleOnBeginFullscreen = () => {
    const payload = {
      isFullScreen: true,
      isPortrait: isPortrait.value,
      videoSize: videoSize.value,
    }
    bridgeToApp.fullscreenStatus(payload)
    console.log('[fullscreen event hook] begin fullscreen', payload)
  }

  // fullscreen 종료
  const handleOnEndFullscreen = () => {
    const payload = {
      isFullScreen: false,
      isPortrait: isPortrait.value,
      videoSize: videoSize.value,
    }
    bridgeToApp.fullscreenStatus(payload)
    console.log('[fullscreen event hook] end fullscreen', payload)
  }

  // fullscreen 시작 & 종료
  const fullscreenChange = () => {
    document.fullscreenElement
      ? handleOnBeginFullscreen()
      : handleOnEndFullscreen()
  }

  // 메타데이터 업데이트
  const updateIsPortrait = (video: HTMLVideoElement) => {
    videoSize.value.videoWidth = video.videoWidth
    videoSize.value.videoHeight = video.videoHeight
  }

  // 이벤트 리스너 등록
  // onMounted 시점에서 호출할것
  const addVideoFullscreenEventListener = (video: ListenerParamVideo) => {
    if (typeof video === 'undefined' || !video) return

    // fullscreen 이벤트
    if (appType.value === 'IOS') {
      video.addEventListener('webkitbeginfullscreen', handleOnBeginFullscreen)
      video.addEventListener('webkitendfullscreen', handleOnEndFullscreen)
    } else if (appType.value === 'AOS') {
      video.addEventListener('fullscreenchange', fullscreenChange)
    }

    // loadedmetadata 이벤트
    video.addEventListener('loadedmetadata', () => updateIsPortrait(video))

    // 비디오 로드
    video.load()
  }

  // 이벤트 리스너 삭제
  // onBeforeUnmount 시점 혹은 onUnMount 시점에서 호출
  const removeVideoFullscreenEventListener = (video: ListenerParamVideo) => {
    if (typeof video === 'undefined' || !video) return

    // fullscreen 이벤트
    video.removeEventListener('webkitbeginfullscreen', handleOnBeginFullscreen)
    video.removeEventListener('webkitendfullscreen', handleOnEndFullscreen)
    video.removeEventListener('fullscreenchange', fullscreenChange)

    // loadedmetadata 이벤트
    video.removeEventListener('loadedmetadata', () => updateIsPortrait(video))
  }

  return {
    addVideoFullscreenEventListener,
    removeVideoFullscreenEventListener,
  }
}

export default useVideoFullScreen
