import { commonError } from './commonError'

/**
 * 사용자 계정 정보
 */
export const accountPanel = {
  settings: {
    phoneNumber: {
      title: 'หมายเลขโทรศัพท์มือถือ',
      guide: 'กรุณารวมขีด (-) ขณะกรอกข้อมูล',
      placeholder: '010-0000-0000',
      action: 'บันทึก',
      valid: {
        empty: 'กรุณาใส่หมายเลขโทรศัพท์มือถือของคุณ',
      },
      messages: {
        error: {
          2006: 'ข้อมูลที่จำเป็นหายไป',
          4000: 'การสื่อสารกับเซิร์ฟเวอร์การพิสูจน์ตัวตนที่รวมอยู่ถูกตัดการเชื่อมต่อ',
          4002: commonError['4002'].message,
          4003: 'ข้อมูลโทเคนและหมายเลขประจำตัวผู้ใช้ทั่วไปไม่ตรงกัน',
          unknown: 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ',
        },
        success: 'หมายเลขโทรศัพท์ของผู้ใช้ได้ถูกเปลี่ยนเป็น {phoneNumber}',
      },
    },
    bankAccount: {
      title: 'ธนาคารการชำระเงินบริจาค {subscribeGrade}',
      forms: {
        bank: {
          label: 'ธนาคาร',
          guide: 'ต้องเป็นบัญชีที่เป็นชื่อของคุณเอง',
          placeholder: 'การเลือกธนาคาร',
          valid: 'กรุณาเลือกธนาคาร',
        },
        account: {
          label: 'หมายเลขบัญชี',
          guide: 'กรุณารวมขีด (-) ขณะกรอกข้อมูล',
          placeholder: '000-0000-0000-00',
          valid: 'กรุณาใส่หมายเลขบัญชีของคุณ',
        },
        owner: {
          label: 'ชื่อผู้ถือบัญชี',
          guide: '',
          placeholder: 'ชื่อ',
          valid: 'กรุณาใส่ชื่อผู้ถือบัญชี',
        },
      },
      messages: {
        empty: {
          fetchBanks:
            'ไม่พบธนาคารสำหรับ {keyword} ในการค้นหา กรุณาใส่ชื่อธนาคารอื่น',
        },
        error: {
          fetchBanks: 'ไม่สามารถดึงรายการธนาคารได้',
          2006: 'ข้อมูลที่จำเป็นขาดหายไป',
          4010: 'ข้อมูลโทเคนและหมายเลขประจำตัวผู้ใช้ทั่วไปไม่ตรงกัน',
          unknown: 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ',
        },
        success:
          'ข้อมูลธนาคารสำหรับการชำระเงินบริจาค {subscribeGrade} ของคุณได้ถูกลงทะเบียนเรียบร้อยแล้ว',
      },
    },
  },
}
