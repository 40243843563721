<script setup lang="ts">
import type {
  NovaBoxPostContentsSnapshotVideoEmits,
  NovaBoxPostContentsSnapshotVideoProps,
} from './NovaBoxPostContentsSnapshotVideo.types'
import { PostContentsType } from '@store'
import VideoReadyImage from '@assets/images/image-uploading.png'

const emit = defineEmits<NovaBoxPostContentsSnapshotVideoEmits>()
const props = withDefaults(
  defineProps<NovaBoxPostContentsSnapshotVideoProps>(),
  {
    createMode: false,
    viewType: PostContentsType.DETAIL,
    editMode: false,
    disabled: false,
  }
)
const { checkPermission, requestPermission } = useAppPermission()
const { addVideoFullscreenEventListener, removeVideoFullscreenEventListener } =
  useVideoFullScreen()
const appStore = useAppStore()
const { isApp } = storeToRefs(appStore)
const { createMode, viewType, videos } = toRefs(props)
const postContentsByVideoRef = ref<Element>()
const videoRef = ref<HTMLVideoElement | null>(null)
// const videoProgressBar = ref<HTMLVideoElement>()
const checkThumbInterval = ref<ReturnType<typeof setInterval>>()
const showVideoControl = ref(true)
const showVideoControlInterval = ref<ReturnType<typeof setInterval>>()
const snapshotMode = ref(false)
const state = reactive({
  progressPercent: 0,
  currentTime: 0,
  thumbnail: createMode.value
    ? VideoReadyImage
    : videos.value[0].s3ThumnailUrl.fileUrl,
  source: videos.value[0].s3Mp4Url.fileUrl,
  loaded: ref(createMode.value),
})
watch(
  () => videos.value[0].s3Mp4Url.fileUrl,
  async () => {
    state.thumbnail = createMode.value
      ? VideoReadyImage
      : videos.value[0].s3ThumnailUrl.fileUrl
    state.progressPercent = 0
    await nextTick()
    setTimeout(() => {
      reqIntervalVideoThumbnail()
      videoRef.value?.load()
    }, 100)
  }
)

const reqIntervalVideoThumbnail = () => {
  // 2. 비디오 썸네일이 없을경우 가져오지 않기
  if (!videos.value[0].s3ThumnailUrl) return

  checkThumbInterval.value = setInterval(async () => {
    await checkThumbnailImage()
  }, 1000)

  const checkThumbnailImage = async () => {
    try {
      const thumbResult = await fetch(videos.value[0].s3ThumnailUrl.fileUrl)
      const videoResult = await fetch(videos.value[0].s3Mp4Url.fileUrl)
      if (thumbResult.status === 200 && videoResult.status === 200) {
        state.thumbnail = videos.value[0].s3ThumnailUrl.fileUrl
        state.source = videos.value[0].s3Mp4Url.fileUrl
        state.loaded = false

        if (createMode.value) {
          await nextTick()
          emit('playBtnActive')
        }
        clearInterval(checkThumbInterval.value)
      }
    } catch {}
  }
}

const swapVideo = async () => {
  // 앱 일때 스토리지 권한 체크
  if (isApp.value) {
    const { data: permission } = await requestPermission('storage')

    if (permission.status === 'off') {
      const accessAble = await checkPermission({
        permission: 'storage',
        permissionType: 'post',
      })
      if (!accessAble) return
    }
  }

  videoRef.value?.pause()
  state.loaded = false
  const fileVideoContainer = await uploadFileVideo(true)
  const newVideos = [...videos.value]

  newVideos.forEach((item) => {
    item.deleteAt = 'Y'
  })

  emit('change:video', [fileVideoContainer.video, ...newVideos])
  state.loaded = true
}

const disablePiP = () => {
  const videoElement = videoRef.value
  if (videoElement) {
    videoElement.disablePictureInPicture = true
  }
}

onMounted(async () => {
  disablePiP()

  // 포스트 등록 모드일때만 비디오 썸네일 가져오기
  await nextTick()
  if (createMode.value) {
    reqIntervalVideoThumbnail()
  }

  addVideoFullscreenEventListener(videoRef.value)
})

onBeforeUnmount(() => {
  removeVideoFullscreenEventListener(videoRef.value)
})

onUnmounted(() => {
  clearInterval(checkThumbInterval.value)
  clearInterval(showVideoControlInterval.value)
})
</script>

<template>
  <div
    ref="postContentsByVideoRef"
    :class="[
      'post-contents-by-video',
      { 'no-use-control': viewType === PostContentsType.LIST },
      {
        'show-video-control':
          viewType === PostContentsType.LIST ? false : showVideoControl,
      },
      { disabled },
    ]"
  >
    <!-- @click="handelVideoLayout" -->
    <video
      v-if="!state.loaded && !snapshotMode"
      ref="videoRef"
      :poster="state.thumbnail"
      :preload="viewType === PostContentsType.DETAIL ? 'auto' : 'none'"
      :fastSeek="state.currentTime"
      playsinline
      :controls="viewType !== PostContentsType.LIST"
      controlsList="nodownload"
    >
      <source :src="videos[0].s3Mp4Url.fileUrl" type="video/mp4" />
      <progress v-if="!snapshotMode" />
    </video>

    <div v-if="!snapshotMode && editMode" class="btn-card-controls">
      <button :disabled="!editMode" class="btn-card-control" @click="swapVideo">
        <NovaIcon
          :icon="{ type: 'outline', name: 'swap-horizon' }"
          :size="16"
        />
      </button>
    </div>

    <img v-if="snapshotMode" class="snapshot" :src="state.thumbnail" alt="" />

    <NovaImageContainer
      v-if="state.loaded && viewType !== 'list' && !snapshotMode"
      :ratio="'16:9'"
      :image-url="state.thumbnail"
      class="empty-image"
    />

    <NovaLoadingIndicator
      v-if="state.loaded && viewType !== 'list' && !snapshotMode"
      class="loading-indicator"
      :bg-bright="'light'"
      :size="100"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@styles/base/post-contents-snapshot';

// 스냅샷 기능으로 인해 전역 인스턴스화: post-contents-snapshot > mixin 스타일 수정할것
// .post-contents-by-video 클래스명 바꾸지 말것
@include post-contents-by-video;
</style>
