export const home = {
  creator: 'สำรวจ Creator ที่คุณชื่นชอบ!',
  myCreator: 'Creator ของฉัน',
  seeMore: 'เพิ่มเติม',
  apply: 'สมัคร',
  details: 'รายละเอียด',
  novaPick: 'P!CK ของ I LIKE LM',
  brandProject: 'NGO·แบรนด์·โครงการ',
  topBannerError: 'ไม่สามารถแสดงแบนเนอร์ในขณะนี้ กรุณาลองอีกครั้งในภายหลัง',
  pickError:
    "ไม่สามารถแสดง 'P!CK ของ I LIKE LM' ในขณะนี้ กรุณาลองอีกครั้งในภายหลัง",
  creatorCategoriesError:
    'ไม่สามารถแสดงหมวดหมู่ในขณะนี้ กรุณาลองอีกครั้งในภายหลัง',
  creatorCategoriesEmpty: 'ไม่มีหมวดหมู่',
  creatorError: 'ไม่สามารถแสดง Creator ในขณะนี้ กรุณาลองอีกครั้งในภายหลัง',
}
