/**
 * 크리에이터 등급
 */
export const creatorGrade = {
  default: 'ผู้สร้าง',
  bronze: 'ทองแดง',
  silver: 'เงิน',
  gold: 'ทอง',
  super: 'ซูเปอร์ครีเอเตอร์',
  mega: 'เมก้าครีเอเตอร์',
  business: 'แบรนด์·โปรเจกต์',
}
