import type { ShareType } from '@store'

/**
 * 공유하기
 */
export const share = {
  label: 'แชร์',
  actions: {
    facebook: 'แชร์ไปยัง Facebook',
    twitter: 'แชร์ไปยัง Twitter',
    kakao: 'แชร์ไปยัง KakaoTalk',
    clipboard: 'คัดลอกลิงก์',
  } as { [Property in ShareType]: string },
}
