
// @ts-nocheck


export const localeCodes =  [
  "ko",
  "en",
  "th",
  "es"
]

export const localeLoaders = {
  "ko": [],
  "en": [],
  "th": [],
  "es": []
}

export const vueI18nConfigs = [
  () => import("../configs/i18n.ts?hash=51113db0&config=1" /* webpackChunkName: "__configs_i18n_ts_51113db0" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./configs/i18n.ts",
  "locales": [
    {
      "code": "ko",
      "name": "korean",
      "default": true,
      "language": "ko-KR"
    },
    {
      "code": "en",
      "name": "english",
      "default": false,
      "language": "en-US"
    },
    {
      "code": "th",
      "name": "thai",
      "default": false,
      "language": "th_TH"
    },
    {
      "code": "es",
      "name": "spanish",
      "default": false,
      "language": "es-ES"
    }
  ],
  "defaultLocale": "ko",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "ko",
    "name": "korean",
    "default": true,
    "language": "ko-KR",
    "files": []
  },
  {
    "code": "en",
    "name": "english",
    "default": false,
    "language": "en-US",
    "files": []
  },
  {
    "code": "th",
    "name": "thai",
    "default": false,
    "language": "th_TH",
    "files": []
  },
  {
    "code": "es",
    "name": "spanish",
    "default": false,
    "language": "es-ES",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
