/**
 * 크리에이터 등급
 */
export const w3cf = {
  topBanner: {
    title: 'Web3 <br />Creator Festival',
    date: 'ระยะเวลาประเมิน 8/9 ~ 8/31',
  },
  allView: 'ทั้งหมด',
  publicPart: 'ภาคปกติ',
  studentPart: 'ภาคนักเรียน',
  notice: 'ประกาศ',
  search: {
    title: '* ลำดับของผลงานที่ได้รับการเสนอชื่อจะถูกเปิดเผยแบบสุ่ม',
    placeholder: 'ค้นหาผลงานที่ได้รับการเสนอชื่อสำหรับเทศกาลผู้สร้าง Web3',
  },
  displayCount: 'ยอดวิว',
  w3cfError:
    'ไม่สามารถดึงข้อมูลรายการผลงานที่ได้รับการเสนอชื่อได้ กรุณาลองใหม่อีกครั้งในภายหลัง',
  w3cfEmpty: 'ไม่มีรายการผู้ได้รับการเสนอชื่อ',
  w3cfLike: {
    fail: 'ไม่สามารถกดถูกใจได้ กรุณาลองใหม่อีกครั้งในภายหลัง',
    success: 'ถูกใจแล้ว',
  },
  w3cfUnlike: {
    fail: 'ไม่สามารถยกเลิกการกดถูกใจได้ กรุณาลองใหม่อีกครั้งในภายหลัง',
    success: 'การกดถูกใจถูกยกเลิกแล้ว',
  },
  w3cfLikeOrUnlikeLock: 'ระยะเวลาโหวตได้สิ้นสุดลงแล้ว',
  w3cfDetailError: 'ไม่สามารถตรวจสอบรายการผู้สมัครได้ โปรดลองอีกครั้งในภายหลัง',
  w3cfDetainNavigationBack: 'ดูรายการ',
  abusingNotify:
    '[ประกาศ] เพื่อการลงคะแนนที่เป็นธรรม เรากำลังดำเนินการตรวจสอบการทุจริต กรุณาดูรายละเอียดเพิ่มเติมในประกาศ',
  contestWinnersNotify:
    '[ประกาศ] การประกาศผู้ชนะการแข่งขันเทศกาลผู้สร้าง Web3 2023',
}
