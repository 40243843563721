/**
 * 크리에이터 방명록
 */
export const creatorGuestBook = {
  regCreatorGuestBookModal: {
    title: 'เขียน',
    textareaPlaceholder: 'ใส่เนื้อหา',
    actions: {
      negative: 'ยกเลิก',
      positive: 'เขียน',
    },
  },
  request: {
    actions: {
      delete: 'ลบ',
    },
    list: {
      empty: 'ไม่มีโพสต์ที่นี่',
      error: 'ไม่สามารถโหลดได้ในขณะนี้ กรุณาลองอีกครั้งในภายหลัง',
    },
    delete: {
      error: 'คุณไม่สามารถลบโพสต์ในขณะนี้ กรุณาลองอีกครั้งในภายหลัง',
      success: 'โพสต์ถูกลบแล้ว',
    },
    reg: {
      stopReg: 'หากคุณปิดหน้าต่างก่อนที่จะบันทึก โพสต์จะถูกลบ',
      validLength: 'ใส่เนื้อหา',
      error: 'โพสต์ยังไม่ได้อัปโหลด กรุณาลองอีกครั้งในภายหลัง',
      success: 'โพสต์อัปโหลดสำเร็จแล้ว',
    },
  },
}
