import type { SponsorSttusCode, PgPaymeantCode } from '@store'

/**
 * 후원자 목록, 후원 목록
 */
export const sponsors = {
  topButton: {
    creatorTitle: 'ผู้สนับสนุนของผู้สร้าง',
    userTitle: 'การสนับสนุน {subscribeGrade}',
  },
  search: {
    creatorTitle: 'รายการผู้สนับสนุน {premiumBrand}',
    userTitle: 'ประวัติการสนับสนุน {premiumBrand}',
    placeholder: 'ค้นหาตามรหัสเฉพาะและชื่อเล่น',
    orderBy: {
      newest: 'ทั้งหมด',
      odrQty: 'จำนวนการสนับสนุน',
    },
    filterType: {
      all: 'ทั้งหมด',
      Day30: '30 วันที่ผ่านมา',
      error: 'กรุณาเลือกวันที่',
    },
  },
  creatorTable: {
    headerSn: 'รหัสเฉพาะ',
    headerNcnm: 'ชื่อเล่น',
    headerOdrSetleDt: 'วันที่สนับสนุนครั้งแรก',
    headerPymntDt: 'วันที่สนับสนุนล่าสุด',
    headerOdr: 'จำนวนการสนัับสนุน',
  },
  userTable: {
    headerSn: 'รหัสเฉพาะ',
    headerNcnm: 'ชื่อเล่น',
    headerOdrSetleDt: 'วันที่สนับสนุน',
    headerOdrDt: 'ระยะเวลาในการรับสิทธิประโยชน์จากการสนับสนุน',
    headerCode: 'สถานะการสนับสนุน',
    headerDetail: {
      title: 'ข้อมูลรายละเอียด',
      btn: 'ดู',
    },
  },
  table: {
    error: {
      message: 'เป็นสมาชิกที่ไม่ได้ลงทะเบียน',
    },
  },
  sponsorsDetail: {
    title: 'ข้อมูลรายละเอียดการสนับสนุน {premiumBrand}',
    userBenefit: 'สิทธิประโยชน์ของ {creatorNickname} จาก {premiumBrand}',
    benefit: 'สิทธิประโยชน์',
    sponsorsInfo: {
      title: 'ข้อมูลการสนัับสนุน',
      sponsorStatus: 'สถานะการสนับสนุน',
      userPrmbrshPgmSbscrptnSn: 'รหัสเฉพาะ',
      sbscrptnOdrBeginDt: 'วันที่สนับสนุน',
      sbscrptnOdrEndDt: 'ระยะเวลาในการรับสิทธิประโยชน์จากการสนับสนุน',
    },
    payment: {
      title: 'ข้อมูลการชำระเงิน',
      pgPayment: 'วิธีการชำระเงิน',
      subscribeAmount: 'จำนวนเงินที่ชำระ',
      sponsorStatus: 'สถานะการชำระเงิน',
    },
  },
  sponsorStatus: {
    name: {
      RSCP: 'กำลังสนับสนุน',
      SBSC: 'กำลังสนับสนุน',
      SBED: 'การสนับสนุนเสร็จสมบูรณ์',
      RQER: 'ข้อผิดพลาดในการดำเนินการ',
      TRMN: 'การสนับสนุนเสร็จสมบูรณ์',
    } as { [Property in SponsorSttusCode]: string },
  },
  pgPayment: {
    name: {
      21001: 'การฝากเงินโดยไม่ใช้บัญชีธนาคาร',
      10009: 'การชำระเงินด้วยบัตร',
    } as { [Property in PgPaymeantCode]: string },
  },

  creatorsList: {
    empty: 'ไม่มีผู้สนับสนุน',
    error: 'ไม่สามารถดึงรายชื่อผู้สนับสนุนได้ กรุณาลองอีกครั้งในภายหลัง',
  },
  userList: {
    empty: 'ไม่มีการสนับสนุนสำหรับ {subscribeGrade}',
    error:
      'ไม่สามารถดึงรายการการสนับสนุน {subscribeGrade} ได้ กรุณาลองอีกครั้งในภายหลัง',
  },
  detail: {
    empty: 'ไม่มีข้อมูลการสนับสนุน',
    error: 'ไม่สามารถค้นหาข้อมูลการสนับสนุนได้ กรุณาลองอีกครั้งในภายหลัง',
  },
  paymentStatus: {
    name: {
      RSCP: 'การชำระเงินเสร็จสมบูรณ์',
      SBSC: 'การชำระเงินเสร็จสมบูรณ์',
      SBED: 'การชำระเงินเสร็จสมบูรณ์',
      RQER: 'การชำระเงินล้มเหลว',
      TRMN: 'การชำระเงินเสร็จสมบูรณ์',
    } as { [Property in SponsorSttusCode]: string },
  },
}
