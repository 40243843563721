/**
 * 프로필 편집(마이페이지)
 */
export const profileControl = {
  avatar: 'แก้ไขอวาตาร์',
  profile: 'อัปโหลดโปรไฟล์',
  dialog: {
    title: 'การแจ้้งเตือน',
    content: 'อวาตาร์ที่แก้ไขจะถูกอัปเดตใน LM Wallet ทุกที่',
    actions: {
      positive: 'ตกลง',
    },
  },
}
