/**
 * auction
 */
export const auction = {
  topBanner: {
    text: "※ หากคุณคลิกที่แบนเนอร์ด้านบน จะนำคุณไปยัง 'แอปพลิเคชันการประมูลทางโทรศัพท์",
    alt: 'งานประมูล NFT ศิลปะ Oulim วันที่ 26 ตุลาคม 2023 ~ 2 พฤศจิกายน 2023',
  },
  displayCount: 'ยอดดู (Hits)',
  auctionError: 'ไม่สามารถดึงรายการงานได้ กรุณาลองอีกครั้งในภายหลัง',
  auctionEmpty: 'ไม่มีรายการ',
  auctionDetailError: 'ไม่สามารถดึงงานได้ กรุณาลองอีกครั้งในภายหลัง',
  auctionDetainNavigationBack: 'ดูรายการ',
}
