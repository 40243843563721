/**
 * 크리에이터 메인 프로필
 */
export const creatorMainProfile = {
  editMainProfile: 'การตั้งค่าโปรไฟล์หลัก',
  profileImageGuide: 'เปลี่ยนรูปโปรไฟล์ของหน้าหลัก',
  categoryGuide: 'เปลี่ยนหมวดหมู่ที่แสดงในหน้าหลัก',
  updateCreatorCategoryFail: 'ไม่สามารถเปลี่ยนหมวดหมู่ได้ กรุณาลองอีกครั้ง',
  updateCreatorCategorySuccess: 'เปลี่ยนหมวดหมู่เป็น {categoryName} แล้ว',
}
