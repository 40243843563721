import { commonError } from './commonError'
import type { ApplyCreatorStatus, ApplyCreatorConditionType } from '@store'
import type { BenefitKey } from '@pages/apply-creator/index.vue'

/**
 * 크리에이터 신청하기
 */
export const applyCreator = {
  entry: 'สมัครเป็น Creator',
  guide: {
    header: {
      target: '{nickname},',
      title: 'สมัครเพื่อเป็น Creator',
      description:
        'รับสิทธิประโยชน์เช่น โอกาสในการสร้างรายได้, การสนับสนุน Creator และอื่น ๆ',
      benefit:
        'ที่ {appName} เราต้อนรับทุกคนที่มีความสามารถในการสร้างสรรค์เนื้อหาที่เป็นเอกลักษณ์ของตนเอง!<br />คุณสามารถเป็น Web3 Creator และรับประโยชน์มากมายจากการสร้างสรรค์เนื้อหาได้อย่างเต็มที่<br /><br />เรายินดีต้อนรับ:<br />  ผู้ที่มีความสามารถในการผลิตเนื้อหาอย่างต่อเนื่อง<br />ผู้ที่มีเนื้อหาที่น่าสนใจและเป็นเอกลักษณ์<br />ผู้ที่มีเรื่องราวและความเชี่ยวชาญในสาขาของตน<br />ร่วมเป็นส่วนหนึ่งของเราและเพลิดเพลินกับประสบการณ์ใหม่ในการสร้างสรรค์!',
    },
    benefits: {
      label: 'สิทธิประโยชน์หลากหลาย',
      contents: {
        weekly: {
          label: 'แจกจ่ายรางวัลจากการทำกิจกรรมทุกสัปดาห์',
          description: 'มอบรางวัลใหญ่ขึ้นสำหรับผู้สร้างสรรค์เนื้อหาเท่านั้น',
        },
        fix: {
          label: 'รางวัลโบนัสประจำเดือน!',
          description:
            'รางวัลโบนัสเพิ่มเติมให้กับ Creator ที่มีส่วนร่วมในการกระตุ้นชุมชนด้วยเนื้อหาที่ยอดเยี่ยมและการสื่อสารที่มีชีวิตชีวาทุกเดือน!',
        },
        /* meeting: {
          label: 'Apply authority for Web3 Creator Fund',
          description:
            "Support LM token worth 10 million won for the creator's project selected through voting",
        }, */
        nft: {
          label: 'โอกาสในการสร้าง NFT',
          description: 'ให้โอกาสในการสร้าง NFT เมื่อลงมือทำโปรเจกต์ของคุณ!',
        },
        communication: {
          label: 'การสื่อสารกับแฟนคลับ',
          description:
            'สนับสนุนฟีเจอร์แชทและการถ่ายทอดสดระหว่าง Creator และแฟนคลับ',
        },
      } as { [Property in BenefitKey]: { label: string; description: string } },
    },
    requiredConditions: {
      label: 'ข้อกำหนด',
      kyc: 'การตรวจสอบ KYC',
    },
    optionalConditions: {
      label: 'กรุณาเลือกข้อกำหนดในการสมัครของคุณ',
      contents: {
        CUAR: 'ส่งเนื้อหาตัวอย่างมากกว่า 3 ชิ้น',
        CUFW: 'มีผู้ติดตามมากกว่า 10,000 คนในแพลตฟอร์มอื่น ๆ (Instagram, YouTube, TikTok ฯลฯ)',
      } as { [Property in ApplyCreatorConditionType]: string },
    },
    previewHomeLink: {
      label: 'ลิงก์หน้าแรก',
      action: 'การตรวจสอบการซ้ำ',
      message: {
        failLength: 'กรุณากรอกอย่างน้อย {min} ตัวอักษร',
        failDuplicate: 'ลิงก์นี้ถูกใช้โดย Creator อื่นแล้ว',
        failCharacter: 'กรุณากรอกเฉพาะภาษาอังกฤษ + ตัวเลข',
        failDefault: 'ไม่สามารถตรวจสอบการซ้ำได้ กรุณาลองอีกครั้งในภายหลัง',
        success: 'คุณสามารถใช้ลิงก์นี้ได้',
      },
    },
    notices: {
      label: 'ข้อควรระวัง',
      contents: [
        'หากคุณใช้บัญชีโซเชียลมีเดียหรือเนื้อหาของบุคคลอื่นโดยไม่ชอบด้วยกฎหมาย ผู้สมัครจะต้องรับผิดชอบต่อการสูญเสียทางกฎหมายและการเงินทั้งหมด',
        'เนื้อหาทั้งหมดที่อัปโหลดไปยัง I LIKE LM ต้องเป็นไปตามกฎหมายลิขสิทธิ์',
        'ผลการตรวจสอบการสมัครเป็น Creator จะได้รับการแจ้งภายใน 3 วันทำการ',
        'การตรวจสอบจะดำเนินการตามมาตรฐานภายใน และผู้ใช้ที่ถูกปฏิเสธสามารถสมัครใหม่ได้หลังจาก 30 วันนับจากวันที่สมัคร',
        'ข้อกำหนด, สิทธิประโยชน์, และเงื่อนไขการรักษาการจัดอันดับสำหรับวิธีการสมัคร Creator ข้างต้นอาจมีการเปลี่ยนแปลงหรือระงับตามการดำเนินงานของชุมชน',
      ],
    },
    apply: {
      status: {
        available: 'สถานะการสมัคร', // 추상적인 상태는 존재하지만 노출하지 않음
        approved: 'สามารถสมัครได้',
        underReview: 'ได้รับการอนุมัติ',
        reject: 'อยู่ระหว่างการตรวจสอบ',
      } as { [Property in ApplyCreatorStatus]: string },
      gateStatus: {
        underReview: 'ถูกปฏิเสธ',
        confirm: 'ผลลัพธ์',
      },
      action: {
        apply: 'สมัครเป็น Creator',
        review: 'ขอการตรวจสอบ',
        underReview: 'กำลังตรวจสอบ',
      },
      notificationTitle: 'การแจ้งเตือน',
      message: {
        failAlreadyCreator: 'คุณเป็น Creator อยู่แล้ว',
        failUnknown: 'คุณเป็นสมาชิกที่ไม่ได้ลงทะเบียน',
        failUser: 'ผู้ใช้คนนี้ไม่สามารถสมัครได้',
        failCondition: 'คุณไม่ตรงตามข้อกำหนดในการสมัครเป็น Creator',
        failCreatorLink: 'นี่คือลิงก์ Creator ที่ต้องการการยืนยัน',
        failDefault:
          'การสมัครของคุณไม่สามารถดำเนินการได้ กรุณาลองอีกครั้งในภายหลัง',
        failReject:
          'การสมัครของ {userNcnm} ถูกปฏิเสธ<br />คุณสามารถสมัครใหม่ได้หลังจาก 30 วันจากวันที่สมัคร',
        failUnderReview:
          'การสมัครของ {userNcnm} อยู่ระหว่างการตรวจสอบ<br />ผลจะแจ้งภายใน 3 วันทำการ',
        successApply:
          'การสมัครเป็น Creator สำเร็จ<br />ผลจะแจ้งภายใน 3 วันทำการ',
        errors: {
          4014: 'ผู้ใช้ไม่สามารถสมัครได้',
          4016: 'ต้องตรวจสอบรหัส',
          4018: 'ที่อยู่ Creator ได้ใช้ไปแล้ว',
          default: commonError.network.message,
        },
      },
    },
  },
  requireKycDialog: {
    title: 'การแจ้งเตือน',
    message: 'คุณต้องทำการตรวจสอบ KYC ให้เสร็จสิ้นเพื่อสมัครเป็น Creator',
    confirm: 'ตรวจสอบ KYC',
  },
  // 크리에이터 신청: 대표 콘텐츠 3개
  conditionsContent: {
    pageTitle: 'การสมัครเป็น Creator กำลังดำเนินการ',
    sections: {
      activity: {
        title: 'เขียนแผนง่าย ๆ สำหรับกิจกรรมเนื้อหาในฐานะ Creator {appName}',
        titleSub: '(มากกว่า {count} ตัวอักษร)',
        textField: {
          placeholder: '',
          error: 'กรุณากรอกอย่างน้อย {count} ตัวอักษร',
        },
      },
      info: {
        title: 'ข้อมูลในการสมัคร',
        representative: 'ส่งเนื้อหาตัวอย่าง ({count} ชิ้นหรือมากกว่า)',
        contentsLink: 'แนบลิงก์เนื้อหา',
        contentsPlaceholder: {
          CLK: 'กรุณากรอกลิงก์เนื้อหา',
          CAT: 'กรุณาอัปโหลดไฟล์เนื้อหา',
        },
        contentsFile: 'แนบไฟล์เนื้อหา',
        contentsEmpty: 'กรุณาเพิ่มเนื้อหาด้วยการคลิกปุ่ม "+"',
        error: 'กรอกเนื้อหาตัวอย่างมากกว่า {count} ชิ้น',
        contentsLinkEmpty: 'กรุณากรอกลิงก์เนื้อหา',
        contentsLinkError: 'นี่ไม่ใช่ลิงก์เนื้อหาที่ถูกต้อง',
        contentsLinkNotMatch: 'กรุณากรอกลิงก์โพสต์ที่คุณอัปโหลด',
        contentsLinkNotPostOwner: 'โพสต์ไม่ใช่โพสต์ที่คุณอัปโหลด',
        contentsFileEmpty: 'กรุณาอัปโหลดไฟล์เนื้อหา',
        contentsDuplicate: 'เนื้อหาที่ลงทะเบียนแล้ว',
        descriptions: [
          'กรุณาเลือกลิงก์หรือไฟล์ที่จะส่ง (อย่างน้อย 3 และไม่เกิน 5)',
          'คุณสามารถอัปโหลดไฟล์ jpg, png, mp4, word เป็นต้น',
          'กด "ลงทะเบียน" เพื่อเพิ่มเนื้อหา',
          'กด "ลบ" เพื่อลบเนื้อหา',
        ],
        sns: 'ที่อยู่โซเชียลมีเดีย',
        snsSubTitle: '(มีผู้ติดตามมากกว่า 10,000 คน)',
        snsPlaceholder: 'กรอกที่อยู่โซเชียลมีเดีย',
        snsErrMsg: 'ที่อยู่โซเชียลมีเดียนี้ไม่ถูกต้อง',
        verifiedSnsAccount: 'ฉันยืนยันว่านี่คือบัญชีของฉันเอง',
      },
      creatorCategory: {
        title: 'เลือกหมวดหมู่ของครีเอเตอร์',
        placeholder: 'เลือกหมวดหมู่',
        error: 'จงเลือกหมวดหมู่',
      },
      creatorHomeLink: {
        title: 'การตั้งค่าที่อยู่ของหน้าโฮมครีเอเตอร์',
        checkDuplicate: 'การตรวจสอบข้อมูลซ้ำ',
        checkedDuplicate: 'ยืนยันแล้ว',
        errors: {
          unavailableCreatorHomeLink:
            'มีตัวอักษรที่ไม่ถูกต้องในข้อความ กรุณาใช้ลิงก์ Creator Home อื่น',
          checkDuplicate: 'กรุณาตรวจสอบการซ้ำ',
          failLength: 'กรุณากรอกอย่างน้อย {min} ตัวอักษร',
          failDuplicate: 'ที่อยู่ถูกใช้แล้ว',
          failCharacter: 'กรุณากรอกเฉพาะภาษาอังกฤษ + ตัวเลข',
          failDefault:
            'ไม่สามารถตรวจสอบการซ้ำได้ในขณะนี้ กรุณาลองอีกครั้งในภายหลัง',
        },
        success: 'นี่คือลิงก์ของ Creator Home ที่สามารถใช้งานได้',
      },
      howToKnow: {
        title: 'คุณรู้จัก {appName} ได้อย่างไร?',
        placeholder: 'เช่น โฆษณาบน Instagram, การแนะนำ ฯลฯ',
      },
    },
    apply: 'คำขอการตรวจสอบ',
  },
}
