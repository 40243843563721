import { commonError } from './commonError'

export const blockMessage = {
  messages: {
    blockedByOtherUserPrimary: '<strong>{userNcnm}</strong> บล็อกฉันแล้ว',
    blockedByOtherUserSecondary:
      'คุณไม่สามารถติดตาม {userNcnm} หรือดูเนื้อหาของ {userNcnm} ได้',
    blockByCurrentUser: '<strong>{userNcnm}</strong> ถูกบล็อก',
    showContentsPrimary: 'คุณต้องการดูเนื้อหาเหล่านี้หรือไม่?',
    showContentsSecondary: 'การดูเนื้อหาไม่ทำให้ {userNcnm} ถูกปลดบล็อก',
    unBlocking: 'คุณต้องการปลดบล็อกหรือไม่?',
  },
  modal: {
    block: {
      title: 'บล็อก',
      contents: {
        title: 'คุณต้องการบล็อก <strong>{userNcnm}</strong> หรือไม่?',
        message: 'ผู้ใช้ไม่สามารถติดตามฉันหรือดูเนื้อหาของฉันได้',
        novaPlusMessage:
          'ผู้ใช้เป็นผู้สนับสนุน NOVA+ ของคุณ<br />การบล็อกผู้สนับสนุน NOVA+ โดยไม่มีเหตุผลที่สมเหตุสมผล (เช่น การด่าทอ/การติดตาม) อาจได้รับการลงโทษ',
      },
    },
    unblock: {
      title: 'ปลดบล็อก',
      contents: {
        title: 'คุณต้องการปลดบล็อก <strong>{userNcnm}</strong> หรือไม่?',
        message: 'ผู้ใช้สามารถติดตามฉันและดูเนื้อหาของฉันได้',
      },
    },
  },
  toastMessage: {
    errors: {
      2010: 'ผู้ใช้ถูกบล็อกแล้ว', // already block error
      2011: 'ผู้ใช้ยังไม่ได้ถูกบล็อก', // already unblock error
      4002: 'สมาชิกนี้ยังไม่ได้ลงทะเบียน',
      4013: 'สมาชิกนี้ยังไม่ได้ลงทะเบียน',
      unknown: commonError.network.message,
    },
    blocking: 'คุณถูกบล็อก',
    unBlocking: 'ปลดบล็อกแล้ว',
  },
  actions: {
    blocked: 'ถูกบล็อก',
    showContents: 'ดูเนื้อหา',
    unBlock: 'ปลดบล็อก',
    block: 'บล็อก',
    cancel: 'ยกเลิก',
  },
}
