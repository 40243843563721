/**
 * 크리에이터 방명록
 */
export const creatorNotice = {
  regCreatorNoticeModal: {
    title: 'เขียนประกาศ',
    textareaPlaceholder: 'กรุณาใส่เนื้อหา',
    actions: {
      negative: 'ยกเลิก',
      positive: 'เขียน',
    },
  },
  request: {
    actions: {
      delete: 'ลบ',
    },
    list: {
      empty: 'ไม่มีประกาศที่ลงทะเบียนไว้',
      error: 'ไม่สามารถดึงประกาศได้ กรุณาลองอีกครั้งในภายหลัง',
    },
    delete: {
      error: 'ไม่สามารถลบประกาศได้ กรุณาลองอีกครั้งในภายหลัง',
      success: 'ประกาศได้ถูกลบแล้ว',
    },
    reg: {
      stopReg:
        'หากปิดในขณะที่ยังไม่ได้บันทึก ประกาศที่คุณกรอกไว้จะถูกลบ คุณต้องการลบโดยไม่บันทึกหรือไม่?',
      validLength: 'กรุณาใส่เนื้อหาประกาศ',
      error: 'ไม่สามารถเขียนประกาศได้ กรุณาลองอีกครั้งในภายหลัง',
      success: 'ได้เขียนประกาศแล้ว',
    },
  },
}
