export default {
  board: () => import("/codebuild/output/src199376353/src/layouts/board.vue").then(m => m.default || m),
  "call-back": () => import("/codebuild/output/src199376353/src/layouts/call-back.vue").then(m => m.default || m),
  "creator-home": () => import("/codebuild/output/src199376353/src/layouts/creator-home.vue").then(m => m.default || m),
  default: () => import("/codebuild/output/src199376353/src/layouts/default.vue").then(m => m.default || m),
  fund: () => import("/codebuild/output/src199376353/src/layouts/fund.vue").then(m => m.default || m),
  hashtag: () => import("/codebuild/output/src199376353/src/layouts/hashtag.vue").then(m => m.default || m),
  "nova-plus": () => import("/codebuild/output/src199376353/src/layouts/nova-plus.vue").then(m => m.default || m),
  "one-column-full-height": () => import("/codebuild/output/src199376353/src/layouts/one-column-full-height.vue").then(m => m.default || m),
  "one-column": () => import("/codebuild/output/src199376353/src/layouts/one-column.vue").then(m => m.default || m),
  search: () => import("/codebuild/output/src199376353/src/layouts/search.vue").then(m => m.default || m),
  "user-home": () => import("/codebuild/output/src199376353/src/layouts/user-home.vue").then(m => m.default || m)
}