/**
 * 다이얼로그
 */
export const dialog = {
  requireSignIn: {
    title: 'การแจ้งเตือน',
    description: 'ต้องเข้าสู่ระบบก่อน ต้องการเข้าสู่ระบบหรือไม่?',
    action: {
      negative: 'ยกเลิก',
      positive: 'เข้าสู่ระบบ',
    },
  },
  resultSignUp: {
    success: {
      title: 'สมัครสมาชิกเสร็จสมบูรณ์',
      content: 'ขอแสดงความยินดีในการสมัครสมาชิก!',
    },
    fail: {
      title: 'สมัครสมาชิกล้มเหลว',
      content:
        'เกิดข้อผิดพลาดระหว่างการดำเนินการคำขอ<br>ขอโทษในความไม่สะดวกในการใช้บริการ<br>กรุณาตรวจสอบและลองอีกครั้ง',
    },
    confirm: 'ตกลง',
  },
  resultSignIn: {
    fail: {
      title: 'เข้าสู่ระบบล้มเหลว',
      content:
        'เกิดข้อผิดพลาดระหว่างการดำเนินการคำขอ ขอโทษในความไม่สะดวกในการใช้บริการ กรุณาตรวจสอบและลองอีกครั้ง',
    },
  },
  confirmCommentDelete: {
    title: 'การแจ้งเตือน',
    description: 'คุณต้องการลบความคิดเห็นหรือไม่?',
    action: {
      negative: 'ยกเลิก',
      positive: 'ลบ',
    },
  },
  postDetailDrop: {
    title: 'การแจ้งเตือน',
    description: 'โพสต์ที่รายงาน',
    action: {
      positive: 'ตกลง',
    },
  },
  postDetailBlocked: {
    title: 'การแจ้งเตือน',
    description: 'โพสต์ที่ถูกบล็อก',
    action: {
      positive: 'ตกลง',
    },
  },
  postUnSubscribed: {
    title: 'การแจ้งเตือน',
    description: 'คุณไม่มีสิทธิ์เข้าถึงโพสต์ {subscribeGrade}',
    action: {
      positive: 'ตกลง',
    },
  },
  confirmTempPostDelete: {
    title: 'การแจ้งเตือน',
    description: 'คุณต้องการลบโพสต์ชั่วคราวหรือไม่?',
    action: {
      positive: 'ลบ',
    },
  },
  multipleSignIn: {
    title: 'การแจ้งเตือน',
    description:
      'คุณได้เข้าสู่ระบบด้วยบัญชีอื่นในเบราว์เซอร์อื่น บัญชีเดิมจะถูกออกจากระบบ',
    action: {
      positive: 'ตกลง',
    },
  },
  referralReg: {
    title: 'ลงทะเบียนผู้แนะนำ',
    placeholder: 'กรุณาใส่อีเมล',
    error: 'ไม่สามารถลงทะเบียนผู้แนะนำได้ กรุณาลองอีกครั้งในภายหลัง',
    success: 'ผู้แนะนำได้ถูกลงทะเบียนแล้ว',
    validate: 'กรุณาใส่อีเมลที่ถูกต้อง',
  },
  event: {
    confirm: 'ตกลง',
    regist: {
      title: 'การแจ้งเตือน',
      content: 'สามารถยื่นเรื่องผ่านแอปได้',
    },
    apply: {
      title: 'การแจ้งเตือน',
      content: 'สามารถสมัครผ่านแอปได้',
    },
  },
}
