import { corporationInfo } from './corporationInfo'
import { kyc } from './kyc'
import { profileControl } from './profile-control'
import { donateAction } from './donate-action'
import { creatorGrade } from './creatorGrade'
import { applyCreator } from './applyCreator'
import { commonError } from './commonError'
import { referral } from './referral'
import { creatorHomeLink } from './creatorHomeLink'
import { creatorGuestBook } from './creatorGuestBook'
import { creatorNotice } from './creatorNotice'
import { creatorHome } from './creator'
import { share } from './share'
import { mypage } from './mypage'
import { dialog } from './dialog'
import { userSummery } from './user-summery'
import { creatorMyPage } from './creatorMyPage'
import { home } from './home'
import { navigations } from './navigations'
import { creatorMainProfile } from './creatorMainProfile'
import { w3cf } from './w3cf'
import { subscribe } from './subscribe'
import { pay } from './pay'
import { accountPanel } from './account-panel'
import { notificationsPanel } from './notificationsPanel'
import { fund } from './fund'
import { timeLimitPopup } from './time-limit-popup'
import { sponsors } from './sponsors'
import { auction } from './auction'
import { terminateApp } from './terminate-app'
import { requestAppPermission } from './request-app-permission'
import { pullToRefresh } from './pullToRefresh'
import { notificationPermission } from './notification-permission'
import { sendbird } from './sendbird'
import { notificationSetting } from './notification-setting'
import { blockMessage } from './block-message'
import { homeActions } from './home-actions'
import { dailyCheck } from './daily-check'
import { blockAccess } from './block-access'
import { postCreate } from './postCreate'
import { serviceBenefits } from './serviceBenefits'
import { rewardPopup } from './rewardPopup'
import { mainTop } from './main-top'
import { aiContents } from './aiContents'

import type {
  CommunityMenu,
  FeedMenu,
} from '@components/NovaHeaderMenuPanelMobileView/NovaHeaderMenuPanelMobileView.types'
import type {
  PostsOrderBy,
  PostPrivateAction,
  CommentsAndRepliesOrderBy,
  CommentAndReplyPrivateActionType,
  SearchAllItemType,
  NoticeAction,
} from '@store'

export default {
  message: 'สวัสดี',
  confirm: 'ยืนยัน',
  cancel: 'ยกเลิก',
  save: 'บันทึก',
  delete: 'ลบ',
  reg: 'ลงทะเบียน',
  modify: 'แก้ไข',
  goHome: 'กลับไปที่หน้าแรก',
  goMyHome: 'กลับไปที่หน้าแรกของฉัน',
  goCreatorHome: 'ไปที่หน้าแรกของ Creator',
  myTotalRewards: 'รางวัลรวมของฉัน',
  rewardsGate: 'รางวัลของฉัน',
  useReward: 'ใช้รางวัล',
  kycBeforeUseReward: 'ทำ KYC ให้ครบถ้วนเพื่อรับรางวัล',
  helpCenter: 'ศูนย์ช่วยเหลือ {appName}',
  attendanceCheck: 'เช็คอินรายวัน',
  displayCount: 'ยอดวิว',
  category: 'หมวดหมู่',
  allView: 'ทั้งหมด',
  sign: {
    in: 'ลงชื่อเข้าใช้',
    up: 'ลงทะเบียน',
    out: 'ออกจากระบบ',
    off: 'ลบบัญชี',
  },
  myRewards: 'กิจกรรมและรางวัลของฉัน',
  linkMyHome: 'หน้าแรกของฉัน',
  settings: 'การตั้งค่า',
  servicesAndPolicies: 'บริการและนโยบาย',
  servicesAndPoliciesMenus: {
    servicesMenu: 'บริการ',
    termsMenu: 'นโยบาย',
  },
  servicesMenu: {
    leisureMetaVerse: 'LEISURE METAVERSE',
    theMoonLabs: 'The Moon Labs',
  },
  service: 'บริการ',
  services: {
    leisureMetaVerse: 'LEISURE METAVERSE',
    theMoonLabs: 'The Moon Labs',
  },
  profile: 'โปรไฟล์',
  cs: 'บริการลูกค้า',
  accountInfo: 'แก้ไขโปรไฟล์',
  account: 'แก้ไขโปรไฟล์',
  defaultSettings: 'การตั้งค่า',
  notification: 'การแจ้งเตือน',
  notifications: 'การแจ้งเตือน',
  notificationAndSetting: 'การตั้งค่าการแจ้งเตือน',
  selectNotificationSound: 'เลือกเสียงการแจ้งเตือน',
  language: 'ภาษา',
  languageSettings: 'ตั้งค่าภาษา',
  korean: 'KOR (ภาษาเกาหลี)',
  english: 'ENG (ภาษาอังกฤษ)',
  thai: 'THA (ภาษาไทย)',
  spanish: 'ESP (ภาษาสเปน)',
  policy: 'นโยบาย',
  termsAndPolicies: 'ข้อกำหนดและนโยบาย',
  name: 'ชื่อ',
  nickName: 'ชื่อเล่น',
  email: 'วันเกิด',
  referralLabel: 'อีเมลของผู้แนะนำ',
  checkDuplicate: 'รหัสผ่าน',
  birthday: 'เปลี่ยน',
  recommenderEmail: 'ลงทะเบียน',
  changePassword: {
    title: 'รหัสผ่าน',
    change: 'เปลี่ยน',
  },
  referralReg: 'การลงทะเบียน',
  membershipOff: 'ลบบัญชี',
  suggestSignUpCopy: 'ผู้ท้าชิงที่จะร่ำรวยจากเหรียญ!',
  apply: 'คำขอ',
  comments: 'ความคิดเห็น',
  commentsExpand: 'แสดงความคิดเห็น',
  commentsCollapse: 'ซ่อนความคิดเห็น',
  commentStatus: {
    hide: 'นี่คือความคิดเห็นที่ซ่อนอยู่',
    report: 'นี่คือความคิดเห็นที่รายงาน',
    delete: 'นี่คือความคิดเห็นที่ลบแล้ว',
    signOff: 'นี่คือความคิดเห็นที่ลบแล้ว',
  } as { [Property in CommentAndReplyPrivateActionType]: string },
  replies: 'คำตอบ',
  postAction: 'กิจกรรมการโพสต์',
  createPost: 'เขียน',
  editPost: 'แก้ไข',
  createNotice: 'เขียน',
  modifyNotice: 'แก้ไข',
  viewRelatedPosts: 'แสดงโพสต์ที่เกี่ยวข้อง',
  event: 'อีเวนท์',
  notice: 'ประกาศ',
  answNotifyDescription:
    'การแจ้งเตือนเกี่ยวกับความคิดเห็นและกิจกรรมในความคิดเห็นของคุณ',
  evntNotifyDescription: 'การแจ้งเตือนสำหรับเหตุการณ์',
  ntceNotifyDescription: 'การแจ้งเตือนสำหรับประกาศ',
  postNotifyDescription: 'การแจ้งเตือนสำหรับกิจกรรมบนโพสต์ของคุณ',
  dntnNotifyDescription:
    'ทั่วไป, การแจ้งเตือนสำหรับการบริจาคโพสต์ {subscribeGrade}',
  cprpNotifyDescription:
    'การแจ้งเตือนสำหรับกิจกรรมบนโพสต์ {subscribeGrade} ของคุณ',
  cppsNotifyDescription: 'การแจ้งเตือนสำหรับผู้สนับสนุนของ {subscribeGrade}',
  faq: 'คำถามที่พบบ่อย',
  hashtag: 'แฮชแท็ก',
  hashtagRanking: 'เทรนด์',
  searchHashtag: 'ค้นหา',
  searchPlaceholder: 'ค้นหา',
  seeMore: 'เพิ่มเติม',
  creator: 'Creator',
  linkCreatorHome: 'หน้าแรกของ Creator',
  creatorHomeLink,
  creatorGuestBook,
  creatorNotice,
  creatorGrade,
  creatorHome,
  follower: 'ผู้ติดตาม',
  follow: 'ติดตาม',
  following: 'ติดตามอยู่',
  donate: 'บริจาค',
  donateAction,
  gapTime: {
    underHour: 'ก่อนหน้านี้',
    underDay: 'ชั่วโมงที่แล้ว',
    underWeek: 'วันที่แล้ว',
  },
  showTermDetail: 'ดู',
  agreeAllTerms: 'ข้อตกลงและเงื่อนไขฉบับสมบูรณ์',
  essentialTerm: '[จำเป็น]',
  optionalTerm: '[ไม่จำเป็น]',
  rewards: {
    title: 'ท้าชิงรายวันเพื่อรับรางวัล',
    description: 'ทำให้สำเร็จตามเป้าหมายและรับรางวัลมากมาย!',
    goRewards: 'รางวัลของฉัน',
    certificationKyc: 'ทำ KYC ให้ครบถ้วนเพื่อรับรางวัล',
    useRewards: 'ใช้รางวัล',
    rewardsCopy: {
      article:
        'คุณได้รับรางวัลเฉลี่ย <span class="text-color-primary-blue text-bold">{lm} LM</span> หรือหนึ่งข้อความ',
      comment:
        'คุณได้รับรางวัลเฉลี่ย <span class="text-color-primary-blue text-bold">{lm} LM</span> สำหรับหนึ่งความคิดเห็น',
      post: 'คุณได้รับรางวัลเฉลี่ย <span class="text-color-primary-blue text-bold">{lm} LM</span> สำหรับหนึ่งโพสต์',
    },
    lastWeekAverageLM:
      '※ ราคา LM เฉลี่ย <span class="text-color-2 text-medium">{price}</span> วอนสำหรับสัปดาห์ที่ผ่านมา',
  },
  postRewards: {
    expected: 'รางวัลที่คาดว่าจะได้รับ',
    total: 'รางวัลทั้งหมด',
  },
  postRecommend: {
    like: 'ถูกใจ',
    disLike: 'ไม่ถูกใจ',
  },
  postPrivateAction: {
    share: 'แชร์',
    scrap: 'บันทึก',
    cancelScrap: 'ยกเลิกการบันทึก',
    // hide: 'ซ่อน',
    report: 'รายงาน',
    edit: 'แก้ไข',
    remove: 'ลบ',
    donate: 'บริจาค',
  } as { [Property in PostPrivateAction]: string },
  postRemoveConfirmMessage: 'คุณต้องการลบโพสต์ของคุณหรือไม่?',
  noticeAction: {
    modify: 'แก้ไข',
    remove: 'ลบ',
  } as { [Property in NoticeAction]: string },
  noticeRemoveConfirmMessage: 'คุณต้องการลบโพสต์ของคุณหรือไม่?',
  searchMenu: 'เมนูค้นหา',
  feeds: 'ฟีด',
  feedsMenu: {
    forYouPost: 'โพสต์สำหรับคุณ',
    recentPost: 'โพสต์ล่าสุด',
    followingPost: 'โพสต์ที่ติดตาม',
  } as { [Property in FeedMenu]: string },
  community: 'ชุมชน',
  communityMenus: {
    // best: 'ดีที่สุด',
    // basicGuide: 'คู่มือพื้นฐาน',
    // insight: 'ข้อมูลเชิงลึก',
    // allTemplate: 'เทมเพลตทั้งหมด',
    categories: 'หมวดหมู่',
  } as { [Property in CommunityMenu]: string },
  etc: 'อื่นๆ',
  etcMenus: {
    event: 'เหตุการณ์',
    notice: 'ประกาศ',
    faq: 'คำถามที่พบบ่อย',
  },
  report: {
    inappropriate: 'โพสต์ที่ไม่เหมาะสม (เนื้อหาอาจจะมีความรุนแรง)',
    false: 'เนื้อหาปลอม',
    spam: 'สแปม',
    etc: 'อื่นๆ',
  },
  reportForm: {
    title: 'รายงาน',
    dropdownPlaceholder: 'หมวดหมู่การรายงาน',
    textareaPlaceholder: 'เขียนเนื้อหา',
  },
  reportAction: {
    cancel: 'ยกเลิก',
    send: 'รายงาน',
  },
  statusMessages: {
    searchKeyword: {
      empty: 'กรุณาใส่คีย์เวิร์ดการค้นหา',
    },
    search: {
      success: "ผลลัพธ์การค้นหา <strong>'{keyword}'</strong>",
      empty: 'ไม่มีผลลัพธ์การค้นหา {keyword}',
      error: 'ไม่สามารถค้นหา {keyword} ได้ โปรดลองอีกครั้งภายหลัง',
    },
    menus: {
      empty: '{menu} ไม่มีเมนู',
    },
    postsByHashtagSimple: {
      empty: '{hashtag} ไม่มีโพสต์',
      error: '{hashtag} ไม่สามารถค้นหาโพสต์ได้ โปรดลองอีกครั้งภายหลัง',
    },
    categories: {
      empty: 'ไม่มีหมวดหมู่ที่พร้อมใช้งาน',
    },
    hashtagRanking: {
      empty: 'ไม่มีแฮชแท็กที่พร้อมใช้งาน',
    },
    tempList: {
      empty: 'ไม่มีรายการบันทึกชั่วคราว',
      error: 'ไม่สามารถค้นหารายการบันทึกชั่วคราวได้ โปรดลองอีกครั้ง',
    },
    recommendAndUnRecommend: {
      recommend: {
        success: 'เคยถูกใจ',
        error: 'ไม่สามารถกดถูกใจได้ กรุณาลองอีกครั้งภายหลัง',
        8048: 'ไม่สามารถทำกิจกรรมเดียวกันซ้ำได้ภายในช่วงเวลาที่กำหนด',
      },
      recommendCancel: 'ไม่สามารถยกเลิกการกดถูกใจได้ กรุณาลองอีกครั้งภายหลัง',
      unRecommend: 'ไม่สามารถกดไม่ชอบได้ กรุณาลองอีกครั้งภายหลัง',
      unRecommendCancel:
        'ไม่สามารถยกเลิกการกดไม่ชอบได้ กรุณาลองอีกครั้งภายหลัง',
    },
    postScrap: {
      success: 'โพสต์ถูกบันทึกแล้ว',
      error: 'ไม่สามารถบันทึกโพสต์ได้ กรุณาลองอีกครั้งภายหลัง',
    },
    postScrapCancel: {
      success: 'โพสต์ถูกยกเลิกการบันทึก',
      error: 'ไม่สามารถบันทึกโพสต์ได้ กรุณาลองอีกครั้งภายหลัง',
    },
    postHide: {
      error: 'ไม่สามารถซ่อนโพสต์ได้ กรุณาลองอีกครั้งภายหลัง',
    },
    postReport: {
      success: 'รายงานโพสต์ได้รับการรับข้อมูลตามปกติ',
      errors: {
        2000: 'ไม่สามารถค้นหาโพสต์',
        2006: 'เลือกหมวดหมู่การรายงาน',
        2010: 'โพสต์นี้ได้รับการรายงานแล้ว',
        2012: 'คุณไม่สามารถรายงานตัวเองได้ ',
        4002: 'เป็นผู้ใช้ที่ยังไม่ได้ลงทะเบียน',
        2020: 'โพสต์นี้ได้รับการตรวจสอบแล้ว',
        unknown: 'ไม่สามารถรายงานโพสต์ได้ กรุณาลองอีกครั้งภายหลัง',
      },
    },
    postRemove: {
      error: 'ไม่สามารถลบโพสต์ได้ กรุณาลองอีกครั้งภายหลัง',
    },
    tempPostRemove: {
      error: 'เราไม่สามารถลบโพสต์ร่างของคุณได้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
    },
    mypageList: {
      reward: {
        empty: 'คุณยังไม่ได้รับรางวัลใดๆ',
        error:
          'เราไม่สามารถดึงประวัติรางวัลของคุณได้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
      },
      post: {
        empty: 'คุณยังไม่ได้สร้างโพสต์ใดๆ',
        error:
          'เราไม่สามารถนำเข้ารายการโพสต์ของคุณได้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
      },
      comment: {
        empty: 'คุณยังไม่ได้แสดงความคิดเห็นใดๆ บนโพสต์',
        error:
          'เราไม่สามารถนำเข้าบทความที่มีความคิดเห็นได้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
      },
      recommend: {
        get: {
          empty: 'คุณยังไม่ได้รับการกดถูกใจใดๆ',
          error:
            'เราไม่สามารถนำเข้าบทความที่มีการกดถูกใจได้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
        },
        set: {
          empty: 'คุณยังไม่ได้กดถูกใจโพสต์ใดๆ',
          error:
            'เราไม่สามารถนำเข้าบทความที่คุณกดถูกใจได้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
        },
      },
      scrap: {
        empty: 'คุณยังไม่ได้บันทึกโพสต์ใดๆ',
        error:
          'เราไม่สามารถนำเข้ารายการโพสต์ได้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
      },
    },
    creatorList: {
      guest: {
        empty: 'มีกำหนดเปิดในภายหลัง',
      },
      notice: {
        empty: 'มีกำหนดเปิดในภายหลัง',
      },
    },
    creators: {
      empty: 'ไม่มี Creator',
      error: 'เราไม่สามารถแสดง Creator ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
    },
    users: {
      empty: 'ไม่มีผู้ใช้',
      error: 'เราไม่สามารถแสดงผู้ใช้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
    },
    creatorFollow: {
      error: 'ไม่สามารถติดตามได้ กรุณาลองอีกครั้งภายหลัง',
    },
    creatorUnfollow: {
      error: 'ไม่สามารถเลิกติดตามได้ กรุณาลองอีกครั้งภายหลัง',
    },
    creatorHome: {
      invalidUrl: 'กรุณาตรวจสอบ URL อีกครั้ง',
    },
    recentSearches: {
      empty: 'ไม่มีคำค้นหาล่าสุด',
    },
    postDetail: {
      error: {
        notFound: 'โพสต์นี้ถูกลบหรือไม่สามารถค้นหาได้',
        drop: 'นี่คือโพสต์ที่ได้รับการตรวจสอบแล้ว',
        notSubscribed: 'คุณไม่มีสิทธิ์เข้าถึงโพสต์ {subscribeGrade}',
        blocked:
          'เจ้าของโพสต์บล็อกคุณ ดังนั้นคุณจึงไม่สามารถเข้าถึงโพสต์นี้ได้',
      },
    },
    comments: {
      empty: 'ไม่มีความคิดเห็น<br />กรุณาเป็นคนแรกที่แสดงความคิดเห็น!',
      error: 'เราไม่สามารถแสดงความคิดเห็นในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
    },
    commentsAdd: {
      empty: '{type} ไม่มีเนื้อหา',
      errors: {
        8048: 'ไม่สามารถทำกิจกรรมเดียวกันซ้ำได้ภายในช่วงเวลาที่กำหนด',
        default: 'เราไม่สามารถแก้ไข {type} ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
      },
    },
    commentsEdit: {
      empty: '{type} ไม่มีเนื้อหา',
      error: 'เราไม่สามารถลงทะเบียน {type} ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
    },
    commentsDelete: {
      denied: 'ความคิดเห็นนี้ถูกลบไปแล้ว',
      error: 'ไม่สามารถลบความคิดเห็นนี้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
    },
    commentsReport: {
      success: 'รายงานได้รับข้อมูลสำเร็จ',
      errors: {
        2000: 'ไม่สามารถค้นหาความคิดเห็น',
        2006: 'เลือกหมวดหมู่การรายงาน',
        2010: 'ความคิดเห็นนี้ได้รับการรายงานแล้ว',
        2012: 'คุณไม่สามารถรายงานตัวเองได้',
        2020: 'ความคิดเห็นนี้ได้รับการลงโทษแล้ว',
        4002: 'เป็นผู้ใช้ที่ยังไม่ได้ลงทะเบียน',
        unknown: 'ไม่สามารถรายงานความคิดเห็นได้ กรุณาลองอีกครั้งภายหลัง',
      },
    },
    commentsHide: {
      denied: 'ความคิดเห็นนี้ถูกซ่อนไปแล้ว',
      error: 'ไม่สามารถซ่อนความคิดเห็นนี้ได้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
    },
    boardList: {
      empty: 'ไม่มีข้อความที่ลงทะเบียน',
      error: 'ไม่สามารถนำเข้ารายการที่เกี่ยวข้องได้ กรุณาลองอีกครั้งภายหลัง',
    },
    tableList: {
      empty: 'ไม่มีข้อมูล',
      error: 'ไม่สามารถนำเข้ารายการที่เกี่ยวข้องได้ กรุณาลองอีกครั้งภายหลัง',
    },
    retire: {
      loading: 'การค้นหาสินทรัพย์ใช้เวลาในการถอนการเป็นสมาชิก กรุณารอสักครู่',
      error: 'ไม่สามารถนำเข้าข้อมูลได้ กรุณาลองอีกครั้งภายหลัง',
    },
    notification: {
      empty: 'ไม่มีการแจ้งเตือน',
      error: 'ไม่สามารถนำเข้ารายการการแจ้งเตือนได้ กรุณาลองอีกครั้งภายหลัง',
    },
    network: {
      error: commonError.network.message,
    },
  },
  postCreate,
  noticeCreate: {
    placeholder: {
      public: 'สาธารณะ',
      tempSave: 'โหลดร่าง',
      title: 'เขียนหัวข้อ',
      content: 'เขียนเนื้อหา (จำเป็น)',
      hashtag: '#ใส่แฮชแท็ก',
    },
    button: {
      tempSave: 'บันทึกร่าง',
      preview: 'ดูตัวอย่าง',
      save: 'บันทึก',
      confirm: 'ยืนยัน',
    },
    toastMessage: {
      tempSave: 'ได้ถูกบันทึกชั่วคราวแล้ว',
      noLinkSelect: 'กรุณาเลือกข้อความเพื่อลิงก์',
      havingLink: 'ลิงก์ได้ถูกกำหนดไว้แล้ว',
      validateTitle: 'กรุณาเขียนหัวข้อมากกว่า 2 ตัวอักษร',
      validateContents: 'กรุณาเขียนเนื้อหามากกว่า 10 ตัวอักษร',
      validateHashtagCharacter: 'สามารถใส่ภาษาเกาหลี, ภาษาอังกฤษ และตัวเลข',
      validateHashtagCheckByte: 'สามารถใส่ได้สูงสุด 30 ตัวอักษร',
      validateHashtagCount: 'สามารถใส่ได้สูงสุด 5 แท็ก',
      validateHashtagDuplicate: 'แท็กที่ซ้ำกันมีอยู่',
      imgError2005: 'ไม่สามารถลงทะเบียนส่วนขยายนี้ได้',
      imgError: 'การอัปโหลดภาพล้มเหลว กรุณาลองอีกครั้งภายหลัง',
      error: 'เกิดข้อผิดพลาดในการประมวลผลคำขอของคุณ กรุณาลองอีกครั้งภายหลัง',
    },
    modal: {
      tempTitle: 'ร่าง',
      previewTitle: 'ดูตัวอย่าง',
      dialogTitle: 'ยืนยันการลบ',
      dialogContent:
        'หากคุณปิดโดยไม่บันทึก ข้อมูลที่คุณกรอกจะหายไป คุณต้องการดำเนินการต่อและละทิ้งการเปลี่ยนแปลงหรือไม่?',
    },
  },
  qna: {
    title: 'สอบถามลูกค้า',
    button: {
      cancel: 'ยกเลิก',
      confirm: 'ส่ง',
    },
    reportMessage: '*คำตอบจะถูกส่งไปยังอีเมลของบัญชีคุณ',
    placeholder: {
      content: 'กรุณาใส่เนื้อหา',
      dropdown: 'หมวดหมู่การสอบถาม',
    },
    error:
      'เราไม่สามารถประมวลผลคำขอสอบถามของคุณได้ในขณะนี้ กรุณาลองอีกครั้งภายหลัง',
    dialog: {
      title: 'การสอบถาม',
      success: 'ได้รับคำถามของคุณแล้ว เราจะติดต่อกลับไปในเร็วๆ นี้',
    },
  },
  creatorMyPage,
  mypage,
  board: {
    sideTitle: 'กระดานข่าว',
    title: {
      notice: 'ประกาศ',
      event: 'กิจกรรม',
      /* news: 'ข่าวสาร', */
      faq: 'คำถามที่พบบ่อย',
      terms: 'บริการและนโยบาย',
    },
    label: {
      notice: 'ประกาศ',
      list: 'รายการ',
      prev: 'ก่อนหน้า',
      next: 'ถัดไป',
      search: 'ค้นหา',
      total: 'ทั้งหมด',
    },
  },
  retire: {
    title: 'คำแนะนำการปิดบัญชี',
    password: {
      title: 'รหัสผ่าน',
      content: {
        text: 'คุณต้องอ่านและยอมรับคำแนะนำก่อนออกจากระบบ',
        warning: 'คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ?',
      },
      validate:
        'ยืนยันรหัสผ่านของคุณและเข้าสู่ระบบอีกครั้ง (การเข้าสู่ระบบจะถูกบล็อกหลังจากผิดพลาดมากกว่า 5 ครั้ง)',
    },
    guide: {
      type1: {
        subTitle: 'กรุณายืนยันก่อนถอนการเป็นสมาชิกของคุณ!',
        list: [
          '"การละทิ้งความรับผิดชอบที่เกิดขึ้นหลังจากการออกจากระบบเป็นความรับผิดชอบของผู้ใช้ทั้งหมด หากคุณสมัครบริการ LM Wallet ซึ่งเป็นบริการที่เชื่อมโยงกันเมื่อคุณออกจาก I LIKE LM คุณสามารถสมัครใหม่ได้ทันทีโดยใช้หมายเลขประจำตัวเดิมที่คุณใช้ก่อนหน้านี้ แต่ประวัติการใช้งานก่อนหน้านี้จะไม่สามารถกู้คืนได้ กรุณาตรวจสอบที่อยู่ก่อนใช้งาน เมื่อออกจาก I LIKE LM และถ้าคุณได้ออกจาก LM Wallet ด้วยแล้ว คุณไม่สามารถสมัครใหม่ด้วยหมายเลขประจำตัวเดิมภายใน 30 วันหลังจากการออกจาก I LIKE LM หลังจาก 30 วัน หากคุณสมัครใหม่ด้วยหมายเลขประจำตัวเดิมอีกครั้ง ประวัติการใช้งาน (รวมถึงสินทรัพย์ดิจิทัล) จะไม่สามารถกู้คืนได้ กรุณาตรวจสอบที่อยู่ก่อนใช้งาน',
        ],
        text: 'การลบบัญชีของคุณยังหมายความว่าข้อมูลส่วนตัวและประวัติการทำธุรกรรมทั้งหมดของคุณจะถูกลบออก',
        warning: 'คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ?',
        agree: 'ฉันตกลง',
        complete: 'บัญชีของคุณได้ถูกลบแล้ว',
      },
      type2: {
        content: {
          text1:
            'กระบวนการลบถูกปิดใช้งานในขณะนี้เนื่องจากคุณมียอดเงินและสินทรัพย์ดิจิทัลที่เหลืออยู่',
          text2:
            'หากคุณต้องการดำเนินการลบบัญชีของคุณ กรุณาติดต่อทีมสนับสนุนลูกค้าของเราที่  <em class="text bule">cs&#64;likelm.com</em>',
        },
        button: {
          inquire: 'ติดต่อเรา',
          home: 'หน้าแรก',
        },
        modal: {
          title: 'ปิดบัญชี',
        },
      },
    },
    inquire: {
      text: {
        title: 'การสอบถามของคุณ',
        validate: 'กรุณาใส่การสอบถามของคุณที่นี่',
        placeholder: 'กรุณาใส่การสอบถามของคุณที่นี่',
      },
      email: {
        title: 'ที่อยู่อีเมล',
        validate: {
          text: 'คำตอบจะถูกส่งไปที่อีเมลนี้ ดังนั้นกรุณาใส่ให้ถูกต้อง',
          error: 'คำตอบจะถูกส่งไปที่อีเมลนี้',
        },
      },
      term: {
        title: 'การเก็บรวบรวมและการใช้ข้อมูลส่วนบุคคล',
        content: {
          text1:
            'บริษัท playNomm จำกัด ดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ดังต่อไปนี้เพื่อจัดการกับการสอบถามจากลูกค้า',
          text2:
            'สำหรับข้อมูลเพิ่มเติม กรุณาอ้างอิงนโยบายความเป็นส่วนตัวของ I LIKE LM',
        },
        list: [
          {
            title: 'รายการที่เก็บรวบรวม',
            detail: 'ที่อยู่อีเมล',
          },
          {
            title: 'วัตถุประสงค์',
            detail: 'ตอบกลับและจัดการกับการสอบถามของลูกค้า',
          },
          {
            title: 'ระยะเวลาการเก็บรักษา',
            detail: '2 ปี นับตั้งแต่การสอบถาม',
          },
        ],
        checkbox: "ฉันยอมรับ 'นโยบายการเก็บรวบรวมและการใช้ข้อมูลส่วนบุคคล'",
      },
      button: 'ส่ง',
      modal: 'การสอบถามของคุณได้รับแล้ว เราจะติดต่อกลับไปโดยเร็วที่สุด',
    },
  },
  dialog,
  sort: {
    'for-you': 'สำหรับคุณ',
    newest: 'ใหม่สุด',
    'follow-newest': 'กำลังติดตาม',
  } as { [Property in PostsOrderBy]: string },
  copyToClipboard: 'ลิงก์ได้ถูกคัดลอกไปยังคลิปบอร์ด',
  recentSearches: 'การค้นหาล่าสุด',
  seeAllResult: 'ดูผลทั้งหมด',
  searchResultItemType: {
    user: 'ผู้ใช้',
    // TODO: 앱 버전 릴리즈를 위해 기능 숨김
    // premium: subscribe.premiumBrand,
    post: 'โพสต์',
    comment: 'ความคิดเห็น',
    hash: 'แฮชแท็ก',
  } as { [Property in SearchAllItemType]: string },
  writeComment: 'เขียนความคิดเห็น',
  doComment: 'ความคิดเห็น',
  editComment: 'แก้ไข',
  cancelComment: 'ยกเลิก',
  doReply: 'ตอบกลับ',
  editReply: 'แก้ไข',
  cancelReply: 'ยกเลิก',
  foldComment: 'พับความคิดเห็น',
  commentsCount: '{count} ความคิดเห็น',
  commentsAndRepliesSort: {
    newest: 'ใหม่สุด',
    oldest: 'เก่าที่สุด',
    hot: 'ร้อนแรงที่สุด',
  } as { [Property in CommentsAndRepliesOrderBy]: string },
  recommendOthersCount: '{count} รายการอื่นๆ',
  commentAndReplyPrivateAction: {
    // hide: 'ซ่อน',
    report: 'รายงานความคิดเห็น',
    delete: 'ลบ',
    edit: 'แก้ไข',
  } as { [Property in CommentAndReplyPrivateActionType]: string },
  videoAddPanel: {
    videoTypeLabel: 'วิดีโอ',
    urlLabel: 'ลิงก์',
    urlPlaceholder: 'ใส่ลิงก์',
    addBtn: 'ยืนยัน',
    descriptions: {
      youtube: [
        'https://www.youtube.com/watch?v=xxxxxxxxxxx',
        'https://www.youtu.be/xxxxxxxxxxx',
        'https://www.youtube.com/embed/xxxxxxxxxxx',
        'กรุณาใส่ลิงก์ในรูปแบบของที่อยู่ตัวอย่างข้างต้น',
      ],
      youtubeShorts: [
        'https://www.youtube.com/shorts/xxxxxxxxxxx',
        'https://www.youtube.com/embed/xxxxxxxxxxx',
        'กรุณาใส่ลิงก์ในรูปแบบของที่อยู่ตัวอย่างข้างต้น',
      ],
      vimeo: [
        'https://vimeo.com/xxxxxxxxx',
        'https://player.vimeo.com/video/xxxxxxxxx',
        'กรุณาใส่ลิงก์ในรูปแบบของที่อยู่ตัวอย่างข้างต้น',
      ],
    },
  },
  colorPickerPanel: {
    textColorLabel: 'สีข้อความ',
    highlightColorLabel: 'เน้น',
  },
  linkAddPanel: {
    label: 'ลิงก์',
    placeholder: 'ใส่ลิงก์',
    addBtn: 'ยืนยัน',
  },
  corporationInfo,
  kyc,
  profileControl,
  events: {
    recommender: {
      title: 'มาแล้ว กิจกรรมรอบที่ 2!',
      recommenders: {
        empty: 'ยังไม่มีข้อมูลการจัดอันดับ',
        error:
          'ไม่สามารถดึงข้อมูลการจัดอันดับได้ กรุณาตรวจสอบอีกครั้งในภายหลัง',
      },
      rank: 'อันดับ',
      email: 'ที่อยู่อีเมล',
      recommendCount: 'การแนะนำ',
      recommendCountUnit: '',
      myRank: 'อันดับของฉัน',
      myRankStatus: {
        error:
          'ไม่สามารถยืนยันข้อมูลการจัดอันดับได้ กรุณาลองอีกครั้งในอีกไม่กี่นาที',
      },
      currentTopRank: 'อันดับ',
      rankCondition:
        'ในกรณีที่มีการเสมอกัน การจัดอันดับจะถูกกำหนดโดยการสะท้อนคะแนนจากจำนวนโพสต์และความคิดเห็น',
      guide: 'คำแนะนำ',
      guideDescription:
        'ผู้ชนะของกิจกรรมจะได้รับการแจ้งทางอีเมล และกรุณาตรวจสอบประกาศภายหลัง',
    },
  },
  accountSetting: {
    introduction: {
      placeholder: 'เขียนแนะนำ',
    },
  },
  bottomSheet: {
    title: 'เข้าถึงได้สะดวกยิ่งขึ้นด้วยการคลิก!',
    button: 'ดู I LIKE LM ในแอป',
  },
  units: {
    days: 'วัน',
  },
  required: 'จำเป็น',
  optional: 'ไม่จำเป็น',
  postReportNotification:
    '*หากการรายงานถูกตัดสินว่าเป็นการรายงานเท็จที่มีเจตนาร้าย จะมีการหัก 30 คะแนน และอาจมีการลงโทษกิจกรรมหากมีการรายงานเท็จสะสมมากขึ้น',
  applyCreator,
  commonError,
  referral,
  share,
  userSummery,
  home,
  navigations,
  creatorMainProfile,
  w3cf,
  subscribe,
  pay,
  accountPanel,
  notificationsPanel,
  fund,
  timeLimitPopup,
  sponsors,
  auction,
  terminateApp,
  requestAppPermission,
  pullToRefresh,
  notificationPermission,
  sendbird,
  notificationSetting,
  blockMessage,
  homeActions,
  dailyCheck,
  blockAccess,
  serviceBenefits,
  rewardPopup,
  mainTop,
  aiContents,
} as { [key in string]: any }
