/**
 * Represents the block access information.
 */
export const blockAccess = {
  modal: {
    title: 'ประกาศการระงับ',
    contents: {
      activity: 'ผู้ใช้ที่ถูกระงับไม่สามารถใช้บริการได้',
      signin:
        'ขณะนี้บัญชีของคุณถูกระงับอยู่ ในช่วงระยะเวลาการระงับนี้ กิจกรรมต่างๆ เช่น การเขียนโพสต์ การแสดงความคิดเห็น การกดถูกใจ ฯลฯ จะถูกจำกัด<br /><br />เมื่อการระงับสิ้นสุดลง คุณจะสามารถกลับมาใช้บริการของชุมชนได้ตามปกติ<br /><br />ระยะเวลาการระงับ: {period}',
    },
  },
  eternalBlocked: 'การระงับถาวร',
}
