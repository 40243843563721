/**
 * 앱 종료 안내
 */
export const terminateApp = {
  dialog: {
    contents: 'คุณแน่ใจหรือไม่ว่าต้องการออกจาก {appName}',
    actions: {
      negative: 'ยกเลิก',
      positive: 'ออก',
    },
  },
}
