import { default as apply1givWzOfo5Meta } from "/codebuild/output/src199376353/src/pages/apply-creator/apply.vue?macro=true";
import { default as indexy7mFtkNyIIMeta } from "/codebuild/output/src199376353/src/pages/apply-creator/index.vue?macro=true";
import { default as additionalIfPIiD1fonMeta } from "/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/additional.vue?macro=true";
import { default as completecMuteL8MNZMeta } from "/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/complete.vue?macro=true";
import { default as indexhJTfX5YMjCMeta } from "/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/index.vue?macro=true";
import { default as indexNBzPekXtv1Meta } from "/codebuild/output/src199376353/src/pages/apply-rent/index.vue?macro=true";
import { default as formVtxTSOfkVQMeta } from "/codebuild/output/src199376353/src/pages/apply-super-moon/form.vue?macro=true";
import { default as indexNqOkk40YOvMeta } from "/codebuild/output/src199376353/src/pages/apply-super-moon/index.vue?macro=true";
import { default as _91id_93bJdCNUjEbeMeta } from "/codebuild/output/src199376353/src/pages/board/event/[id].vue?macro=true";
import { default as indexo65g2tesznMeta } from "/codebuild/output/src199376353/src/pages/board/event/index.vue?macro=true";
import { default as _91id_936lW0Bk9XRYMeta } from "/codebuild/output/src199376353/src/pages/board/faq/[id].vue?macro=true";
import { default as indexNI7XKHIhLKMeta } from "/codebuild/output/src199376353/src/pages/board/faq/index.vue?macro=true";
import { default as _91id_93eZM7nbOu3rMeta } from "/codebuild/output/src199376353/src/pages/board/news/[id].vue?macro=true";
import { default as indexStHr8PrcEHMeta } from "/codebuild/output/src199376353/src/pages/board/news/index.vue?macro=true";
import { default as _91id_93GUmwtnVvo5Meta } from "/codebuild/output/src199376353/src/pages/board/notice/[id].vue?macro=true";
import { default as indexaLHtDRHKCiMeta } from "/codebuild/output/src199376353/src/pages/board/notice/index.vue?macro=true";
import { default as paytrVGy4cYHqMeta } from "/codebuild/output/src199376353/src/pages/callback/pay.vue?macro=true";
import { default as signinmxFcLXSIB2Meta } from "/codebuild/output/src199376353/src/pages/callback/signin.vue?macro=true";
import { default as signupSRWb3F799tMeta } from "/codebuild/output/src199376353/src/pages/callback/signup.vue?macro=true";
import { default as _91postId_93dCP1zgd438Meta } from "/codebuild/output/src199376353/src/pages/creator/[id]/activities/[postId].vue?macro=true";
import { default as activitiesx3msWgv1H3Meta } from "/codebuild/output/src199376353/src/pages/creator/[id]/activities.vue?macro=true";
import { default as _91collectionId_93HqqxcMUtR0Meta } from "/codebuild/output/src199376353/src/pages/creator/[id]/collections/[collectionId].vue?macro=true";
import { default as collectionsgaeeRE8f0VMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/collections.vue?macro=true";
import { default as donationbG7d52MNiAMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/donation.vue?macro=true";
import { default as guestbooknLqj9NzzVjMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/guestbook.vue?macro=true";
import { default as _91postId_93hrgGn0bGfzMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/index/[postId].vue?macro=true";
import { default as indexsiZ4FTyMtoMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/index.vue?macro=true";
import { default as _91noticeId_93MhTrerW6mBMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/notice/[noticeId].vue?macro=true";
import { default as noticeI954k2azoeMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/notice.vue?macro=true";
import { default as _91postId_93nWNEs6DsgCMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/nova-plus/[postId].vue?macro=true";
import { default as indexes1ow4nBywMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/nova-plus/index.vue?macro=true";
import { default as _91postId_93CTYnHLk6bQMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/posts/[postId].vue?macro=true";
import { default as indexIoPkoFn6JBMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/posts/index.vue?macro=true";
import { default as rewardvEgAY0OUpgMeta } from "/codebuild/output/src199376353/src/pages/creator/[id]/reward.vue?macro=true";
import { default as statisticsDsx6McxFZ1Meta } from "/codebuild/output/src199376353/src/pages/creator/[id]/statistics.vue?macro=true";
import { default as sponsors7Znj738vO8Meta } from "/codebuild/output/src199376353/src/pages/creator/sponsors.vue?macro=true";
import { default as _91creatorUserSn_935wg7GglezKMeta } from "/codebuild/output/src199376353/src/pages/creator/subscribe/cancel/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93auW9uB5uyZMeta } from "/codebuild/output/src199376353/src/pages/creator/subscribe/opened/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93uiXCk2J94tMeta } from "/codebuild/output/src199376353/src/pages/creator/subscribe/refund/[creatorUserSn].vue?macro=true";
import { default as _91creatorUserSn_93p0K7sfb1PQMeta } from "/codebuild/output/src199376353/src/pages/creator/subscribe/support/[creatorUserSn].vue?macro=true";
import { default as allyyVL8vz2T5Meta } from "/codebuild/output/src199376353/src/pages/creators/all.vue?macro=true";
import { default as myDKyJy3OpCZMeta } from "/codebuild/output/src199376353/src/pages/creators/my.vue?macro=true";
import { default as indexi2BNtkxB2pMeta } from "/codebuild/output/src199376353/src/pages/daily-check/index.vue?macro=true";
import { default as _91id_93mnU19jHhfjMeta } from "/codebuild/output/src199376353/src/pages/feed/[id].vue?macro=true";
import { default as indexBq09F6MNr6Meta } from "/codebuild/output/src199376353/src/pages/feed/index.vue?macro=true";
import { default as indexvFmzAmiyH3Meta } from "/codebuild/output/src199376353/src/pages/fund/gaudi-fund/index.vue?macro=true";
import { default as gaudioDK6LaGuyuMeta } from "/codebuild/output/src199376353/src/pages/fund/gaudi.vue?macro=true";
import { default as _91id_93k8yKsRxtXnMeta } from "/codebuild/output/src199376353/src/pages/fund/history/[id].vue?macro=true";
import { default as historyOXrwKdHj9bMeta } from "/codebuild/output/src199376353/src/pages/fund/history.vue?macro=true";
import { default as _91id_93yBauNlTM4VMeta } from "/codebuild/output/src199376353/src/pages/fund/index/[id].vue?macro=true";
import { default as indexoJ2auzdCVrMeta } from "/codebuild/output/src199376353/src/pages/fund/index.vue?macro=true";
import { default as _91id_93mDfXiRHR1QMeta } from "/codebuild/output/src199376353/src/pages/fund/notice/[id].vue?macro=true";
import { default as indexi8Y7TVpxJAMeta } from "/codebuild/output/src199376353/src/pages/fund/notice/index.vue?macro=true";
import { default as _91id_93Urvpz2mJlaMeta } from "/codebuild/output/src199376353/src/pages/hashtag/[id].vue?macro=true";
import { default as indexFcgDJVSKMLMeta } from "/codebuild/output/src199376353/src/pages/hashtag/index.vue?macro=true";
import { default as indexmhq0prnDjzMeta } from "/codebuild/output/src199376353/src/pages/index.vue?macro=true";
import { default as _91id_93UXJbsVBsNBMeta } from "/codebuild/output/src199376353/src/pages/nova-plus/[id].vue?macro=true";
import { default as indexGybj5GY163Meta } from "/codebuild/output/src199376353/src/pages/nova-plus/index.vue?macro=true";
import { default as indexKdMvTy2y8BMeta } from "/codebuild/output/src199376353/src/pages/oulim/index.vue?macro=true";
import { default as _91id_937z1LtISisfMeta } from "/codebuild/output/src199376353/src/pages/post/[id].vue?macro=true";
import { default as _64_91id_93YdNuZ5wXmkMeta } from "/codebuild/output/src199376353/src/pages/referral/@[id].vue?macro=true";
import { default as _91id_93za9JXtr6LiMeta } from "/codebuild/output/src199376353/src/pages/search/detail/comment/[id].vue?macro=true";
import { default as indexKP8jus1Xb5Meta } from "/codebuild/output/src199376353/src/pages/search/detail/comment/index.vue?macro=true";
import { default as hashtagZ6sGvBT8gnMeta } from "/codebuild/output/src199376353/src/pages/search/detail/hashtag.vue?macro=true";
import { default as _91id_93L7GQvjPSbPMeta } from "/codebuild/output/src199376353/src/pages/search/detail/index/[id].vue?macro=true";
import { default as index93WBsgt3c1Meta } from "/codebuild/output/src199376353/src/pages/search/detail/index/index.vue?macro=true";
import { default as _91id_93jBvhlueoSzMeta } from "/codebuild/output/src199376353/src/pages/search/detail/nova-plus/[id].vue?macro=true";
import { default as indexzxWARHhIPuMeta } from "/codebuild/output/src199376353/src/pages/search/detail/nova-plus/index.vue?macro=true";
import { default as userGbLkuSom6WMeta } from "/codebuild/output/src199376353/src/pages/search/detail/user.vue?macro=true";
import { default as _91id_93QVPBvHmsgnMeta } from "/codebuild/output/src199376353/src/pages/search/index/[id].vue?macro=true";
import { default as indexRDaj43lqBCMeta } from "/codebuild/output/src199376353/src/pages/search/index.vue?macro=true";
import { default as _91id_93CkBNSlY1CUMeta } from "/codebuild/output/src199376353/src/pages/terms/[id].vue?macro=true";
import { default as _91postId_9301cYC0WBMYMeta } from "/codebuild/output/src199376353/src/pages/user/[id]/activities/[postId].vue?macro=true";
import { default as activitiesS4DInruvHjMeta } from "/codebuild/output/src199376353/src/pages/user/[id]/activities.vue?macro=true";
import { default as _91collectionId_932xQofMxfxTMeta } from "/codebuild/output/src199376353/src/pages/user/[id]/collections/[collectionId].vue?macro=true";
import { default as collectionsmzghoSE7FEMeta } from "/codebuild/output/src199376353/src/pages/user/[id]/collections.vue?macro=true";
import { default as donation39VDwXx6J5Meta } from "/codebuild/output/src199376353/src/pages/user/[id]/donation.vue?macro=true";
import { default as _91postId_93jlFfF2aM3YMeta } from "/codebuild/output/src199376353/src/pages/user/[id]/index/[postId].vue?macro=true";
import { default as indexfwXXaannkLMeta } from "/codebuild/output/src199376353/src/pages/user/[id]/index/index.vue?macro=true";
import { default as rewardR0WDLn4dbOMeta } from "/codebuild/output/src199376353/src/pages/user/[id]/reward.vue?macro=true";
import { default as indexMGulY47O8KMeta } from "/codebuild/output/src199376353/src/pages/user/sponsors/index.vue?macro=true";
import { default as _91id_93XLad2dd10UMeta } from "/codebuild/output/src199376353/src/pages/w3cf/detail/[id].vue?macro=true";
import { default as indexnlGujUAg04Meta } from "/codebuild/output/src199376353/src/pages/w3cf/index.vue?macro=true";
export default [
  {
    name: "apply-creator-apply___ko",
    path: "/apply-creator/apply",
    meta: apply1givWzOfo5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-creator/apply.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator-apply___en",
    path: "/en/apply-creator/apply",
    meta: apply1givWzOfo5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-creator/apply.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator-apply___th",
    path: "/th/apply-creator/apply",
    meta: apply1givWzOfo5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-creator/apply.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator-apply___es",
    path: "/es/apply-creator/apply",
    meta: apply1givWzOfo5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-creator/apply.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator___ko",
    path: "/apply-creator",
    meta: indexy7mFtkNyIIMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-creator/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator___en",
    path: "/en/apply-creator",
    meta: indexy7mFtkNyIIMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-creator/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator___th",
    path: "/th/apply-creator",
    meta: indexy7mFtkNyIIMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-creator/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-creator___es",
    path: "/es/apply-creator",
    meta: indexy7mFtkNyIIMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-creator/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-additional___ko",
    path: "/apply-rent/facility-:id()/additional",
    meta: additionalIfPIiD1fonMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/additional.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-additional___en",
    path: "/en/apply-rent/facility-:id()/additional",
    meta: additionalIfPIiD1fonMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/additional.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-additional___th",
    path: "/th/apply-rent/facility-:id()/additional",
    meta: additionalIfPIiD1fonMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/additional.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-additional___es",
    path: "/es/apply-rent/facility-:id()/additional",
    meta: additionalIfPIiD1fonMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/additional.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-complete___ko",
    path: "/apply-rent/facility-:id()/complete",
    meta: completecMuteL8MNZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/complete.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-complete___en",
    path: "/en/apply-rent/facility-:id()/complete",
    meta: completecMuteL8MNZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/complete.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-complete___th",
    path: "/th/apply-rent/facility-:id()/complete",
    meta: completecMuteL8MNZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/complete.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id-complete___es",
    path: "/es/apply-rent/facility-:id()/complete",
    meta: completecMuteL8MNZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/complete.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id___ko",
    path: "/apply-rent/facility-:id()",
    meta: indexhJTfX5YMjCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id___en",
    path: "/en/apply-rent/facility-:id()",
    meta: indexhJTfX5YMjCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id___th",
    path: "/th/apply-rent/facility-:id()",
    meta: indexhJTfX5YMjCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent-facility-id___es",
    path: "/es/apply-rent/facility-:id()",
    meta: indexhJTfX5YMjCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/facility-[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent___ko",
    path: "/apply-rent",
    meta: indexNBzPekXtv1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent___en",
    path: "/en/apply-rent",
    meta: indexNBzPekXtv1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent___th",
    path: "/th/apply-rent",
    meta: indexNBzPekXtv1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-rent___es",
    path: "/es/apply-rent",
    meta: indexNBzPekXtv1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-rent/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon-form___ko",
    path: "/apply-super-moon/form",
    meta: formVtxTSOfkVQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-super-moon/form.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon-form___en",
    path: "/en/apply-super-moon/form",
    meta: formVtxTSOfkVQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-super-moon/form.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon-form___th",
    path: "/th/apply-super-moon/form",
    meta: formVtxTSOfkVQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-super-moon/form.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon-form___es",
    path: "/es/apply-super-moon/form",
    meta: formVtxTSOfkVQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-super-moon/form.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon___ko",
    path: "/apply-super-moon",
    meta: indexNqOkk40YOvMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-super-moon/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon___en",
    path: "/en/apply-super-moon",
    meta: indexNqOkk40YOvMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-super-moon/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon___th",
    path: "/th/apply-super-moon",
    meta: indexNqOkk40YOvMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-super-moon/index.vue").then(m => m.default || m)
  },
  {
    name: "apply-super-moon___es",
    path: "/es/apply-super-moon",
    meta: indexNqOkk40YOvMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/apply-super-moon/index.vue").then(m => m.default || m)
  },
  {
    name: "board-event-id___ko",
    path: "/board/event/:id()",
    meta: _91id_93bJdCNUjEbeMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/event/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-event-id___en",
    path: "/en/board/event/:id()",
    meta: _91id_93bJdCNUjEbeMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/event/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-event-id___th",
    path: "/th/board/event/:id()",
    meta: _91id_93bJdCNUjEbeMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/event/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-event-id___es",
    path: "/es/board/event/:id()",
    meta: _91id_93bJdCNUjEbeMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/event/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-event___ko",
    path: "/board/event",
    meta: indexo65g2tesznMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/event/index.vue").then(m => m.default || m)
  },
  {
    name: "board-event___en",
    path: "/en/board/event",
    meta: indexo65g2tesznMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/event/index.vue").then(m => m.default || m)
  },
  {
    name: "board-event___th",
    path: "/th/board/event",
    meta: indexo65g2tesznMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/event/index.vue").then(m => m.default || m)
  },
  {
    name: "board-event___es",
    path: "/es/board/event",
    meta: indexo65g2tesznMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/event/index.vue").then(m => m.default || m)
  },
  {
    name: "board-faq-id___ko",
    path: "/board/faq/:id()",
    meta: _91id_936lW0Bk9XRYMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-faq-id___en",
    path: "/en/board/faq/:id()",
    meta: _91id_936lW0Bk9XRYMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-faq-id___th",
    path: "/th/board/faq/:id()",
    meta: _91id_936lW0Bk9XRYMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-faq-id___es",
    path: "/es/board/faq/:id()",
    meta: _91id_936lW0Bk9XRYMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-faq___ko",
    path: "/board/faq",
    meta: indexNI7XKHIhLKMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "board-faq___en",
    path: "/en/board/faq",
    meta: indexNI7XKHIhLKMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "board-faq___th",
    path: "/th/board/faq",
    meta: indexNI7XKHIhLKMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "board-faq___es",
    path: "/es/board/faq",
    meta: indexNI7XKHIhLKMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "board-news-id___ko",
    path: "/board/news/:id()",
    meta: _91id_93eZM7nbOu3rMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-news-id___en",
    path: "/en/board/news/:id()",
    meta: _91id_93eZM7nbOu3rMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-news-id___th",
    path: "/th/board/news/:id()",
    meta: _91id_93eZM7nbOu3rMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-news-id___es",
    path: "/es/board/news/:id()",
    meta: _91id_93eZM7nbOu3rMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-news___ko",
    path: "/board/news",
    meta: indexStHr8PrcEHMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/news/index.vue").then(m => m.default || m)
  },
  {
    name: "board-news___en",
    path: "/en/board/news",
    meta: indexStHr8PrcEHMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/news/index.vue").then(m => m.default || m)
  },
  {
    name: "board-news___th",
    path: "/th/board/news",
    meta: indexStHr8PrcEHMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/news/index.vue").then(m => m.default || m)
  },
  {
    name: "board-news___es",
    path: "/es/board/news",
    meta: indexStHr8PrcEHMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/news/index.vue").then(m => m.default || m)
  },
  {
    name: "board-notice-id___ko",
    path: "/board/notice/:id()",
    meta: _91id_93GUmwtnVvo5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-notice-id___en",
    path: "/en/board/notice/:id()",
    meta: _91id_93GUmwtnVvo5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-notice-id___th",
    path: "/th/board/notice/:id()",
    meta: _91id_93GUmwtnVvo5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-notice-id___es",
    path: "/es/board/notice/:id()",
    meta: _91id_93GUmwtnVvo5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "board-notice___ko",
    path: "/board/notice",
    meta: indexaLHtDRHKCiMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "board-notice___en",
    path: "/en/board/notice",
    meta: indexaLHtDRHKCiMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "board-notice___th",
    path: "/th/board/notice",
    meta: indexaLHtDRHKCiMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "board-notice___es",
    path: "/es/board/notice",
    meta: indexaLHtDRHKCiMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/board/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "callback-pay___ko",
    path: "/callback/pay",
    meta: paytrVGy4cYHqMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/pay.vue").then(m => m.default || m)
  },
  {
    name: "callback-pay___en",
    path: "/en/callback/pay",
    meta: paytrVGy4cYHqMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/pay.vue").then(m => m.default || m)
  },
  {
    name: "callback-pay___th",
    path: "/th/callback/pay",
    meta: paytrVGy4cYHqMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/pay.vue").then(m => m.default || m)
  },
  {
    name: "callback-pay___es",
    path: "/es/callback/pay",
    meta: paytrVGy4cYHqMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/pay.vue").then(m => m.default || m)
  },
  {
    name: "callback-signin___ko",
    path: "/callback/signin",
    meta: signinmxFcLXSIB2Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/signin.vue").then(m => m.default || m)
  },
  {
    name: "callback-signin___en",
    path: "/en/callback/signin",
    meta: signinmxFcLXSIB2Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/signin.vue").then(m => m.default || m)
  },
  {
    name: "callback-signin___th",
    path: "/th/callback/signin",
    meta: signinmxFcLXSIB2Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/signin.vue").then(m => m.default || m)
  },
  {
    name: "callback-signin___es",
    path: "/es/callback/signin",
    meta: signinmxFcLXSIB2Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/signin.vue").then(m => m.default || m)
  },
  {
    name: "callback-signup___ko",
    path: "/callback/signup",
    meta: signupSRWb3F799tMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/signup.vue").then(m => m.default || m)
  },
  {
    name: "callback-signup___en",
    path: "/en/callback/signup",
    meta: signupSRWb3F799tMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/signup.vue").then(m => m.default || m)
  },
  {
    name: "callback-signup___th",
    path: "/th/callback/signup",
    meta: signupSRWb3F799tMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/signup.vue").then(m => m.default || m)
  },
  {
    name: "callback-signup___es",
    path: "/es/callback/signup",
    meta: signupSRWb3F799tMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/callback/signup.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-activities___ko",
    path: "/creator/:id()/activities",
    meta: activitiesx3msWgv1H3Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_93dCP1zgd438Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-activities___en",
    path: "/en/creator/:id()/activities",
    meta: activitiesx3msWgv1H3Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_93dCP1zgd438Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-activities___th",
    path: "/th/creator/:id()/activities",
    meta: activitiesx3msWgv1H3Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_93dCP1zgd438Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-activities___es",
    path: "/es/creator/:id()/activities",
    meta: activitiesx3msWgv1H3Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-activities-postId___es",
    path: ":postId()",
    meta: _91postId_93dCP1zgd438Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-collections___ko",
    path: "/creator/:id()/collections",
    meta: collectionsgaeeRE8f0VMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_93HqqxcMUtR0Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-collections___en",
    path: "/en/creator/:id()/collections",
    meta: collectionsgaeeRE8f0VMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_93HqqxcMUtR0Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-collections___th",
    path: "/th/creator/:id()/collections",
    meta: collectionsgaeeRE8f0VMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_93HqqxcMUtR0Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-collections___es",
    path: "/es/creator/:id()/collections",
    meta: collectionsgaeeRE8f0VMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-collections-collectionId___es",
    path: ":collectionId()",
    meta: _91collectionId_93HqqxcMUtR0Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-donation___ko",
    path: "/creator/:id()/donation",
    meta: donationbG7d52MNiAMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-donation___en",
    path: "/en/creator/:id()/donation",
    meta: donationbG7d52MNiAMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-donation___th",
    path: "/th/creator/:id()/donation",
    meta: donationbG7d52MNiAMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-donation___es",
    path: "/es/creator/:id()/donation",
    meta: donationbG7d52MNiAMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-guestbook___ko",
    path: "/creator/:id()/guestbook",
    meta: guestbooknLqj9NzzVjMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/guestbook.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-guestbook___en",
    path: "/en/creator/:id()/guestbook",
    meta: guestbooknLqj9NzzVjMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/guestbook.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-guestbook___th",
    path: "/th/creator/:id()/guestbook",
    meta: guestbooknLqj9NzzVjMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/guestbook.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-guestbook___es",
    path: "/es/creator/:id()/guestbook",
    meta: guestbooknLqj9NzzVjMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/guestbook.vue").then(m => m.default || m)
  },
  {
    name: "creator-id___ko",
    path: "/creator/:id()",
    meta: indexsiZ4FTyMtoMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-index-postId___ko",
    path: ":postId()",
    meta: _91postId_93hrgGn0bGfzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/index/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id___en",
    path: "/en/creator/:id()",
    meta: indexsiZ4FTyMtoMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-index-postId___en",
    path: ":postId()",
    meta: _91postId_93hrgGn0bGfzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/index/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id___th",
    path: "/th/creator/:id()",
    meta: indexsiZ4FTyMtoMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-index-postId___th",
    path: ":postId()",
    meta: _91postId_93hrgGn0bGfzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/index/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id___es",
    path: "/es/creator/:id()",
    meta: indexsiZ4FTyMtoMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-index-postId___es",
    path: ":postId()",
    meta: _91postId_93hrgGn0bGfzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/index/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-notice___ko",
    path: "/creator/:id()/notice",
    meta: noticeI954k2azoeMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/notice.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-notice-noticeId___ko",
    path: ":noticeId()",
    meta: _91noticeId_93MhTrerW6mBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/notice/[noticeId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-notice___en",
    path: "/en/creator/:id()/notice",
    meta: noticeI954k2azoeMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/notice.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-notice-noticeId___en",
    path: ":noticeId()",
    meta: _91noticeId_93MhTrerW6mBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/notice/[noticeId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-notice___th",
    path: "/th/creator/:id()/notice",
    meta: noticeI954k2azoeMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/notice.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-notice-noticeId___th",
    path: ":noticeId()",
    meta: _91noticeId_93MhTrerW6mBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/notice/[noticeId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-notice___es",
    path: "/es/creator/:id()/notice",
    meta: noticeI954k2azoeMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/notice.vue").then(m => m.default || m),
    children: [
  {
    name: "creator-id-notice-noticeId___es",
    path: ":noticeId()",
    meta: _91noticeId_93MhTrerW6mBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/notice/[noticeId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "creator-id-nova-plus-postId___ko",
    path: "/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93nWNEs6DsgCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/nova-plus/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus-postId___en",
    path: "/en/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93nWNEs6DsgCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/nova-plus/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus-postId___th",
    path: "/th/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93nWNEs6DsgCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/nova-plus/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus-postId___es",
    path: "/es/creator/:id()/nova-plus/:postId()",
    meta: _91postId_93nWNEs6DsgCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/nova-plus/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus___ko",
    path: "/creator/:id()/nova-plus",
    meta: indexes1ow4nBywMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus___en",
    path: "/en/creator/:id()/nova-plus",
    meta: indexes1ow4nBywMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus___th",
    path: "/th/creator/:id()/nova-plus",
    meta: indexes1ow4nBywMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-nova-plus___es",
    path: "/es/creator/:id()/nova-plus",
    meta: indexes1ow4nBywMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts-postId___ko",
    path: "/creator/:id()/posts/:postId()",
    meta: _91postId_93CTYnHLk6bQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/posts/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts-postId___en",
    path: "/en/creator/:id()/posts/:postId()",
    meta: _91postId_93CTYnHLk6bQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/posts/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts-postId___th",
    path: "/th/creator/:id()/posts/:postId()",
    meta: _91postId_93CTYnHLk6bQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/posts/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts-postId___es",
    path: "/es/creator/:id()/posts/:postId()",
    meta: _91postId_93CTYnHLk6bQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/posts/[postId].vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts___ko",
    path: "/creator/:id()/posts",
    meta: indexIoPkoFn6JBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts___en",
    path: "/en/creator/:id()/posts",
    meta: indexIoPkoFn6JBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts___th",
    path: "/th/creator/:id()/posts",
    meta: indexIoPkoFn6JBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-posts___es",
    path: "/es/creator/:id()/posts",
    meta: indexIoPkoFn6JBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-reward___ko",
    path: "/creator/:id()/reward",
    meta: rewardvEgAY0OUpgMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-reward___en",
    path: "/en/creator/:id()/reward",
    meta: rewardvEgAY0OUpgMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-reward___th",
    path: "/th/creator/:id()/reward",
    meta: rewardvEgAY0OUpgMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-reward___es",
    path: "/es/creator/:id()/reward",
    meta: rewardvEgAY0OUpgMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-statistics___ko",
    path: "/creator/:id()/statistics",
    meta: statisticsDsx6McxFZ1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/statistics.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-statistics___en",
    path: "/en/creator/:id()/statistics",
    meta: statisticsDsx6McxFZ1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/statistics.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-statistics___th",
    path: "/th/creator/:id()/statistics",
    meta: statisticsDsx6McxFZ1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/statistics.vue").then(m => m.default || m)
  },
  {
    name: "creator-id-statistics___es",
    path: "/es/creator/:id()/statistics",
    meta: statisticsDsx6McxFZ1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/[id]/statistics.vue").then(m => m.default || m)
  },
  {
    name: "creator-sponsors___ko",
    path: "/creator/sponsors",
    meta: sponsors7Znj738vO8Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "creator-sponsors___en",
    path: "/en/creator/sponsors",
    meta: sponsors7Znj738vO8Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "creator-sponsors___th",
    path: "/th/creator/sponsors",
    meta: sponsors7Znj738vO8Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "creator-sponsors___es",
    path: "/es/creator/sponsors",
    meta: sponsors7Znj738vO8Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/sponsors.vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___ko",
    path: "/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_935wg7GglezKMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/cancel/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___en",
    path: "/en/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_935wg7GglezKMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/cancel/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___th",
    path: "/th/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_935wg7GglezKMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/cancel/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-cancel-creatorUserSn___es",
    path: "/es/creator/subscribe/cancel/:creatorUserSn()",
    meta: _91creatorUserSn_935wg7GglezKMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/cancel/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___ko",
    path: "/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93auW9uB5uyZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/opened/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___en",
    path: "/en/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93auW9uB5uyZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/opened/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___th",
    path: "/th/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93auW9uB5uyZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/opened/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-opened-creatorUserSn___es",
    path: "/es/creator/subscribe/opened/:creatorUserSn()",
    meta: _91creatorUserSn_93auW9uB5uyZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/opened/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___ko",
    path: "/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93uiXCk2J94tMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/refund/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___en",
    path: "/en/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93uiXCk2J94tMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/refund/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___th",
    path: "/th/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93uiXCk2J94tMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/refund/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-refund-creatorUserSn___es",
    path: "/es/creator/subscribe/refund/:creatorUserSn()",
    meta: _91creatorUserSn_93uiXCk2J94tMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/refund/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-support-creatorUserSn___ko",
    path: "/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93p0K7sfb1PQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/support/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-support-creatorUserSn___en",
    path: "/en/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93p0K7sfb1PQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/support/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-support-creatorUserSn___th",
    path: "/th/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93p0K7sfb1PQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/support/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creator-subscribe-support-creatorUserSn___es",
    path: "/es/creator/subscribe/support/:creatorUserSn()",
    meta: _91creatorUserSn_93p0K7sfb1PQMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creator/subscribe/support/[creatorUserSn].vue").then(m => m.default || m)
  },
  {
    name: "creators-all___ko",
    path: "/creators/all",
    meta: allyyVL8vz2T5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creators/all.vue").then(m => m.default || m)
  },
  {
    name: "creators-all___en",
    path: "/en/creators/all",
    meta: allyyVL8vz2T5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creators/all.vue").then(m => m.default || m)
  },
  {
    name: "creators-all___th",
    path: "/th/creators/all",
    meta: allyyVL8vz2T5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creators/all.vue").then(m => m.default || m)
  },
  {
    name: "creators-all___es",
    path: "/es/creators/all",
    meta: allyyVL8vz2T5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creators/all.vue").then(m => m.default || m)
  },
  {
    name: "creators-my___ko",
    path: "/creators/my",
    meta: myDKyJy3OpCZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creators/my.vue").then(m => m.default || m)
  },
  {
    name: "creators-my___en",
    path: "/en/creators/my",
    meta: myDKyJy3OpCZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creators/my.vue").then(m => m.default || m)
  },
  {
    name: "creators-my___th",
    path: "/th/creators/my",
    meta: myDKyJy3OpCZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creators/my.vue").then(m => m.default || m)
  },
  {
    name: "creators-my___es",
    path: "/es/creators/my",
    meta: myDKyJy3OpCZMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/creators/my.vue").then(m => m.default || m)
  },
  {
    name: "daily-check___ko",
    path: "/daily-check",
    meta: indexi2BNtkxB2pMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/daily-check/index.vue").then(m => m.default || m)
  },
  {
    name: "daily-check___en",
    path: "/en/daily-check",
    meta: indexi2BNtkxB2pMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/daily-check/index.vue").then(m => m.default || m)
  },
  {
    name: "daily-check___th",
    path: "/th/daily-check",
    meta: indexi2BNtkxB2pMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/daily-check/index.vue").then(m => m.default || m)
  },
  {
    name: "daily-check___es",
    path: "/es/daily-check",
    meta: indexi2BNtkxB2pMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/daily-check/index.vue").then(m => m.default || m)
  },
  {
    name: "feed-id___ko",
    path: "/feed/:id()",
    component: () => import("/codebuild/output/src199376353/src/pages/feed/[id].vue").then(m => m.default || m)
  },
  {
    name: "feed-id___en",
    path: "/en/feed/:id()",
    component: () => import("/codebuild/output/src199376353/src/pages/feed/[id].vue").then(m => m.default || m)
  },
  {
    name: "feed-id___th",
    path: "/th/feed/:id()",
    component: () => import("/codebuild/output/src199376353/src/pages/feed/[id].vue").then(m => m.default || m)
  },
  {
    name: "feed-id___es",
    path: "/es/feed/:id()",
    component: () => import("/codebuild/output/src199376353/src/pages/feed/[id].vue").then(m => m.default || m)
  },
  {
    name: "feed___ko",
    path: "/feed",
    component: () => import("/codebuild/output/src199376353/src/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: "feed___en",
    path: "/en/feed",
    component: () => import("/codebuild/output/src199376353/src/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: "feed___th",
    path: "/th/feed",
    component: () => import("/codebuild/output/src199376353/src/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: "feed___es",
    path: "/es/feed",
    component: () => import("/codebuild/output/src199376353/src/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi-fund___ko",
    path: "/fund/gaudi-fund",
    meta: indexvFmzAmiyH3Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/gaudi-fund/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi-fund___en",
    path: "/en/fund/gaudi-fund",
    meta: indexvFmzAmiyH3Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/gaudi-fund/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi-fund___th",
    path: "/th/fund/gaudi-fund",
    meta: indexvFmzAmiyH3Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/gaudi-fund/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi-fund___es",
    path: "/es/fund/gaudi-fund",
    meta: indexvFmzAmiyH3Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/gaudi-fund/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi___ko",
    path: "/fund/gaudi",
    meta: gaudioDK6LaGuyuMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/gaudi.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi___en",
    path: "/en/fund/gaudi",
    meta: gaudioDK6LaGuyuMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/gaudi.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi___th",
    path: "/th/fund/gaudi",
    meta: gaudioDK6LaGuyuMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/gaudi.vue").then(m => m.default || m)
  },
  {
    name: "fund-gaudi___es",
    path: "/es/fund/gaudi",
    meta: gaudioDK6LaGuyuMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/gaudi.vue").then(m => m.default || m)
  },
  {
    name: "fund-history___ko",
    path: "/fund/history",
    meta: historyOXrwKdHj9bMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/history.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-history-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src199376353/src/pages/fund/history/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund-history___en",
    path: "/en/fund/history",
    meta: historyOXrwKdHj9bMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/history.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-history-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src199376353/src/pages/fund/history/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund-history___th",
    path: "/th/fund/history",
    meta: historyOXrwKdHj9bMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/history.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-history-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src199376353/src/pages/fund/history/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund-history___es",
    path: "/es/fund/history",
    meta: historyOXrwKdHj9bMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/history.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-history-id___es",
    path: ":id()",
    component: () => import("/codebuild/output/src199376353/src/pages/fund/history/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund___ko",
    path: "/fund",
    meta: indexoJ2auzdCVrMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/index.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-index-id___ko",
    path: ":id()",
    component: () => import("/codebuild/output/src199376353/src/pages/fund/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund___en",
    path: "/en/fund",
    meta: indexoJ2auzdCVrMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/index.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-index-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src199376353/src/pages/fund/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund___th",
    path: "/th/fund",
    meta: indexoJ2auzdCVrMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/index.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-index-id___th",
    path: ":id()",
    component: () => import("/codebuild/output/src199376353/src/pages/fund/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund___es",
    path: "/es/fund",
    meta: indexoJ2auzdCVrMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/index.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-index-id___es",
    path: ":id()",
    component: () => import("/codebuild/output/src199376353/src/pages/fund/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "fund-notice-id___ko",
    path: "/fund/notice/:id()",
    meta: _91id_93mDfXiRHR1QMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "fund-notice-id___en",
    path: "/en/fund/notice/:id()",
    meta: _91id_93mDfXiRHR1QMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "fund-notice-id___th",
    path: "/th/fund/notice/:id()",
    meta: _91id_93mDfXiRHR1QMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "fund-notice-id___es",
    path: "/es/fund/notice/:id()",
    meta: _91id_93mDfXiRHR1QMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "fund-notice___ko",
    path: "/fund/notice",
    meta: indexi8Y7TVpxJAMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-notice___en",
    path: "/en/fund/notice",
    meta: indexi8Y7TVpxJAMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-notice___th",
    path: "/th/fund/notice",
    meta: indexi8Y7TVpxJAMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "fund-notice___es",
    path: "/es/fund/notice",
    meta: indexi8Y7TVpxJAMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/fund/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "hashtag-id___ko",
    path: "/hashtag/:id()",
    meta: _91id_93Urvpz2mJlaMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/hashtag/[id].vue").then(m => m.default || m)
  },
  {
    name: "hashtag-id___en",
    path: "/en/hashtag/:id()",
    meta: _91id_93Urvpz2mJlaMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/hashtag/[id].vue").then(m => m.default || m)
  },
  {
    name: "hashtag-id___th",
    path: "/th/hashtag/:id()",
    meta: _91id_93Urvpz2mJlaMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/hashtag/[id].vue").then(m => m.default || m)
  },
  {
    name: "hashtag-id___es",
    path: "/es/hashtag/:id()",
    meta: _91id_93Urvpz2mJlaMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/hashtag/[id].vue").then(m => m.default || m)
  },
  {
    name: "hashtag___ko",
    path: "/hashtag",
    meta: indexFcgDJVSKMLMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/hashtag/index.vue").then(m => m.default || m)
  },
  {
    name: "hashtag___en",
    path: "/en/hashtag",
    meta: indexFcgDJVSKMLMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/hashtag/index.vue").then(m => m.default || m)
  },
  {
    name: "hashtag___th",
    path: "/th/hashtag",
    meta: indexFcgDJVSKMLMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/hashtag/index.vue").then(m => m.default || m)
  },
  {
    name: "hashtag___es",
    path: "/es/hashtag",
    meta: indexFcgDJVSKMLMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/hashtag/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ko",
    path: "/",
    meta: indexmhq0prnDjzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexmhq0prnDjzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___th",
    path: "/th",
    meta: indexmhq0prnDjzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexmhq0prnDjzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "nova-plus-id___ko",
    path: "/nova-plus/:id()",
    meta: _91id_93UXJbsVBsNBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "nova-plus-id___en",
    path: "/en/nova-plus/:id()",
    meta: _91id_93UXJbsVBsNBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "nova-plus-id___th",
    path: "/th/nova-plus/:id()",
    meta: _91id_93UXJbsVBsNBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "nova-plus-id___es",
    path: "/es/nova-plus/:id()",
    meta: _91id_93UXJbsVBsNBMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "nova-plus___ko",
    path: "/nova-plus",
    meta: indexGybj5GY163Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "nova-plus___en",
    path: "/en/nova-plus",
    meta: indexGybj5GY163Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "nova-plus___th",
    path: "/th/nova-plus",
    meta: indexGybj5GY163Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "nova-plus___es",
    path: "/es/nova-plus",
    meta: indexGybj5GY163Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "oulim___ko",
    path: "/oulim",
    meta: indexKdMvTy2y8BMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/oulim/index.vue").then(m => m.default || m)
  },
  {
    name: "oulim___en",
    path: "/en/oulim",
    meta: indexKdMvTy2y8BMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/oulim/index.vue").then(m => m.default || m)
  },
  {
    name: "oulim___th",
    path: "/th/oulim",
    meta: indexKdMvTy2y8BMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/oulim/index.vue").then(m => m.default || m)
  },
  {
    name: "oulim___es",
    path: "/es/oulim",
    meta: indexKdMvTy2y8BMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/oulim/index.vue").then(m => m.default || m)
  },
  {
    name: "post-id___ko",
    path: "/post/:id()",
    meta: _91id_937z1LtISisfMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: "post-id___en",
    path: "/en/post/:id()",
    meta: _91id_937z1LtISisfMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: "post-id___th",
    path: "/th/post/:id()",
    meta: _91id_937z1LtISisfMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: "post-id___es",
    path: "/es/post/:id()",
    meta: _91id_937z1LtISisfMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/post/[id].vue").then(m => m.default || m)
  },
  {
    name: "referral-@id___ko",
    path: "/referral/@:id()",
    meta: _64_91id_93YdNuZ5wXmkMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/referral/@[id].vue").then(m => m.default || m)
  },
  {
    name: "referral-@id___en",
    path: "/en/referral/@:id()",
    meta: _64_91id_93YdNuZ5wXmkMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/referral/@[id].vue").then(m => m.default || m)
  },
  {
    name: "referral-@id___th",
    path: "/th/referral/@:id()",
    meta: _64_91id_93YdNuZ5wXmkMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/referral/@[id].vue").then(m => m.default || m)
  },
  {
    name: "referral-@id___es",
    path: "/es/referral/@:id()",
    meta: _64_91id_93YdNuZ5wXmkMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/referral/@[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment-id___ko",
    path: "/search/detail/comment/:id()",
    meta: _91id_93za9JXtr6LiMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/comment/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment-id___en",
    path: "/en/search/detail/comment/:id()",
    meta: _91id_93za9JXtr6LiMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/comment/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment-id___th",
    path: "/th/search/detail/comment/:id()",
    meta: _91id_93za9JXtr6LiMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/comment/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment-id___es",
    path: "/es/search/detail/comment/:id()",
    meta: _91id_93za9JXtr6LiMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/comment/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment___ko",
    path: "/search/detail/comment",
    meta: indexKP8jus1Xb5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/comment/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment___en",
    path: "/en/search/detail/comment",
    meta: indexKP8jus1Xb5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/comment/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment___th",
    path: "/th/search/detail/comment",
    meta: indexKP8jus1Xb5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/comment/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-comment___es",
    path: "/es/search/detail/comment",
    meta: indexKP8jus1Xb5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/comment/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-hashtag___ko",
    path: "/search/detail/hashtag",
    meta: hashtagZ6sGvBT8gnMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/hashtag.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-hashtag___en",
    path: "/en/search/detail/hashtag",
    meta: hashtagZ6sGvBT8gnMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/hashtag.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-hashtag___th",
    path: "/th/search/detail/hashtag",
    meta: hashtagZ6sGvBT8gnMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/hashtag.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-hashtag___es",
    path: "/es/search/detail/hashtag",
    meta: hashtagZ6sGvBT8gnMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/hashtag.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index-id___ko",
    path: "/search/detail/:id()",
    meta: _91id_93L7GQvjPSbPMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/index/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index-id___en",
    path: "/en/search/detail/:id()",
    meta: _91id_93L7GQvjPSbPMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/index/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index-id___th",
    path: "/th/search/detail/:id()",
    meta: _91id_93L7GQvjPSbPMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/index/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index-id___es",
    path: "/es/search/detail/:id()",
    meta: _91id_93L7GQvjPSbPMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/index/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index___ko",
    path: "/search/detail",
    meta: index93WBsgt3c1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/index/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index___en",
    path: "/en/search/detail",
    meta: index93WBsgt3c1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/index/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index___th",
    path: "/th/search/detail",
    meta: index93WBsgt3c1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/index/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-index___es",
    path: "/es/search/detail",
    meta: index93WBsgt3c1Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/index/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus-id___ko",
    path: "/search/detail/nova-plus/:id()",
    meta: _91id_93jBvhlueoSzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus-id___en",
    path: "/en/search/detail/nova-plus/:id()",
    meta: _91id_93jBvhlueoSzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus-id___th",
    path: "/th/search/detail/nova-plus/:id()",
    meta: _91id_93jBvhlueoSzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus-id___es",
    path: "/es/search/detail/nova-plus/:id()",
    meta: _91id_93jBvhlueoSzMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/nova-plus/[id].vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus___ko",
    path: "/search/detail/nova-plus",
    meta: indexzxWARHhIPuMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus___en",
    path: "/en/search/detail/nova-plus",
    meta: indexzxWARHhIPuMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus___th",
    path: "/th/search/detail/nova-plus",
    meta: indexzxWARHhIPuMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-nova-plus___es",
    path: "/es/search/detail/nova-plus",
    meta: indexzxWARHhIPuMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/nova-plus/index.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-user___ko",
    path: "/search/detail/user",
    meta: userGbLkuSom6WMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/user.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-user___en",
    path: "/en/search/detail/user",
    meta: userGbLkuSom6WMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/user.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-user___th",
    path: "/th/search/detail/user",
    meta: userGbLkuSom6WMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/user.vue").then(m => m.default || m)
  },
  {
    name: "search-detail-user___es",
    path: "/es/search/detail/user",
    meta: userGbLkuSom6WMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/detail/user.vue").then(m => m.default || m)
  },
  {
    name: "search___ko",
    path: "/search",
    meta: indexRDaj43lqBCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/index.vue").then(m => m.default || m),
    children: [
  {
    name: "search-index-id___ko",
    path: ":id()",
    meta: _91id_93QVPBvHmsgnMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: indexRDaj43lqBCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/index.vue").then(m => m.default || m),
    children: [
  {
    name: "search-index-id___en",
    path: ":id()",
    meta: _91id_93QVPBvHmsgnMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search___th",
    path: "/th/search",
    meta: indexRDaj43lqBCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/index.vue").then(m => m.default || m),
    children: [
  {
    name: "search-index-id___th",
    path: ":id()",
    meta: _91id_93QVPBvHmsgnMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "search___es",
    path: "/es/search",
    meta: indexRDaj43lqBCMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/index.vue").then(m => m.default || m),
    children: [
  {
    name: "search-index-id___es",
    path: ":id()",
    meta: _91id_93QVPBvHmsgnMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/search/index/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "terms-id___ko",
    path: "/terms/:id()",
    meta: _91id_93CkBNSlY1CUMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/terms/[id].vue").then(m => m.default || m)
  },
  {
    name: "terms-id___en",
    path: "/en/terms/:id()",
    meta: _91id_93CkBNSlY1CUMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/terms/[id].vue").then(m => m.default || m)
  },
  {
    name: "terms-id___th",
    path: "/th/terms/:id()",
    meta: _91id_93CkBNSlY1CUMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/terms/[id].vue").then(m => m.default || m)
  },
  {
    name: "terms-id___es",
    path: "/es/terms/:id()",
    meta: _91id_93CkBNSlY1CUMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/terms/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-id-activities___ko",
    path: "/user/:id()/activities",
    meta: activitiesS4DInruvHjMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-activities-postId___ko",
    path: ":postId()",
    meta: _91postId_9301cYC0WBMYMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-activities___en",
    path: "/en/user/:id()/activities",
    meta: activitiesS4DInruvHjMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-activities-postId___en",
    path: ":postId()",
    meta: _91postId_9301cYC0WBMYMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-activities___th",
    path: "/th/user/:id()/activities",
    meta: activitiesS4DInruvHjMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-activities-postId___th",
    path: ":postId()",
    meta: _91postId_9301cYC0WBMYMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-activities___es",
    path: "/es/user/:id()/activities",
    meta: activitiesS4DInruvHjMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-activities-postId___es",
    path: ":postId()",
    meta: _91postId_9301cYC0WBMYMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/activities/[postId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-collections___ko",
    path: "/user/:id()/collections",
    meta: collectionsmzghoSE7FEMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-collections-collectionId___ko",
    path: ":collectionId()",
    meta: _91collectionId_932xQofMxfxTMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-collections___en",
    path: "/en/user/:id()/collections",
    meta: collectionsmzghoSE7FEMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-collections-collectionId___en",
    path: ":collectionId()",
    meta: _91collectionId_932xQofMxfxTMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-collections___th",
    path: "/th/user/:id()/collections",
    meta: collectionsmzghoSE7FEMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-collections-collectionId___th",
    path: ":collectionId()",
    meta: _91collectionId_932xQofMxfxTMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-collections___es",
    path: "/es/user/:id()/collections",
    meta: collectionsmzghoSE7FEMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/collections.vue").then(m => m.default || m),
    children: [
  {
    name: "user-id-collections-collectionId___es",
    path: ":collectionId()",
    meta: _91collectionId_932xQofMxfxTMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/collections/[collectionId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-id-donation___ko",
    path: "/user/:id()/donation",
    meta: donation39VDwXx6J5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "user-id-donation___en",
    path: "/en/user/:id()/donation",
    meta: donation39VDwXx6J5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "user-id-donation___th",
    path: "/th/user/:id()/donation",
    meta: donation39VDwXx6J5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "user-id-donation___es",
    path: "/es/user/:id()/donation",
    meta: donation39VDwXx6J5Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/donation.vue").then(m => m.default || m)
  },
  {
    name: "user-id-index-postId___ko",
    path: "/user/:id()/:postId()",
    meta: _91postId_93jlFfF2aM3YMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/index/[postId].vue").then(m => m.default || m)
  },
  {
    name: "user-id-index-postId___en",
    path: "/en/user/:id()/:postId()",
    meta: _91postId_93jlFfF2aM3YMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/index/[postId].vue").then(m => m.default || m)
  },
  {
    name: "user-id-index-postId___th",
    path: "/th/user/:id()/:postId()",
    meta: _91postId_93jlFfF2aM3YMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/index/[postId].vue").then(m => m.default || m)
  },
  {
    name: "user-id-index-postId___es",
    path: "/es/user/:id()/:postId()",
    meta: _91postId_93jlFfF2aM3YMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/index/[postId].vue").then(m => m.default || m)
  },
  {
    name: "user-id-index___ko",
    path: "/user/:id()",
    meta: indexfwXXaannkLMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: "user-id-index___en",
    path: "/en/user/:id()",
    meta: indexfwXXaannkLMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: "user-id-index___th",
    path: "/th/user/:id()",
    meta: indexfwXXaannkLMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: "user-id-index___es",
    path: "/es/user/:id()",
    meta: indexfwXXaannkLMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: "user-id-reward___ko",
    path: "/user/:id()/reward",
    meta: rewardR0WDLn4dbOMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "user-id-reward___en",
    path: "/en/user/:id()/reward",
    meta: rewardR0WDLn4dbOMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "user-id-reward___th",
    path: "/th/user/:id()/reward",
    meta: rewardR0WDLn4dbOMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "user-id-reward___es",
    path: "/es/user/:id()/reward",
    meta: rewardR0WDLn4dbOMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/[id]/reward.vue").then(m => m.default || m)
  },
  {
    name: "user-sponsors___ko",
    path: "/user/sponsors",
    meta: indexMGulY47O8KMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/sponsors/index.vue").then(m => m.default || m)
  },
  {
    name: "user-sponsors___en",
    path: "/en/user/sponsors",
    meta: indexMGulY47O8KMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/sponsors/index.vue").then(m => m.default || m)
  },
  {
    name: "user-sponsors___th",
    path: "/th/user/sponsors",
    meta: indexMGulY47O8KMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/sponsors/index.vue").then(m => m.default || m)
  },
  {
    name: "user-sponsors___es",
    path: "/es/user/sponsors",
    meta: indexMGulY47O8KMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/user/sponsors/index.vue").then(m => m.default || m)
  },
  {
    name: "w3cf-detail-id___ko",
    path: "/w3cf/detail/:id()",
    meta: _91id_93XLad2dd10UMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/w3cf/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "w3cf-detail-id___en",
    path: "/en/w3cf/detail/:id()",
    meta: _91id_93XLad2dd10UMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/w3cf/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "w3cf-detail-id___th",
    path: "/th/w3cf/detail/:id()",
    meta: _91id_93XLad2dd10UMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/w3cf/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "w3cf-detail-id___es",
    path: "/es/w3cf/detail/:id()",
    meta: _91id_93XLad2dd10UMeta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/w3cf/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "w3cf___ko",
    path: "/w3cf",
    meta: indexnlGujUAg04Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/w3cf/index.vue").then(m => m.default || m)
  },
  {
    name: "w3cf___en",
    path: "/en/w3cf",
    meta: indexnlGujUAg04Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/w3cf/index.vue").then(m => m.default || m)
  },
  {
    name: "w3cf___th",
    path: "/th/w3cf",
    meta: indexnlGujUAg04Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/w3cf/index.vue").then(m => m.default || m)
  },
  {
    name: "w3cf___es",
    path: "/es/w3cf",
    meta: indexnlGujUAg04Meta || {},
    component: () => import("/codebuild/output/src199376353/src/pages/w3cf/index.vue").then(m => m.default || m)
  }
]