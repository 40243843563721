import { subscribe } from './subscribe'
import { commonError } from './commonError'
import type { UserContentType } from '@components/NovaBoxUserTopHeader/NovaBoxUserTopHeader.types'
import type {
  DonationsOrderBy,
  MyCommentOrderBy,
  UserContentsOrderBy,
  MyRecommendPostType,
  RewardsOrderBy,
  ScrapPostsOrderBy,
  SetRecommendsOrderBy,
  ActivityFilter,
  CreatorContentsItemType,
  MyCollectionsFilter,
} from '@store'

export const mypage = {
  profile: {
    title: 'ข้อมูลโปรไฟล์',
    placeholder: {
      nickname: 'ตั้งชื่อเล่นของคุณ',
      content: 'เนื้อหาประวัติส่วนตัว',
    },
    button: {
      cancel: 'ยกเลิก',
      save: 'บันทึก',
    },
    dialog: {
      title: 'การแจ้งเตือน',
      initImage:
        'การลบภาพจะเปลี่ยนเป็นอวตารเริ่มต้นของคุณ คุณแน่ใจหรือไม่ว่าต้องการลบมัน?',
      doubleNickname: 'ชื่อผู้ใช้นี้ถูกใช้แล้ว กรุณาเลือกชื่อผู้ใช้อื่น',
      noImage: 'โปรไฟล์ของผู้ใช้ไม่พบ',
    },
    toastMessage: {
      unavailableNickname: 'อักขระไม่ถูกต้องในข้อความ กรุณาใช้ชื่อเล่นอื่น',
      imgError2005: 'ไม่สามารถลงทะเบียนส่วนขยายนี้ได้',
      imgError: 'การอัปโหลดภาพล้มเหลว กรุณาลองอีกครั้งภายหลัง',
    },
  },
  tabMenu: {
    contents: 'รางวัล',
    reward: 'การสนับสนุน',
    donation: 'โพสต์',
    activity: 'กิจกรรมของฉัน',
    collections: 'คอลเลกชันของฉัน',
  } as { [Property in UserContentType]: string },
  sort: {
    reward: {
      newest: 'ใหม่ล่าสุด',
      oldest: 'เก่า',
      rewardQty: 'ใหญ่ที่สุด',
    } as { [Property in RewardsOrderBy]: string },
    donation: {
      newest: 'ใหม่ล่าสุด',
      oldest: 'เก่า',
      donationQty: 'ใหญ่ที่สุด',
    } as { [Property in DonationsOrderBy]: string },
    post: {
      newest: 'ใหม่ล่าสุด',
      oldest: 'เก่า',
      hot: 'เรียงตามความนิยม',
    } as { [Property in UserContentsOrderBy]: string },
    recommend: {
      newest: 'ใหม่ล่าสุด',
      oldest: 'เก่า',
    } as { [Property in SetRecommendsOrderBy]: string },
    comment: {
      newest: 'ใหม่ล่าสุด',
      oldest: 'เก่า',
      // hot: 'เรียงตามความนิยม',
    } as { [Property in MyCommentOrderBy]: string },
    scrap: {
      newest: 'ใหม่ล่าสุด',
      oldest: 'เก่า',
      // hot: 'เรียงตามความนิยม',
    } as { [Property in ScrapPostsOrderBy]: string },
  },
  postTypes: {
    content: 'เนื้อหา',
    free: 'ฟรี',
    live: 'สด',
  } as { [Property in CreatorContentsItemType]: string },
  filter: {
    donation: {
      received: 'ที่ได้รับ',
      sent: 'ที่ส่ง',
    },
    activity: {
      recommend: 'ถูกใจ',
      comment: 'ความคิดเห็น',
      scrap: 'บันทึก',
    } as { [Property in ActivityFilter]: string },
    myCollections: {
      recentlyDesc: 'ที่สร้างล่าสุด',
      rarityDesc: 'จัดเรียงตามความหายาก',
    } as { [Property in MyCollectionsFilter]: string },
  },
  panel: {
    reward: {
      title: 'รางวัลรวม',
      empty: 'คุณยังไม่ได้รับรางวัลใด ๆ',
    },
    donationQty: {
      received: 'การสนับสนุนที่ได้รับรวม',
      sent: 'การสนับสนุนที่ส่งรวม',
    },
    post: {
      public: 'สาธารณะ',
      private: 'ส่วนตัว',
    },
    comment: 'ความคิดเห็นทั้งหมด',
    recommend: {
      get: 'โพสต์ที่ได้รับการถูกใจ',
      set: 'โพสต์ที่ฉันถูกใจ',
    } as { [Property in MyRecommendPostType]: string },
    scrap: 'โพสต์ที่บันทึกทั้งหมด',
  },
  reward: {
    active: 'กิจกรรม',
    messages: {
      activity: 'รางวัลกิจกรรมรายสัปดาห์ได้รับการจ่ายแล้ว',
      post: 'รางวัลการโพสต์รายสัปดาห์ได้รับการจ่ายแล้ว',
      fixed: 'รางวัลรายเดือนได้รับการจ่ายแล้ว',
      incentive: 'รางวัลจูงใจได้รับการจ่ายแล้ว',
      monthly: 'รางวัลโบนัสรายเดือนได้รับการจ่ายแล้ว',
      referral: 'รางวัลการแนะนำได้รับการจ่ายแล้ว',
      novaPlus: `รางวัล ${subscribe.subscribeGrade.novaPlus} ได้รับการจ่ายแล้ว`,
      nft: 'รางวัลกิจกรรมการมีส่วนร่วมในระบบนิเวศรายสัปดาห์ได้รับการจ่ายแล้ว',
      superCreator: 'คุณได้รับรางวัลพิเศษรายเดือน',
      megaCreator: 'คุณได้รับรางวัลพิเศษรายเดือน',
      weeklyReward: 'รางวัลรายสัปดาห์ได้รับการจ่ายแล้ว',
      missionReward: 'คุณได้รับรางวัลภารกิจ',
    },
    zeroGuide: 'เมื่อคะแนนกิจกรรมถูกหักล้างกันจนถึงศูนย์ รางวัลจะเป็น 0LM',
  },
  donation: {
    message: {
      received: 'คุณได้รับการสนับสนุนจาก <strong>{userNcnm}</strong>.',
      sent: 'คุณได้ส่งการสนับสนุนไปยัง <strong>{userNcnm}</strong>.',
      empty: {
        received: {
          title: 'ไม่มีบันทึกการสนับสนุนที่คุณได้รับ',
          message: 'ลองรับการสนับสนุนจากแฟน ๆ ผ่านกิจกรรมต่าง ๆ',
        },
        sent: {
          title: 'ไม่มีบันทึกการสนับสนุนที่คุณส่ง',
          message: 'ส่งการสนับสนุนที่อบอุ่นให้กับผู้สร้าง',
        },
      },
      error: {
        received:
          'ไม่สามารถดึงข้อมูลบันทึกการสนับสนุุนที่คุณได้รับ กรุณาลองอีกครั้งภายหลัง',
        sent: 'ไม่สามารถดึงข้อมูลบันทึกการสนับสนุนที่คุณส่ง กรุณาลองอีกครั้งภายหลัง',
        donationQty: 'ไม่สามารถดึงข้อมูลการสนับสนุน กรุณาลองอีกครั้งภายหลัง',
      },
    },
  },
  myFollow: {
    tabs: {
      follower: 'ผู้ติดตาม',
      block: 'รายการบล็อก',
    },
    title: {
      follower: 'ผู้ติดตาม',
      following: 'กำลังติดตาม',
      block: 'รายการบล็อก',
    },
    follower: {
      noDate: {
        title: 'กำลังมองหาผู้ติดตาม?',
        message:
          'บัญชีนี้ยังไม่มีผู้ติดตาม<br />คุณสามารถสร้างผู้ติดตามได้โดยการสร้างโพสต์และมีส่วนร่วมกับคนอื่น ๆ',
      },
      error: 'ไม่สามารถดึงรายชื่อผู้ติดตาม กรุณาลองอีกครั้งภายหลัง',
    },
    following: {
      state: 'ติดตามคุณ',
      noDate: {
        title: 'คุณยังไม่ได้ติดตามใครเลย',
        button: 'ค้นหาคนที่ควรติดตาม',
      },
      backFlag: 'ติดตามฉัน',
      error: 'ไม่สามารถดึงรายชื่อผู้ติดตาม กรุณาลองอีกครั้งภายหลัง',
    },

    block: {
      title: 'รายการบล็อก',
      noDate: {
        title: 'คุณยังไม่ได้บล็อกใคร',
      },
      error: 'ไม่สามารถดึงรายการบล็อก กรุณาลองอีกครั้งภายหลัง',
    },
    button: {
      follow: 'ติดตาม',
      following: 'กำลังติดตาม',
      delete: 'ลบผู้ติดตาม',
      search: 'ค้นหาคนที่ควรติดตาม',
      cancel: 'ปลดบล็อก',
    },
    message: {
      follow: {
        error: {
          2022: 'คุณติดตามแล้ว',
          2034: 'คุณไม่สามารถติดตาม {userNcnm} ได้',
          4002: 'นี่เป็นสมาชิกที่ไม่ได้ลงทะเบียน',
          4013: 'นี่เป็นสมาชิกที่ไม่ได้ลงทะเบียน',
          unknown: commonError.network.message,
        },
      },
      unfollow: {
        error: {
          2023: 'ไม่มีผู้ติดตาม',
          2034: 'คุณไม่สามารถยกเลิกการติดตาม {userNcnm} ได้',
          4002: 'สมาชิกที่ไม่ได้ลงทะเบียน',
          4013: 'สมาชิกที่ไม่ได้ลงทะเบียน',
          unknown: commonError.network.message,
        },
      },
      followerRemoveConfirmMessage: 'ต้องการลบผู้ติดตามใช่หรือไม่?',
      delSuccess: 'ลบผู้ติดตามแล้ว',
      delError: 'ไม่สามารถลบผู้ติดตาม กรุณาลองอีกครั้งภายหลัง',
      followerError: 'ไม่สามารถติดตาม กรุณาลองอีกครั้งภายหลัง',
      unfollowError: 'ไม่สามารถยกเลิกการติดตาม กรุณาลองอีกครั้งภายหลัง',
    },
  },
  myCollection: {
    message: {
      empty: {
        message: 'ไม่มี NFT ให้แสดง',
      },
    },
    button: {
      showMore: 'แสดงเพิ่มเติม',
      showLess: 'แสดงน้อยลง',
    },
    detail: {
      owner: 'เจ้าของ',
      rarity: 'ความหายาก',
      issueDate: 'วันที่ออก',
    },
  },
}
