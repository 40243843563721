<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { NovaBoxW3cfProps } from './NovaBoxW3cf.types'

const props = withDefaults(defineProps<NovaBoxW3cfProps>(), {
  isDetail: false,
})

const { addVideoFullscreenEventListener, removeVideoFullscreenEventListener } =
  useVideoFullScreen()
const { t } = useI18n()
const { userStore, showSignInDialog } = useMembershipProcess()
const w3cfStore = useW3cfStore()
const videoRef = ref<HTMLVideoElement>()
const tippyRef = ref()
const isLoading = ref(false)

// 좋아요, 좋아요 취소 게이트
const handleOnLike = async () => {
  // 1. 비로그인 상황에서는 로그인 가능하도록 팝업 열기
  if (!userStore.isSignIn) {
    await showSignInDialog()
    return
  }

  // 2. 좋아요 / 좋아요 취소 액션 호출
  switch (props.source.rcFlag) {
    case '0':
      await reqW3cfLike()
      break
    case '1':
      await reqW3cfUnlike()
      break
    default:
      throw new Error('w3cf like, unlike flag is not match')
  }
}

// 좋아요
const reqW3cfLike = async () => {
  try {
    isLoading.value = true
    await useSleeper()
    await w3cfStore.reqW3cfLike(
      { w3cfNttSn: props.source.w3cfNttSn },
      props.source.rcCount + 1
    )
    useToast(t('w3cf.w3cfLike.success'))
  } catch {
    useToast(t('w3cf.w3cfLike.fail'))
  } finally {
    isLoading.value = false
  }
}

// 좋아요 취소
const reqW3cfUnlike = async () => {
  try {
    isLoading.value = true
    await useSleeper()
    await w3cfStore.reqW3cfUnlike(
      { w3cfNttSn: props.source.w3cfNttSn },
      props.source.rcCount - 1
    )
    useToast(t('w3cf.w3cfUnlike.success'))
  } catch {
    useToast(t('w3cf.w3cfUnlike.fail'))
  } finally {
    isLoading.value = false
  }
}

// 공유 패널 닫기
const handleOnSharePanelClose = () => {
  tippyRef.value?.hide()
}

onMounted(() => {
  addVideoFullscreenEventListener(videoRef.value)
})

onUnmounted(() => {
  removeVideoFullscreenEventListener(videoRef.value)
})
</script>

<template>
  <div :class="['w3cf', { 'on-detail': isDetail }]">
    <div class="header">
      <p class="apply-user">{{ source.w3cfApplcntNm }}</p>

      <p class="view-count">
        {{ $t('w3cf.displayCount') }}
        {{ useFormatThousandsSeparators(source.nttRdcnt) }}
      </p>
    </div>

    <div class="body">
      <h4 class="title">{{ source.nttSj }}</h4>

      <div class="contents">
        <div class="contents-viewer">
          <NovaImageContainer
            v-if="source.cretnMediaTyCode === 'I'"
            :image-url="source.cretnFileUrl"
            :ratio="'auto'"
            :empty-type="'default'"
            class="image-viewer"
          />
          <!--
            autoplay 자동재생
            playsinline ios 자동재생
            muted 음소거(자동재생)
            controls 영상 컨트롤 박스/재생 멈춤 진행바 볼륨
            loop 반복 재생
            none 재생전 미디어 파일 다운로드
            controlsList="nodownload" 다운로드 삭제 ,nofullscreen 버튼 숨김
             -->
          <video
            v-if="source.cretnMediaTyCode === 'V'"
            ref="videoRef"
            :src="source.cretnFileUrl"
            :poster="source.cretnThumbFileUrl"
            autoplay
            controls
            loop
            muted
            none
            playsinline
            controlsList="nodownload nofullscreen"
            class="video-viewer"
          />
        </div>

        <p v-dompurify-html="source.nttCn" class="description" />
      </div>
    </div>

    <NovaDivider />

    <div class="actions">
      <NovaBoxPostAction
        :icon="{
          type: 'outline',
          name: source.rcFlag === '0' ? 'like' : 'like-fill',
        }"
        :display-number="source.rcCount"
        :loading="isLoading"
        :action-type="'recommend'"
        :disabled="source.rcAvlAt === 'N'"
        @click.stop="handleOnLike"
      />

      <Tippy
        ref="tippyRef"
        :append-to="'parent'"
        :interactive="true"
        :placement="'bottom-end'"
        :theme="'popover'"
        @click.stop="handleOnSharePanelClose"
      >
        <NovaBoxPostAction
          :icon="{ type: 'outline', name: 'more-horizontal' }"
          :display-number="-1"
          :icon-size="16"
          :action-type="'share'"
        />

        <template #content>
          <NovaBoxPostActionSharePanel
            :contents-type="'w3cf'"
            :show-navigation="false"
            :cmty-ntt-sn="source.w3cfNttSn"
            :post-title="source.nttSj"
            :post-contents="source.w3cfApplcntNm"
            :post-image="source.cretnThumbFileUrl"
            :share-count="0"
          />
        </template>
      </Tippy>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.w3cf {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: $color-bg-3;

  &.on-detail {
    border-radius: 16px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    .apply-user {
      @include text-style($text-body-14-bold);
      color: $color-text-2;
    }

    .view-count {
      @include text-style($text-body-14-regular);
      color: $color-text-3;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
      @include text-style($text-heading2-bold);
      color: $color-text-2;
    }

    .contents {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .contents-viewer {
        .image-viewer {
          border-radius: 8px;
          background-color: $color-bg-2;
        }

        .video-viewer {
          width: 100%;
          height: auto;
          border-radius: 8px;
        }
        video::-webkit-media-controls-fullscreen-button {
          display: none !important;
        }
      }

      .description {
        word-break: break-all;

        * {
          @include text-style($text-body-14-regular);
          color: $color-text-2;
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }
}
</style>
