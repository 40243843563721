import { commonError } from './commonError'

const notificationType = {
  push: 'การแจ้งเตือน',
  email: 'อีเมล',
  sms: 'SMS',
}

const pushDialog = {
  title: 'การแจ้งเตือนผ่านแอป',
  contents:
    '<p style="font-size: 16px; font-weight: 500; line-height: 1.2; text-align: center;">*คุณสามารถดูการแจ้งเตือนบริการเมื่อเปิดแอป {appName} ได้ โดยไม่ต้องรับการแจ้งเตือนผ่านพุช</p><p style="font-size: 12px; text-align: center; margin-top: 4px;">*แม้คุณจะปิดการแจ้งเตือน แต่การอัปเดตบริการที่สำคัญยังคงส่งถึงคุณได้</p>',
  confirm: 'ตกลง',
}

const serviceDialog = {
  title: 'การแจ้งเตือนบริการ',
  contents:
    'หากไม่เปิดการแจ้งเตือนบริการ การแจ้งเตือนใหม่จะไม่ได้รับการบันทึกในกล่องการแจ้งเตือน<br />คุณต้องการรับการแจ้งเตือนหรือไม่?',
  cancel: 'ไม่, ปิดการแจ้งเตือน',
  confirm: 'ใช่',
}

const marketingDialog = {
  title: 'การแจ้งเตือนการตลาด ({type})',
  contents:
    'คุณสามารถรับข้อมูลเกี่ยวกับกิจกรรมและประโยชน์ของ {appName} ได้โดยไม่พลาด คุณต้องการยืนยันการ consent หรือไม่?',
  cancel: 'ไม่, ปิดการแจ้งเตือน',
  confirm: 'ใช่',
}

const notificationMarketingAgreeDialog = {
  title: 'คุณต้องการรับการแจ้งเตือนการตลาดผ่านพุชหรือไม่?',
  message:
    'คุณสามารถรับข้อมูลเกี่ยวกับกิจกรรมและประโยชน์โดยไม่พลาดได้ผ่านการแจ้งเตือนพุชในแอป!',
  messageSm:
    '* การยินยอมรับการแจ้งเตือนการตลาดผ่านพุชสามารถเปลี่ยนแปลงได้ในการตั้งค่าแอป I LIKE LM > การตั้งค่าการแจ้งเตือน',
  actions: {
    action: 'ครั้งหน้า',
    positive: 'ใช่, ฉันจะรับมัน',
  },
  errors: {
    7005: 'ไม่สามารถบันทึกการยอมรับการแจ้งเตือนการตลาดผ่านพุชได้ โปรดลองอีกครั้งภายหลัง',
    7006: 'ไม่สามารถบันทึกการยอมรับการแจ้งเตือนการตลาดผ่านพุชได้ โปรดลองอีกครั้งภายหลัง',
    default: commonError.network.message,
  },
}

const toastMessage = {
  marketing: {
    agree:
      'คุณได้ยินยอมรับการรับการแจ้งเตือนการตลาดของ {appName} ({type}) ({date})',
    deny: 'คุณปฏิเสธการรับการแจ้งเตือนการตลาดของ {appName} ({type}) ({date})',
  },
}

export const notificationSetting = {
  notificationAndSetting: 'การตั้งค่าการแจ้งเตือน',
  selectNotificationSound: 'เลือกเสียงการแจ้งเตือน',
  goDetailSetting: 'รายละเอียด',
  marketingAgreeY: 'ยินยอมรับการรับข้อมูลการตลาด ({date})',
  marketingAgreeN: 'ปฏิเสธการรับข้อมูลการตลาด ({date})',
  marketingPushTime: 'เวลาส่ง: 8AM~9PM (KST)',
  notificationType,
  pushDialog,
  serviceDialog,
  marketingDialog,
  notificationMarketingAgreeDialog,
  toastMessage,
}
