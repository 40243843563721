<script setup lang="ts">
import { type Classification, type UserPanelId, LayerType } from '@store'
import {
  NovaModalCommon,
  NovaModalDialog,
  NovaModalReportCustomer,
} from '#components'
import { ROUTES } from '@configs'

const emit = defineEmits(['goPanel', 'toggleShowPanel'])

const config = useRuntimeConfig()
const { gtEvent } = useGoogleTag()
const { t } = useI18n()
const { show: modalShow, hide: modalHide } = useModal()
const { certifiedKYC } = useKyc()
const userStore = useUserStore()
const etcStore = useEtcStore()
const layoutStore = useLayoutStore()
const mypageStore = useMyPageStore()
const kycCertified = computed(() => userStore.user?.kycAt === 'Y')
const lm = computed(() =>
  useNumberCompact(mypageStore.rewardCount.rwardSmLM, 2)
)
const copyRight = computed(() =>
  t('corporationInfo.copyright', { year: new Date().getFullYear() })
)
const grade = computed(() => {
  switch (userStore.user?.crtrSpclGradCode) {
    case '0000':
    case null:
      return 'default'
    case '0020':
      return 'bronze'
    case '0030':
      return 'super'
    case '0040':
      return 'silver'
    case '0050':
      return 'mega'
    case '0060':
      return 'gold'
    default:
      throw new Error('creator grade is not match')
  }
})
const applyCreator = computed(() => {
  const status = mypageStore.applyCreatorStatus
  switch (status) {
    // 심사중
    case 'underReview':
      return {
        badge: {
          label: t('applyCreator.guide.apply.gateStatus.underReview'),
          theme: 'secondary-green-light-80',
        },
        showLink: true,
      }
    // 승인거부
    case 'reject':
      return {
        badge: {
          label: t('applyCreator.guide.apply.gateStatus.confirm'),
          theme: 'secondary-gray',
        },
        showLink: true,
      }
    // 승인완료(크리에이터 전환된 상태)
    case 'approved':
      return {
        badge: undefined,
        showLink: false,
      }
    // 신청 가능
    case 'available':
    default:
      return {
        badge: undefined,
        showLink: true,
      }
  }
})
const state = reactive({
  qnaContent: '',
  qnaValue: 0,
  detail: {
    dropdownPlaceholder: t('qna.placeholder.dropdown'),
    textareaPlaceholder: t('qna.placeholder.content'),
    textareaMax: 4000,
    btnLabel: [t('qna.button.cancel'), t('qna.button.confirm')],
  },
})

const menuSet = computed(() =>
  etcStore.classifications.map((item) => {
    return { value: item.bbsNttClSn, label: item.nttClNm }
  })
)

onBeforeMount(() => {
  fetchRewardCount()
})

onUnmounted(() => {
  layoutStore['layer:close'](LayerType.USER_PROFILE_EDIT_PANEL)
  layoutStore['layer:close'](LayerType.USER_MAIN_PROFILE_EDIT_PANEL)
})

// 사용자 누적 보상 정보 조회
const fetchRewardCount = async () => {
  if (!userStore.isSignIn) return

  try {
    await mypageStore.fetchRewardCount({
      userCmmnSn: userStore.user!.userCmmnSn,
    })
  } catch {}
}

const handleOnClickUseReward = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: `나의 홈 리워드 클릭 > ${
      kycCertified.value ? '지갑으로 이동' : 'KYC 인증 요청'
    }`,
    eventLabel: t('rewardsGate'),
    eventSlot: '유저 패널',
    eventI18nAddr: useKoreanTranslation('rewardsGate'),
    eventComponent: 'Button',
  })
  if (kycCertified.value) {
    useNavigations({ external: true, url: config.public.REWARD_URL })
  } else {
    certifiedKYC('reward')
  }
}

const handleOnGoCreatorPage = () => {
  const creatorLink = userStore.user?.creatorLink
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '크리에이터 홈 이동 클릭',
    eventLabel: t('linkCreatorHome'),
    eventSlot: '유저 패널',
    eventI18nAddr: useKoreanTranslation('linkCreatorHome'),
    eventComponent: 'Button',
    creatorLink,
  })
  if (creatorLink) {
    useNavigations({ external: false, url: `/creator/@${creatorLink}` })
    emit('toggleShowPanel', null)
  }
}

const handleOnGoUserHomePage = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '유저 홈 이동 클릭',
    eventLabel: t('linkMyHome'),
    eventSlot: '유저 패널',
    eventI18nAddr: useKoreanTranslation('linkMyHome'),
    eventComponent: 'Button',
    userNcnm: userStore.user!.userNcnm,
  })
  useNavigations({
    external: false,
    url: `/user/@${userStore.user!.userNcnm}`,
  })
  emit('toggleShowPanel', null)
}

const handleOnGoDailyCheck = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '출석 체크',
    eventLabel: t('dailyCheck.dailyCheckin'),
    eventSlot: '유저 패널',
    eventI18nAddr: useKoreanTranslation('dailyCheck.dailyCheckin'),
    eventComponent: 'Button',
  })
  useNavigations({ url: ROUTES.DAILY_CHECK.path })
  emit('toggleShowPanel', null)
}

const handleOnGoApplyCreator = () => {
  if (
    mypageStore.applyCreatorStatus === 'reject' ||
    mypageStore.applyCreatorStatus === 'underReview' ||
    mypageStore.applyCreatorStatus === 'available'
  ) {
    gtEvent('clickEvent', {
      eventCategory: '클릭',
      eventAction: '크리에이터 신청하기',
      eventLabel: t('applyCreator.entry'),
      eventSlot: '유저 패널',
      eventI18nAddr: useKoreanTranslation('applyCreator.entry'),
      eventComponent: 'Button',
      applyCreatorStatus: mypageStore.applyCreatorStatus,
    })
    useNavigations({ url: ROUTES.APPLY_CREATOR.path })
    emit('toggleShowPanel', null)
  }
}

const handleOnGoSettings = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '설정',
    eventLabel: t('settings'),
    eventSlot: '유저 패널',
    eventI18nAddr: useKoreanTranslation('settings'),
    eventComponent: 'Button',
  })
  const panelId: UserPanelId = 'settings'
  emit('goPanel', panelId)
}

// const handleOnGoServices = () => {
//   const panelId: UserPanelId = 'services'
//   gtEvent('clickEvent', {
//     eventCategory: '클릭',
//     eventAction: '서비스 및 정책',
//     eventLabel: t('servicesAndPolicies'),
//     eventSlot: '유저 패널',
//     eventI18nAddr: useKoreanTranslation('servicesAndPolicies'),
//     eventComponent: 'Button',
//   })
//   emit('goPanel', panelId)
// }

const onSubmitReport = async () => {
  try {
    const selectCtg: Classification[] = etcStore.classifications.filter(
      (item) => item.bbsNttClSn === state.qnaValue
    )
    const payload = {
      bbsNttClSn: selectCtg[0].bbsNttClSn,
      email: userStore.userCommonInfo!.email,
      nttClNm: selectCtg[0].nttClNm,
      nttCn: state.qnaContent,
      userCmmnSn: userStore.userCommonInfo!.userCmmnSn,
    }
    await etcStore.sendQnA(payload)
    await modalHide(modalsName.MODAL_CS)
    await useSleeper()
    await showDialogModal()
  } catch (err) {
    useToast(t('qna.error'))
  }
}

const handleOnGoCS = async () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '고객 문의 모달 오픈',
    eventLabel: t('cs'),
    eventSlot: '유저 패널',
    eventI18nAddr: useKoreanTranslation('cs'),
    eventComponent: 'Button',
  })
  emit('toggleShowPanel', null)
  await modalShow({
    component: NovaModalCommon,
    bind: {
      name: modalsName.MODAL_CS,
      showCloseBtn: true,
    },
    on: {
      close: async () => {
        gtEvent('clickEvent', {
          eventCategory: '클릭',
          eventAction: '고객 문의 모달 닫기',
          eventLabel: t('cs'),
          eventSlot: '유저 패널',
          eventI18nAddr: useKoreanTranslation('cs'),
          eventComponent: 'Button',
        })
        await modalHide(modalsName.MODAL_CS)
      },
    },
    slots: {
      title: t('qna.title'),
      contents: {
        component: NovaModalReportCustomer,
        bind: {
          detail: state.detail,
          menuSet: menuSet.value,
          message: t('qna.reportMessage'),
          where: t('qna.title'),
          title: useKoreanTranslation('qna.title'),
        },
        on: {
          close: async () => {
            await modalHide(modalsName.MODAL_CS)
          },
          updateMenu: (value: number) => {
            state.qnaValue = value
          },
          updateText: (value: string) => {
            state.qnaContent = value
          },
          confirm: () => {
            if (state.qnaValue > 0) {
              onSubmitReport()
            }
          },
        },
      },
    },
  })
}

const showDialogModal = async () => {
  await modalShow({
    component: NovaModalDialog,
    bind: {
      name: modalsName.MODAL_DIALOG,
      btns: [
        {
          label: t('confirm'),
          theme: 'primary-blue-light',
          size: 32,
          onClick: async () => {
            await modalHide(modalsName.MODAL_DIALOG)
          },
        },
      ],
    },
    slots: {
      title: t('qna.dialog.title'),
      content: t('qna.dialog.success'),
    },
  })
}

const handleOnSignOut = () => {
  userStore.signOut()
}
</script>

<template>
  <NovaHeaderToolsPanelBase class="user-panel-gate">
    <NovaLayoutScrollContainer class="contents">
      <div class="user-portrait-wrap">
        <NovaPortraitContainer
          :size="'lg'"
          :image-url="userStore.user?.userProflUrl"
        />
        <span class="user-name">{{ userStore.user?.userNcnm }}</span>
      </div>

      <NovaHeaderMenuGroup>
        <template #menus>
          <NovaHeaderMenuItem
            v-if="userStore.user?.cmtyUserSeCode === 'C'"
            :label="t('linkCreatorHome')"
            @click="handleOnGoCreatorPage"
          >
            <!--            유저 패널에서 크리에이터 등급 아이콘 제거-->
            <!--            <template #prefix>-->
            <!--              <NovaCreatorGradeBadge-->
            <!--                :creator-grade="userStore.user.crtrSpclGradCode"-->
            <!--                :creator-organization="userStore.user.crtrOrgnztTyCode"-->
            <!--                class="creator-badge"-->
            <!--              />-->
            <!--            </template>-->

            <template #extra>
              <div class="extra-wrap">
                <span class="badge-text">
                  {{ t(`creatorGrade.${grade}`) }}
                </span>
                <NovaIcon
                  :icon="{ type: 'outline', name: 'chev-thin-right' }"
                />
              </div>
            </template>
          </NovaHeaderMenuItem>

          <NovaHeaderMenuItem
            v-if="userStore.user?.cmtyUserSeCode !== 'C'"
            :label="t('linkMyHome')"
            @click="handleOnGoUserHomePage"
          />

          <NovaHeaderMenuItem
            v-if="userStore.isSignIn"
            :label="t('rewardsGate')"
            @click="handleOnClickUseReward"
          >
            <template #extra>
              <div class="extra-wrap">
                <NovaButtonText
                  :label="
                    kycCertified
                      ? `${lm} LM`
                      : t('kyc.userKycInfo.certifiedAction.yetSimple')
                  "
                  :theme="
                    kycCertified
                      ? 'primary-blue-light'
                      : 'primary-red-ultra-light'
                  "
                  :size="20"
                  :readonly="true"
                  class="shrink"
                />
                <NovaIcon
                  :icon="{ type: 'outline', name: 'chev-thin-right' }"
                />
              </div>
            </template>
          </NovaHeaderMenuItem>

          <NovaHeaderMenuItem
            :label="t('dailyCheck.dailyCheckin')"
            @click="handleOnGoDailyCheck"
          />

          <NovaHeaderMenuItem
            v-if="applyCreator.showLink"
            :label="t('applyCreator.entry')"
            @click="handleOnGoApplyCreator"
          >
            <template #extra>
              <div class="apply-creator-menu-item-extra">
                <NovaButtonText
                  v-if="applyCreator.badge"
                  :label="applyCreator.badge.label"
                  :theme="applyCreator.badge.theme"
                  :size="20"
                  :readonly="true"
                />

                <NovaIcon
                  v-else
                  :icon="{ type: 'outline', name: 'chev-thin-right' }"
                />
              </div>
            </template>
          </NovaHeaderMenuItem>

          <NovaHeaderMenuItem
            :label="t('settings')"
            @click="handleOnGoSettings"
          />

          <!--<NovaHeaderMenuItem-->
          <!--  :label="t('servicesAndPolicies')"-->
          <!--  @click="handleOnGoServices"-->
          <!--/>-->

          <NovaHeaderMenuItem :label="t('cs')" @click="handleOnGoCS" />

          <NovaHeaderMenuItem :label="t('sign.out')" @click="handleOnSignOut" />
        </template>
      </NovaHeaderMenuGroup>

      <div class="copy-right-wrap">
        <NovaDivider />

        <p class="copy-right">{{ copyRight }}</p>
      </div>
    </NovaLayoutScrollContainer>

    <div class="help-center-wrap">
      <NovaBoxHelpCenter />
    </div>
  </NovaHeaderToolsPanelBase>
</template>

<style lang="scss" scoped>
.contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .user-portrait-wrap {
    //display: flex;
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .user-name {
      @include text-style($text-heading2-bold);
      @include ellipsis(1);
    }

    .user-cell {
      cursor: pointer;
    }

    @include tablet {
    }

    @include mobile {
      display: flex;
    }
  }

  .apply-creator-menu-item-extra {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .copy-right-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .copy-right {
      @include text-style($text-body-12-regular);
      color: $color-text-3;
    }
  }

  .extra-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;

    .badge-text {
      flex-shrink: 0;
      font-size: 12px;
      background-color: #347ae2;
      border-radius: 3px;
      color: #fff;
      padding: 2px 5px;
    }
  }

  .shrink {
    flex-shrink: 0;
  }
}

.help-center-wrap {
  padding: 0 20px 20px;
}
</style>
